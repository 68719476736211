import React from "react"
import step1 from "../../../imgs/onboarding/step1-graph-export.png"
import step1En from "../../../imgs/onboarding/english/step1-graph-export-en.png"
import step2 from "../../../imgs/onboarding/step2-graph-export.png"
import step2En from "../../../imgs/onboarding/english/step2-graph-export-en.png"

export const stepsGraphExportData = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step 1 export graph data" />
        </div>
      ),
      title: language.onboarding_graph_export_data_title_step1,
      description: language.onboarding_graph_export_data_description_step1,
      placement: "left-start",            
      target: "#export_data_picker",
      disableBeacon: true,
      steps: 2,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideFooter: true,
      hideFooterTitle: "Haz click en el botón!",
      pulseEffect: true
    },
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="step 2 export graph data" />
        </div>
      ),
      title: language.onboarding_graph_export_data_title_step2,
      description: language.onboarding_graph_export_data_description_step2,
      placement: "left",
      width: 340,      
      target: "#export_csv",
      disableBeacon: true,
      steps: 2,
      disableOverlayClose: true,
      hideBackButton: true,
    },
  ])
}
