import React, {Fragment} from "react";

export const replaceTextValues = (text, values) => {
    
    for (let key in values) {
        let value = values[key.toLowerCase()];
        text = text.replace("{{" + key.toLowerCase() + "}}", value);
    }
    text = text.split('\n');

    return <Fragment>{text.map(value => <Fragment>{value}<br/></Fragment>)}</Fragment>;

}

