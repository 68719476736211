import React from "react";
import { connect } from 'react-redux';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from "swiper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';

import empty_widgets_slider from "../../../imgs/empty-widgets-slider.svg";

import { useWindowSize } from '../../shared/utilities/useWindowSize';

import { track } from "@amplitude/analytics-browser";

SwiperCore.use([Autoplay, Navigation]);

/* It organize the banners by placing 1 indicators - 1 banner image */
/* When one of them is finished, it starts repeating the remaining element. */
const orderData = (arr1, arr2) => {
    if (arr1 === undefined || arr1 === null) {
        return arr2
    }

    if (arr2 === undefined || arr2 === null) {
        return arr1
    }

    const result = []
    let i = 0
    let j = 0

    while (i < arr1.length && j < arr2.length) {
        result.push(arr1[i++])
        result.push(arr2[j++])
    }

    while (i < arr1.length) {
        result.push(arr1[i++])
    }

    while (j < arr2.length) {
        result.push(arr2[j++])
    } return result
}

const Cards = (props) => {

    const { bannersData } = props

    let activeServicesEnergy = props.activeServicesEnergy;

    const size = useWindowSize();

    const RenderWidgets = ({ item }) => {

        let value_color;
        let icon_color;

        switch (item.type) {
            case 0:
                value_color = "txt-blue-green-gradient";
                icon_color = "txt-green-500";
                break;
            case 2:
                value_color = "txt-green-dark-gradient";
                icon_color = "txt-green-500";
                break;
            default:
                value_color = "txt-red-orange-gradient";
                icon_color = "txt-orange-500";
                break;
        }

        return (
            <div className={`swiper-slider-container column-center align-items-center border-1 border-color-gray-800 bg-gray-500 rounded-16`} style={{ height: 120 }}>
                {!item.image_url ?
                    <div className={`swiper-slider-container column-between align-items-center bg-gray-500 rounded-16 p-3 w-100 h-100`}>
                        <div className="txt-phrase txt-6 txt-white">{`${props.language[item.title]}`.replace('#VALUE', item.value_title ?? '')}</div>
                        <div className="row-center align-items-center ">
                            {item.icon !== null && <FontAwesomeIcon icon={fal[item.icon]} className={`txt-12 me-2 ${icon_color}`} />}
                            <div className={`txt-bold txt-phrase txt-12 ${value_color}`}>{item.value}</div>
                        </div>
                        <div className="row-center align-items-center">
                            {item.icon_text !== null && <FontAwesomeIcon icon={fal[item.icon_text]} className={`txt-10 me-2 ${icon_color}`} />}
                            <div className="txt-regular txt-6 txt-phrase txt-white">{`${props.language[item.text]}`.replace('#VALUE', item.value_text ?? '')}</div>
                        </div>
                    </div> :
                    <a href={item.link} className={`overflow-hidden rounded-16 pe-pointer w-100 h-100`} style={{ backgroundColor: item.background }} target="_blank" rel="noopener noreferrer" onClick={() => {track('Widget opened', {Widget_ID: `${item.amplitude_event}`})}}>
                        <img className="overflow-hidden pe-pointer h-100 w-100" style={{ objectFit: 'contain' }} src={item.image_url} alt={'neu-img'}></img>
                    </a>
                }
            </div>
        );
    };

    const RenderWidgetsEmpty = () => {
        return (
            <div className={`swiper-slider-container column-between align-items-center bg-gray-500 rounded-16 p-3`} style={{ height: 120 }}>
                <img src={empty_widgets_slider} alt="empty_widget_energy" style={{ width: 124, height: 60 }} />
                <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap mt-2">{props.language.energy_widgets_empty_data}</div>
            </div>
        )
    };

    return (
        <div className="row-center align-items-center ps-4 w-100 h-100">
            <div
                id="prev-slide"
                className="row-center align-items-center h-100 pe-pointer"
                style={{ width: 40, height: 40 }}
                onClick={() => track('Widget clicked', {Widget_Back: 'activate'})}
            >
                <i className="fa-regular fa-chevron-left txt-14 txt-gray-700 pe-pointer" />
            </div>
            <div className="position-relative" style={{ width: "calc(100% - 80px)" }} >
                <div className="position-absolute h-100 top-0 left-0 bg-black-transparent-gradient-horizontal zindex-2" style={{ width: 120 }}></div>
                <div className="position-absolute h-100 top-0 right-0 bg-transparent-black-gradient-horizontal zindex-2" style={{ width: 120 }}></div>
                {props.indicators.length ?
                    <Swiper
                        loop={true}
                        centeredSlides={true}
                        spaceBetween={16}
                        autoplay={{ delay: 10000, disableOnInteraction: false }}
                        slidesPerView={size.width >= 1400 ? 3 : 2}
                        navigation={{ nextEl: "#next-slide", prevEl: "#prev-slide" }}>
                        {orderData(props.indicators, activeServicesEnergy.some(s => s.type.name === "energy") ? bannersData : []).map((card, i) =>
                            <SwiperSlide key={i}>
                                <RenderWidgets item={card}></RenderWidgets>
                            </SwiperSlide>)}
                    </Swiper> :
                    <Swiper
                        loop={true}
                        centeredSlides={true}
                        spaceBetween={16}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        slidesPerView={size.width >= 1400 ? 3 : 2}
                        navigation={{ nextEl: "#next-slide", prevEl: "#prev-slide" }}>
                        {[0, 1, 2].map((i) =>
                            <SwiperSlide key={i}>
                                <RenderWidgetsEmpty />
                            </SwiperSlide>)}
                    </Swiper>
                }
            </div>
            <div
                id="next-slide"
                className="row-center align-items-center  h-100 pe-pointer"
                style={{ width: 40, height: 40 }}
                onClick={() => track('Widget clicked', {Widget_Forward: 'activate'})}>
                <i className="fa-regular fa-chevron-right txt-14 txt-gray-700 pe-pointer" />
            </div>
        </div>);
};

const mapStateToProps = (state) => ({
    indicators: state.energy.indicators,
    language: state.shared.language,
    bannersData: state.energy.bannersData,
    activeServicesEnergy: state.services.activeServicesEnergy
});

export default connect(mapStateToProps)(Cards);

