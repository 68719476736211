import React, { Component } from 'react';
import { Provider } from 'react-redux';

import { Route, Switch } from 'react-router-dom';

import Store from './store/Store';

import Main from './components/Main';
import Login from './components/login/Login';
import Paid from './components/invoices/pay/paid/Paid';
import MobileScreen from './components/shared/MobileScreen';
import Onboarding from './components/onboarding/Onboarding';

import './styles/style.scss';
import './icons/css/all.css';

import 'rsuite/dist/styles/rsuite-default.min.css';

import 'react-circular-progressbar/dist/styles.css';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        logEvent(analytics, "open_app");
    }

    componentWillUnmount(){
        logEvent(analytics, "close_app");
    }

    render() {
        return (
            <Provider store={Store}>
                <MobileScreen/>
                <div className='d-none d-lg-flex'>
                    <Switch>
                        <Route path="/paid" exact component={Paid}/>
                        <Route path="/login" exact component={Login} />
                        <Route path='/onboarding' component={Onboarding} />
                        <Route component={Main} />
                    </Switch>
                </div>
            </Provider>
        );
    }
}
