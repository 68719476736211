import React from 'react';

import * as shared from '../creators/Shared';

import { GETData, SETData } from "../../services/WebServices";

import { Notification, Alert } from 'rsuite';

import * as firebase from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { getRemoteConfig, fetchAndActivate, getAll } from "firebase/remote-config";

import lang from '../../language/es';

const firebaseConfig = {
    apiKey: "AIzaSyDPSbHg2-QGXjLJsRUZTJ2Gtgum0dpXA1E",
    authDomain: "neu-app-v4.firebaseapp.com",
    projectId: "neu-app-v4",
    storageBucket: "neu-app-v4.appspot.com",
    messagingSenderId: "401559542772",
    appId: "1:401559542772:web:443a0d4fc0c44fd7c937a1",
    measurementId: "G-BL7BT7TJSC"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const remoteConfig = getRemoteConfig();

isSupported().then(support => {
    if (support) {
        const messaging = getMessaging(firebaseApp);

        onMessage(messaging, (payload) => {
            Notification.open(
                {
                    className: "bg-gray-800 rounded-8",
                    title: payload.data.title,
                    duration: 20000,
                    placement: "bottomEnd",
                    description: (<div className="txt-phrase txt-regular txt-6 txt-white text-wrap">{payload.data.body}</div>)
                }
            )
        });
    }
})

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

remoteConfig.defaultConfig = lang;

export const loadLanguage = async () => {

    return await fetchAndActivate(remoteConfig)
        .then(() => {

            let words = {};

            const parameters = getAll(remoteConfig);

            Object.entries(parameters).forEach(value => {
                const [key, entry] = value;
                words[key] = entry.asString().replace(/\s+/g, " ");
            });

            return words;
        });

}

export const setCurrentModule = payload => {
    return {
        type: shared.SET_CURRENT_MODULE,
        payload
    }
}

export const setMenu = payload => {
    return {
        type: shared.SET_MENU,
        payload
    }
}

export const setLanguage = payload => {
    return {
        type: shared.SET_LANGUAGE,
        payload
    }
}

export const setShowBlackScreen = payload => {
    return {
        type: shared.SET_SHOW_BLACK_SCREEN,
        payload
    }
}

export const setShowBlackScreenDrawer = payload => {
    return {
        type: shared.SET_SHOW_BLACK_SCREEN_DRAWER,
        payload
    }
}

export const setShowCalendar = payload => {
    return {
        type: shared.SET_SHOW_CALENDAR,
        payload
    }
}

export const setShowNpsSurvey = payload => {
    return {
        type: shared.SET_SHOW_NPS_SURVEY,
        payload
    }
}

export const setStartOnboarding = payload => {
    return {
        type: shared.SET_START_ONBOARDING,
        payload
    }
}

export const setControlledOnboarding = payload => {
    return {
        type: shared.SET_CONTROLLED_ONBOARDING,
        payload
    }
}

export const setShowDrawerOnboarding = payload => {
    return {
        type: shared.SET_SHOW_DRAWER_ONBOARDING,
        payload
    }
}

export const setShowPulseAnimation = payload => {
    return {
        type: shared.SET_SHOW_PULSE_ANIMATION,
        payload
    }
}

export const setScreenPaddingBottom = payload => {
    return {
        type: shared.SET_SCREEN_PADDING_BOTTOM,
        payload
    }
}

export const logout = payload => {
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
    localStorage.setItem('account', null);
    return {
        type: shared.LOGOUT,
        payload
    }
}

export const loadLanguageAsync = () => {
    return (dispatch) => {

        loadLanguage().then(response => {
            dispatch(setLanguage(response));
        })
    }
}

export const loadUserNpsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().users.user.idUser;
        const userCapabilities = getState().users.user.capabilities;

        if ( userCapabilities.show_nps_survey ) {

            GETData(`survey/${userId}`, "GET")
            .then(response => {
                if (response !== null) {
                    if (response.body[0].show_nps_survey === true || response.body[0].show_nps_survey === null) {

                        dispatch(setShowNpsSurvey(true))

                    }
                    else {

                        dispatch(setShowNpsSurvey(false))

                    }
                }
            })
            .catch(response => console.error(response));

        }

    }
}

export const setNpsSurveyAsync = (payload) => {
    return (dispatch, getState) => {

        const userId = getState().users.user.idUser;

        const language = getState().shared.language;

        const data = {
            id_user: userId,
            ...payload
        }

        SETData("survey/create", "POST", data)
            .then(response => {
                if (response) Alert.success(language.alert_nps_survey_create_success);
            })
            .catch(() => Alert.error(language.alert_nps_survey_create_error))
            .finally(() => dispatch(setShowNpsSurvey(false)));
    }
}

