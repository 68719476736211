export const SET_REPORTS_GENIUS = 'SET_REPORT_GENIUS';
export const SET_GRAPHS_REPORT_GENIUS = 'SET_GRAPHS_REPORT_GENIUS';
export const SET_ACTIVE_REPORTS = 'SET_ACTIVE_REPORTS';
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
export const SET_NEW_REPORT_LOADING = 'SET_NEW_REPORT_LOADING';
export const SET_REPORT_GENERATED = 'SET_REPORT_GENERATED';
export const SET_NEW_REPORT = 'SET_NEW_REPORT';
export const SET_SHOW_CONTENT_HEADER = 'SET_SHOW_CONTENT_HEADER';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_GRAPHS_TYPE = 'SET_GRAPHS_TYPE';
export const SET_TOTAL_GRAPHS_REPORT_GENIUS = 'SET_TOTAL_GRAPHS_REPORT_GENIUS';

