import React from "react";

export const colors = [
    {
        name: "Green",
        hex: "#CEFC34"
    },
    {
        name: "Blue",
        hex: "#00B7D3"
    },
    {
        name: "Orange",
        hex: "#FFAA00"
    },
    {
        name: "Red",
        hex: "#FC3535"
    },
    {
        name: "Purple",
        hex: "#9F14E5"
    },
    {
        name: "Pink",
        hex: "#E44AC3"
    },
    {
        name: "PurpleLight",
        hex: "#daa1f7"
    },
    {
        name: "PurpleDark",
        hex: "#754c8a"
    },
    {
        name: "BlueLight",
        hex: "#99f1ff"
    },
    {
        name: "BlueDark",
        hex: "#6199A3"
    },
    {
        name: "GreenLight",
        hex: "#e6fe9a"
    },
    {
        name: "GreenDark",
        hex: "#5E741A"
    },
    {
        name: "Neutral",
        hex: "#F5FED6"
    },
]

const gradients25 = colors.map((color, i) => {
    return (
        <svg key={`${i}-25`} style={{ height: 0, width: 0, display: 'flex' }}>
            <defs>
                <linearGradient id={`linear${color.name}Transparent25`} gradientTransform="rotate(90)">
                    <stop offset="25%" stopColor={color.hex} />
                    <stop offset="100%" stopColor="transparent" />
                </linearGradient>
            </defs>
        </svg>
    )
});

const gradients50 = colors.map((color, i) => {
    return (
        <svg key={`${i}-50`} style={{ height: 0, width: 0, display: 'flex' }}>
            <defs>
                <linearGradient id={`linear${color.name}Transparent50`} gradientTransform="rotate(90)">
                    <stop offset="50%" stopColor={color.hex} />
                    <stop offset="100%" stopColor="transparent" />
                </linearGradient>
            </defs>
        </svg>
    )
});

const gradient75 = colors.map((color, i) => {
    return (
        <svg key={`${i}-75`} style={{ height: 0, width: 0, display: 'flex' }}>
            <defs>
                <linearGradient id={`linear${color.name}Transparent75`} gradientTransform="rotate(90)">
                    <stop offset="75%" stopColor={color.hex} />
                    <stop offset="100%" stopColor="transparent" />
                </linearGradient>
            </defs>
        </svg>
    )
});

export const gradients = [...gradients25, ...gradients50, ...gradient75];

// export const barStyles = colors.map(color => {
//     return {
//         data: { fill: `url(#linear${color.name}Transparent75)` }
//     }
// });

export const barStyles = colors.map(color => {
    return {
        data: { fill: `${color.hex}` }
    }
});

export const linearStyles = colors.map(color => {
    return {
        data: {
            fill: `url(#linear${color.name}Transparent25)`,
            fillOpacity: 0.3,
            stroke: color.hex,
            strokeWidth: 2
        }
    }
});

export const scaltterStyles = colors.map(color => {
    return {
        data: {
            fill: '#00',
            stroke: color.hex,
            strokeWidth: 2
        }
    }
});

export const grid = {
    fill: "#FFF",
    stroke: "#FFF",
    pointerEvents: "painted",
    strokeWidth: 1,
    opacity: 0.2
}

export const tickLabelsX = {
    fill: "#FFF",
    fontSize: 10,
    angle: 45,
    padding: 60,
    fontFamily: "Inter"
}

export const tickLabelsY = {
    fill: "#FFF",
    fontSize: 10,
    fontFamily: "Inter"
}

export const tickStyle = {
    fill: "#161617",
    opacity: 0.8
}