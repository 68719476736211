import React, { Fragment, useEffect, useRef, useState } from "react"
import { connect } from "react-redux"

import * as energyActions from "../../../store/actions/Energy"
import * as sharedActions from "../../../store/actions/Shared"

import _ from "lodash"
// import { Animation } from "rsuite"
import { track } from "@amplitude/analytics-browser"

import { Transition } from "react-transition-group"

import { Loader } from "rsuite"

import { VictoryChart, VictoryGroup, VictoryAxis, createContainer, VictoryBar, VictoryLabel, VictoryTooltip, VictoryArea, Rect } from "victory"

import moment from "moment-timezone"
import { addDays, parse, startOfWeek, startOfMonth, addMonths, startOfYear, addYears } from "date-fns"

import CurrencyFormat from 'react-currency-format';

import GraphTypePicker from "./GraphTypePicker"
import GraphPeriodPicker from "./GraphPeriodPicker"
import GraphIndicators from "./GraphIndicators"
import ExtraItemsPopover from "../../genius/ExtraItemsPopover"
import CalendarGraphEnergy from "../../shared/utilities/CalendarGraphEnergy"
import ButtonCircle from "../../shared/utilities/ButtonCircle"
import ExportGraph from "./ExportGraph"

import empty_graph_energy from "../../../imgs/empty-graph-energy.svg"

import { setGraphLabel } from "../../../services/GraphsHandler"

import { useWindowSize } from "../../shared/utilities/useWindowSize"
import { colors, gradients, barStyles, linearStyles, grid, tickLabelsX, tickLabelsY, tickStyle } from "../../shared/svgs/GraphStyles"

import useTour from '../../onboarding/hook/useTour';
import { stepsGraphType } from '../../onboarding/steps/GraphTypeSteps';
import { stepsGraphPeriod } from "../../onboarding/steps/GraphPeriodSteps";
import { stepsGraphCalendar } from "../../onboarding/steps/GraphCalendarSteps";
import { stepsGraphExportData } from "../../onboarding/steps/GraphExportDataSteps"

import { useHistory } from 'react-router-dom';

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi")


const graphLoadingContainer = (loading, language) => (
  <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
    <Transition in={loading} timeout={100}>
      {(state) => (
        <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
          <div className="column-center align-items-center w-100 h-100">
            <Loader inverse center size="md" content={language.loader_text_content} />
          </div>
        </div>
      )}
    </Transition>
  </div>
)


const formatDateLabel = (intervalGraph, domain, lang) => {
  let dateFormated
  let intervalLeft
  let intervalRight
  moment.locale(lang)
  switch (intervalGraph) {
    case 0:
      dateFormated = moment
        .utc(domain?.x[1] * 1000)
        .tz("America/Bogota")
        .format("DD MMMM YYYY")
      break
    case 1:
    case 2:
      intervalLeft = moment.utc((domain?.x[0] + 86400) * 1000).format("MMM DD")
      intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM DD")
      dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`
      break
    case 3:
      intervalLeft = moment.utc((domain?.x[0] + 2592000) * 1000).format("MMM YYYY")
      intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM YYYY")
      dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`
      break
    default:
      dateFormated = ""
      break
  }
  return dateFormated
}

const handlerFormatAxis = (date, type, lang, zoomContainer = false, title = false) => {
  let stringFormats

  switch (type) {
    case 0:
      stringFormats = ["hh:mm DD/MM/YY", "HH", "dddd, DD MMMM YYYY"]
      break
    case 1:
    case 2:
      stringFormats = ["DD/MM/YY", "ddd, DD", "MMMM YYYY"]
      break
    case 3:
      stringFormats = ["MMM, YY", "MMM", "YYYY"]
      break
    default:
      break
  }
  if (zoomContainer) {
    moment.locale(lang)
    return parseInt(moment(date, stringFormats[0]).unix())
  } else {
    moment.locale(lang)
    return moment.utc(date * 1000).format(stringFormats[title ? 2 : 1])
  }
}


const getNextMonth = (date, left, interval, lang) => {
  moment.locale(lang);
  let dateObject = moment.utc(date);
  switch (interval) {
    case 0:
      const day = left ? dateObject.subtract(1, 'days') : dateObject.add(1, 'days');
      const firstHour = day.startOf('day').unix() - 1800;
      const lastHour = day.endOf('day').unix() - 1800;
      return { x: [firstHour, lastHour] };
    case 1:
      const week = left ? dateObject.subtract(1, 'weeks') : dateObject.add(1, 'weeks');
      const firstDay = week.startOf('isoWeek').unix() - 43200;
      const lastDay = week.endOf('isoWeek').unix() - 86400 * 0.5;
      return { x: [firstDay, lastDay] };
    case 2:
      const month = left ? dateObject.subtract(1, 'months') : dateObject.add(1, 'months');
      const firstDayMonth = month.startOf('month').unix() - 43200;
      const lastDayMonth = month.endOf('month').unix() - 43200;
      return { x: [firstDayMonth, lastDayMonth] };
    default:
      break;
  }
}

const getBarWidth = (intervalGraph, numOfGraphsSelected) => {
  switch (intervalGraph) {
    case 1:
      if (numOfGraphsSelected === 1) {
        return 60
      } else if (numOfGraphsSelected < 4) {
        return 40
      } else if (numOfGraphsSelected === 4) {
        return 25
      } else {
        return 15
      }
    case 2:
      if (numOfGraphsSelected === 1) {
        return 20
      } else if (numOfGraphsSelected === 2) {
        return 12
      } else {
        return 10
      }
    case 3:
      if (numOfGraphsSelected <= 2) {
        return 35
      } else if (numOfGraphsSelected > 2 || numOfGraphsSelected <= 4) {
        return 20
      } else {
        return 15
      }
    default:
      break
  }
}

const Graph = (props) => {

  const history = useHistory();

  let graphData = props.graphData
  let intervalGraph = props.intervalGraph
  let graphDateFrom = props.graphDateFrom
  let graphDateTo = props.graphDateTo
  let showCalendar = props.showCalendar
  let scrollActive = props.scrollActive
  let setIntervalGraph = props.setIntervalGraph
  //let tariff = props.tariff;  
  let showReportModal = props.showReportModal
  let graphCalendarPicker = props.graphCalendarPicker
  let graphExportData = props.graphExportData
  let activeServicesPlus = props.activeServicesPlus

  const setShowBlackScreen = props.setShowBlackScreen
  const setShowCalendar = props.setShowCalendar
  const setScrollActive = props.setScrollActive
  const setControlledOnboarding = props.setControlledOnboarding
  const setStartOnboarding = props.setStartOnboarding
  const loadDetailService = props.loadDetailService

  const size = useWindowSize()

  const numLegendsToShow = size.width > 1400 ? 3 : 2

  const graphContainer = useRef(null)
  const graphContainerLoader = useRef(null)

  const maxNormalizeInit = {
    GENERATION: 1,
    IRRADIANCE: 1,
    PR: 100,
  }
  let avgText = props.language.language_locale === "es" ? 'Prom' : 'Avg'

  const [emptyData, setEmptyData] = useState(false)
  const [graphContainerWidth, setGraphContainerWidth] = useState(0)
  const [domain, setDomain] = useState(null)
  const [entireDomain, setEntireDomain] = useState(null)
  const [data, setData] = useState([])
  const [grabbing, setGrabbing] = useState(false)
  const [dateRangeEnergy] = useState([
    {
      startDate: graphDateFrom,
      endDate: graphDateTo,
      key: "selection",
    },
  ])
  const numOfGraphsSelected = data.length
  const [openGraphPeriodPicker, setOpenPeriodPicker] = useState(false);
  const [maxNormalize, setMaxNormalize] = useState(maxNormalizeInit);
  const [loader, setLoader] = useState(true);

  useEffect(() => {    
    const sortGraphData = graphData.sort((a, b) => b.data.length - a.data.length);
    if (sortGraphData.length) setData(sortGraphData)
    // eslint-disable-next-line
  }, [graphData])

  useEffect(() => {
    if (data.length) {
      const dataValues = data[0]?.data
      let intervalX = [dataValues[0]?.x_sec, intervalGraph === 3 ? _.last(dataValues)?.x_sec + (new Date().getDate() + 1) * 86400 : _.last(dataValues)?.x_sec]
      setEntireDomain({ x: intervalX })
      if (domain === null || isNaN(domain.x[0])) {
        setDomain({
          x: [
            intervalX[1] -
            (intervalGraph === 0
              ? 86400
              : intervalGraph === 1
                ? 604800 - (numOfGraphsSelected > 9 ? 86400 * (numOfGraphsSelected - 9) : 0)
                : intervalGraph === 2
                  ? 86400 * moment(graphDateFrom)?.daysInMonth() - (numOfGraphsSelected < 3 ? 0 : numOfGraphsSelected === 3 || numOfGraphsSelected === 4 ? 86400 * (numOfGraphsSelected + 1) : numOfGraphsSelected === 5 ? 86400 * (numOfGraphsSelected * 2) : numOfGraphsSelected <= 7 ? 86400 * Math.floor(numOfGraphsSelected * 2.5) : 86400 * 22)
                  : intervalGraph === 3
                    ? 31536000 - (numOfGraphsSelected > 3 ? 86400 * moment(graphDateFrom)?.daysInMonth() * (numOfGraphsSelected - 3) : 0)
                    : 0),
            intervalX[1],
          ],
        })
      }
    }
    // eslint-disable-next-line
  }, [graphContainerWidth, data, intervalGraph])

  useEffect(() => {
    if (graphContainer.current) {
      setGraphContainerWidth(graphContainer.current.getBoundingClientRect().width)
    }
    else {
      setGraphContainerWidth(graphContainerLoader.current.getBoundingClientRect().width)
    };
  }, [size, loader]);

  useEffect(() => {
    const emptyDataGraph = data.filter((d) => d.data.length === 0)
    setEmptyData(emptyDataGraph.length === data.length)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (domain) {
      const intervalLeft = (domain.x[0] - entireDomain.x[0]) / (intervalGraph === 1 ? 604800 : intervalGraph === 0 ? 86400 : intervalGraph === 2 ? 86400 * 30 : 31536000)
      const intervalRight = (entireDomain.x[1] - domain.x[1]) / (intervalGraph === 1 ? 604800 : intervalGraph === 0 ? 86400 : intervalGraph === 2 ? 86400 * 30 : 31536000)
      if (intervalLeft < 0.6 && props.enableScrollData) {        
        props.loadGraphAsync(true, true)
      }
      if (intervalRight < 0.6 && props.enableScrollData && 
        entireDomain.x[1] - (handlerFormatAxis(new Date(), intervalGraph, props.language.language_locale, true) + 
        (props.activeGraphs?.includes('PREDICTION_ACTIVE_ENERGY') && intervalGraph === 0 ? 3600 * 1000 * 24 * 0 : 0)) <= 0) {
          props.loadGraphAsync(true, false)
      }
    }
    if (props.activeGraphs?.includes('IRRADIANCE_GEN')) getMaxNormalize();
    // eslint-disable-next-line
  }, [domain, props.enableScrollData])

  useEffect(() => {
    setShowBlackScreen(showCalendar)
  }, [setShowBlackScreen, showCalendar])

  useEffect(() => { setTimeout(() => setLoader(props.graphLoading), 500) }, [props.graphLoading])

  const getMax = (arr) => {
    arr = arr.map((value) => value.data.filter((value) => value.y > 0 && value?.x_sec >= domain?.x[0] && value?.x_sec <= domain?.x[1]).map((value) => value.y)).reduce((a, b) => a.concat(b), [])
    return Math.max(...arr)
  }

  const getMaxNormalize = () => {
    let generation = data?.filter(item => item?.value === 'GENERATION');
    let irradiance = data?.filter(item => item?.value === 'IRRADIANCE');
    generation = generation.map((value) => value.data.filter((value) => value?.x_sec >= domain?.x[0] && value?.x_sec <= domain?.x[1]).map((value) => value.y)).reduce((a, b) => a.concat(b), [])
    irradiance = irradiance.map((value) => value.data.filter((value) => value?.x_sec >= domain?.x[0] && value?.x_sec <= domain?.x[1]).map((value) => value.y)).reduce((a, b) => a.concat(b), [])
    setMaxNormalize({ ...maxNormalize, GENERATION: generation.length ? Math.max(...generation) : 1, IRRADIANCE: irradiance.length ? Math.max(...irradiance) : 1 });
  }

  const handleCalendar = (value) => {
    props.setGraphDates(value)
    props.loadGraphAsync(false, true)
    setShowBlackScreen(false)
    setShowCalendar(false)
  }

  const RenderEmptyData = () => {
    return (
      <div className="column-center align-items-center w-100" style={{ height: 500 }}>
        <img src={empty_graph_energy} alt="empty_graph_energy" style={{ width: 155, height: 84 }} />
        <div className="txt-gray-600 txt-phrase txt-semibold txt-10 text-wrap mt-2">{props.language.energy_graph_title_empty_data}</div>
        <div className="txt-gray-600 txt-phrase txt-regular txt-8 text-wrap mt-2">{props.language.energy_graph_description_empty_data}</div>
        <button
          className="row-center align-items-center py-2 px-4 gap-2 bg-green-500 rounded-8 mt-3"
          onClick={() => {
            setShowCalendar(true)
          }}>
          <p className="txt-phrase txt-semibold txt-neutral-800 txt-8 pe-pointer">{props.language.energy_graph_empty_data_button}</p>
        </button>
      </div>
    )
  }

  const capitalizedText = (text) => {
    let label = ""
    var phrase = text.toLowerCase().split(" ")

    for (var i = 0; i < phrase.length; i++) {
      if (i !== 2) {
        phrase[i] = phrase[i].charAt(0).toUpperCase() + phrase[i].substring(1)
      }
    }

    label = phrase.join(" ")

    return label
  }

  useEffect(() => {
    if (showCalendar && graphCalendarPicker) {
      setControlledOnboarding({ key: 'graph_calendar_picker', value: true })
    } else {
      setControlledOnboarding({ key: 'graph_calendar_picker', value: false })
    }
  }, [showCalendar, setControlledOnboarding, graphCalendarPicker])

  useEffect(() => {
    if (showReportModal && graphExportData) {
      setControlledOnboarding({ key: 'graph_export_data', value: true })
    } else {
      setControlledOnboarding({ key: 'graph_export_data', value: false })
    }
  }, [showReportModal, setControlledOnboarding, graphExportData])

  const tourGraphType = useTour({ steps: stepsGraphType(props.language), localStorageKey: "graph-type-tour", isOpenDrawer: !scrollActive, controlledOnboarding: props.controlledTypePicker, setControlledOnboarding: setControlledOnboarding, runTour: props.graphTypePicker, setRunTour: setStartOnboarding, key: 'graph_type_picker', setShowPulseAnimation: props.setShowPulseAnimation });
  const tourGraphPeriod = useTour({ steps: stepsGraphPeriod(props.language), localStorageKey: "graph-period-tour", isOpenDrawer: openGraphPeriodPicker, controlledOnboarding: props.controlledPeriodPicker, setControlledOnboarding: setControlledOnboarding, runTour: props.graphPeriodPicker, setRunTour: setStartOnboarding, key: 'graph_period_picker', setShowPulseAnimation: props.setShowPulseAnimation });
  const tourGraphCalendar = useTour({ steps: stepsGraphCalendar(props.language), localStorageKey: "graph-calendar-tour", isOpenDrawer: showCalendar, controlledOnboarding: props.controlledCalendarPicker, setControlledOnboarding: setControlledOnboarding, runTour: props.graphCalendarPicker, setRunTour: setStartOnboarding, key: 'graph_calendar_picker', setShowPulseAnimation: props.setShowPulseAnimation });
  const tourGraphExportData = useTour({ steps: stepsGraphExportData(props.language), localStorageKey: "graph-export-data-tour", isOpenDrawer: showReportModal, controlledOnboarding: props.controlledExportPicker, setControlledOnboarding: setControlledOnboarding, runTour: props.graphExportData, setRunTour: setStartOnboarding, key: 'graph_export_data', setShowPulseAnimation: props.setShowPulseAnimation });

  const DotSliderLine = ({ scale }) => {
    const currentDate = new Date(domain?.x[1] * 1000);
    const firstDateMonth = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1));
    const firstDateTimestamp = firstDateMonth?.getTime();
    const x = scale.x((firstDateTimestamp / 1000) - (86400 / 2))
    return (
      <g>
        <line
          x1={x}
          x2={x}
          y1={48}
          y2={400}
          stroke="#f9fafb"
          strokeWidth="2"
        />
        <circle cx={x} cy={48} r="3" fill="#f9fafb" />
        <rect x={x - 60} y={23} width="120" height="21" rx="12" ry="12" stroke="#7f7f7f" fill="#7f7f7f" fillOpacity="0.2" />
        <text textAnchor="middle" alignmentBaseline="middle" x={x} y={35} fontSize={12} fill={'#f9fafb'} fontFamily={'Inter'}>{capitalizedText(moment.utc(firstDateTimestamp).format('MMMM, YYYY'))}</text>
      </g>
    )
  }

  const FinalDot = ({ scale, data, style, valueData }) => {
    const value = _.last(data);
    const x = scale.x(value?.x_sec);
    const y = data.length && valueData !== "PREDICTION_ACTIVE_ENERGY" ? scale.y(value?.y) : -1000;
    return (
      <g>
        <defs>
          <filter id="blurFilter" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          </filter>
        </defs>
        <circle cx={x} cy={y} r="4" fill={style?.data?.stroke} />
        <circle cx={x} cy={y} r="9" fill={style?.data?.stroke} filter="url(#blurFilter)">
          <animate attributeName="opacity" values="0;1;0" dur="2s" repeatCount="indefinite" />
        </circle>
      </g>
    )
  }

  const AverageLine = ({ scale }) => {
    let unit = null;
    let avg = 0;
    let array = [];
    let y = -1000;
    const keys = [];
    const uniqueArray = [];
    const dataFiltered = data.map((value) => value.data.filter((value) => value.y > 0 && value?.x_sec > domain?.x[0] && value?.x_sec <= domain?.x[1]));
    dataFiltered[0].forEach(item => {
      if (!keys.includes(item?.x_sec)) {
        uniqueArray.push(item);
        keys.push(item?.x_sec);
      }
    })
    array = uniqueArray.map((value) => value.y).reduce((a, b) => a.concat(b), []);
    if (array.length) {
      unit = data[0].data[0].unit;
      const tot = array.reduce((prev, curr) => (curr += prev)).toFixed(2);
      avg = (tot / array.length).toFixed(2);
      y = scale.y(avg);
    }
    return (
      <g>
        <line
          x1={0}
          x2={graphContainerWidth}
          y1={y}
          y2={y}
          stroke="#f9fafb"
          strokeWidth="1"
          strokeDasharray="10,7"
        />
        <rect x={(graphContainerWidth / 2) - 55} y={y - 13} width="110" height="26" rx="12" ry="12" stroke="#f9fafb" fill="#050505" />
        <text textAnchor="middle" alignmentBaseline="middle" x={(graphContainerWidth / 2)} y={y} fontSize={10} fill={'#f9fafb'} fontFamily={'Inter'}>{`${avgText}: ${avg}${unit}`}</text>
      </g>
    )
  }

  const handleBarClick = (_event, data) => {
    track('Graphicbar clicked', { Rank_ID: props.rankGraphId, Project_Type: props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })

    if (numOfGraphsSelected >= 5) return

    const targetDate = parse(data?.datum?.x, 'dd/MM/yy', new Date())
    const targetDateYear = parse(data?.datum?.x, 'MMM, d', new Date())

    const graphDateFromWeek = addDays(startOfWeek(targetDate, 0), 1)
    const graphDateToWeek = addDays(startOfWeek(targetDate, 0), 7)

    const graphDateFromMonth = startOfMonth(targetDateYear)
    const graphDateToMonth = addDays(addMonths(startOfMonth(targetDateYear), 1), -1)

    if (targetDate) {
      switch (intervalGraph) {
        case 1:
          props.setDetailDataGraph({ graphDateFrom: targetDate, graphDateTo: targetDate, intervalGraph: 0 })
          props.setPrevStateBar(targetDate)
          break
        case 2:
          props.setDetailDataGraph({ graphDateFrom: graphDateFromWeek, graphDateTo: graphDateToWeek, intervalGraph: 1 })
          props.setPrevStateBar(targetDate)
          break
        case 3:
          props.setDetailDataGraph({ graphDateFrom: graphDateFromMonth, graphDateTo: graphDateToMonth, intervalGraph: 2 })
          props.setPrevStateBar(targetDateYear)
          break
        default:
          break
      }
    }
  }

  const formatOperationStateValue = value => {
    switch (value) {
      case 0:
        return props.language.graph_operation_state_no_record;
      case 1:
        return props.language.graph_operation_state_deteriorating;
      case 2:
        return props.language.graph_operation_state_process;
      case 3:
        return props.language.graph_operation_state_standby;
      case 4:
        return props.language.graph_operation_state_checking;
      case 5:
        return props.language.graph_operation_state_fail;
      default:
        return null
    }
  }

  const handlePrevStateBar = (targetDate) => {

    const graphDateFromWeek = addDays(startOfWeek(targetDate, 0), 1)
    const graphDateToWeek = addDays(startOfWeek(targetDate, 0), 7)

    const graphDateFromMonth = startOfMonth(targetDate)
    const graphDateToMonth = addDays(addMonths(startOfMonth(targetDate), 1), -1)

    const graphDateFromYear = startOfYear(targetDate)
    const graphDateToYear = addYears(startOfYear(targetDate), 1)

    if (targetDate) {
      switch (intervalGraph) {
        case 0:
          props.setDetailDataGraph({ graphDateFrom: graphDateFromWeek, graphDateTo: graphDateToWeek, intervalGraph: 1 })
          props.setPrevStateBar(null)
          break
        case 1:
          props.setDetailDataGraph({ graphDateFrom: graphDateFromMonth, graphDateTo: graphDateToMonth, intervalGraph: 2 })
          props.setPrevStateBar(null)
          break
        case 2:
          props.setDetailDataGraph({ graphDateFrom: graphDateFromYear, graphDateTo: graphDateToYear, intervalGraph: 3 })
          props.setPrevStateBar(null)
          break
        default:
          break
      }
    }
  }

  const CustomFlyout = ({ x, y, datum, data }) => {

    const detailData = data.map(item => { return { id: item.id, data: item.data.filter(d => d.x_sec === datum.x_sec), value: item?.value } });

    const Content = ({ item, i }) => {
      return (
        <>
          <foreignObject x={x - 75} y={y - ((-i * 35) + (data.length > 1 ? 46 : 50))} width="30" height="30">
            <i className="fa-solid fa-circle" style={{ color: data.length && item?.value !== 'PREDICTION_ACTIVE_ENERGY' ? colors[i - Math.floor(i / colors.length) * colors.length].hex : "white", fontSize: 6 }} />
          </foreignObject>
          <foreignObject x={x - 65} y={y - ((-i * 35) + (data.length > 1 ? 40 : 44))} width="130" height="30">
            <p className="txt-phrase txt-regular txt-white txt-6 text-nowrap">{`${item?.id.split("-").length === 2 ? item?.id.split("-")[0] : item?.id.split("-").slice(0, item?.id.split("-").length - 1).reverse().join(' - ')}`}</p>
          </foreignObject>
          <CurrencyFormat
            value={item?.data[0]?.y}
            displayType={'text'}
            thousandSeparator={true}
            renderText={value =>
              <text
                x={x - 65}
                y={y - ((-i * 35) + (data.length > 1 ? 13 : 17))}
                fontSize="12"
                fontFamily={'Inter'}
                fill="#A2A2A2">
                {intervalGraph === 0 ? `${handlerFormatAxis(item?.data[0]?.x_sec, intervalGraph, props.language.language_locale)}:00 : ` : `${handlerFormatAxis(item?.data[0]?.x_sec, intervalGraph, props.language.language_locale)} : `} <tspan fill="white" fontWeight={'bold'}>{`${props.activeGraphs.includes('OPERATION_STATE') ? formatOperationStateValue(parseInt(value)) : value} ${item?.data[0]?.unit}`}</tspan>
              </text>}
          />

        </>
      )
    }

    return (
      <g>
        <rect
          x={x - 90}
          y={y - 50}
          width="170"
          opacity={0.8}
          height={detailData.filter(d => d.data.length !== 0).length * 43}
          rx="20"
          fill="#1F1F20"
          stroke="#999"
        />
        {detailData.length ? detailData.filter(d => d.data.length !== 0).map((d, index) => (<Content key={d.id} item={d} i={index}/>)) : null}
      </g>
    );
  };

  useEffect(() => { if(props.activeGraphs.includes('REACTIVE_CAPACITIVE_EXCESS_ENERGY')){setIntervalGraph(3)} }, [props.activeGraphs, setIntervalGraph])
  
  return (
    <Fragment>
      {loader ? <div ref={graphContainerLoader} style={{ height: 670 }}>{graphLoadingContainer(loader, props.language)}</div> :
        <Fragment>
          {tourGraphType}
          {tourGraphPeriod}
          {tourGraphCalendar}
          {tourGraphExportData}
          <div className="position-relative column-start align-items-center w-100">
            {gradients}
            <div className="column-between align-center h-100 w-100">
              <div className="d-flex flex-nowrap justify-content-between align-items-center w-100 px-3 pt-3">
                <div id="graph_picker" className="p-2 rounded-30" style={{ backgroundColor: "#1D1D1D" }}>
                  <div className="row-start align-items-center flex-wrap gap-2">
                    {data.slice(0, numLegendsToShow).map((d, i) => (
                      <ExtraItemsPopover
                        key={d.id}
                        content={
                          <div className="w-100">
                            <div className="column-start align-items-center">
                              <div className="row-between w-100 align-items-center px-3 py-2">
                                <div className="txt-phrase txt-6 text-nowrap txt-white">{setGraphLabel(d?.id)}</div>
                              </div>
                            </div>
                          </div>
                        }
                        button={
                          <div className={`row-center align-items-center border-1 border-color-gray-800 bg-gray-450 rounded-30 p-2 gap-3 max-width-220  ${props.activeGraphs.length === 1 ? "max-width-360-2xl" : "max-width-260-xl max-width-300-2xl"} `}>
                            <i className="fa-solid fa-circle txt-6" style={{ color: d.data.length && d?.value !== "PREDICTION_ACTIVE_ENERGY" ? colors[i - Math.floor(i / colors.length) * colors.length].hex : "white" }}></i>
                            <div className="txt-phrase txt-white txt-regular txt-6">{d.data.length ? setGraphLabel(d.id, true) : `${props.language.energy_graph_no_data} - ${setGraphLabel(d?.id, true)}`}</div>
                            {props.activeGraphs.length > 1 && <i className="fa-light fa-circle-xmark txt-9 txt-gray-700 pe-pointer" onClick={() => props.setGraphActive([...props.activeGraphs.filter((g) => g !== d.value)])}></i>}
                          </div>
                        }
                      />
                    ))}
                    {data.length > numLegendsToShow && (
                      <ExtraItemsPopover
                        content={
                          <div className="w-100 px-3 py-2">
                            <div className="column-start">
                              {data.slice(numLegendsToShow).map((d, i) => {
                                return (
                                  <div key={i} className="row-between align-items-center gap-2 py-2">
                                    <i className="fa-solid fa-circle txt-6" style={{ color: d.data.length && d?.value !== "PREDICTION_ACTIVE_ENERGY" ? colors[i + numLegendsToShow - Math.floor((i + numLegendsToShow) / colors.length) * colors.length].hex : "white" }}></i>
                                    <div className="txt-phrase txt-white txt-regular txt-6">{d.data.length ? setGraphLabel(d.id) : `${props.language.energy_graph_no_data} - ${setGraphLabel(d?.id, true)}`}</div>
                                    <i className="fa-solid fa-close txt-8 txt-gray-700 pe-pointer" onClick={() => props.setGraphActive([...props.activeGraphs.filter((g) => g !== d.value)])}></i>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        }
                        button={
                          <div className="row-center align-items-center rounded-pill border-1 border-color-gray-800 bg-gray-450 rounded-30 p-2">
                            <i className="fa-solid fa-plus txt-6 txt-white"></i>
                            <div className="ms-1 txt-phrase txt-6 txt-white">{data.length - numLegendsToShow}</div>
                          </div>
                        }
                      />
                    )}
                    <GraphTypePicker />
                  </div>
                </div>
                <div className="row-start align-items-center gap-2">
                  <div className="p-2 rounded-30" style={{ backgroundColor: "#1D1D1D" }}>
                    <div className="row-start align-items-center gap-3">
                      {activeServicesPlus.length ? 
                        <button onClick={() => { history.push('/plus'); loadDetailService({idService: activeServicesPlus[0]?.idService}) }} className={`row-center align-items-center border-1 border-color-gray-800 bg-gray-450 gap-2 rounded-pill py-2 ps-3 pe-2`}>
                          <p className="txt-phrase txt-white txt-6 text-nowrap">{props.language.energy_graph_plus_button}</p>
                          <i className={`fa-light fa-circle-arrow-up-right txt-10 txt-gray-700`}></i>
                        </button> : null}
                      <GraphPeriodPicker onChange={(value) => setOpenPeriodPicker(value)} />
                      <div className="txt-white txt-phrase txt-8 text-left w-100">{capitalizedText(formatDateLabel(intervalGraph, domain, props.language.language_locale))}</div>
                      <div id="date_calendar_picker" className="position-relative column-center align-items-center">
                        <ButtonCircle
                          sizeIcon={10}
                          icon={'calendar'}
                          focus={showCalendar}
                          showPulse={graphCalendarPicker && props.showPulseAnimation}
                          onClick={() => {
                            setShowCalendar(!showCalendar)
                          }}
                        />
                        <CalendarGraphEnergy show={showCalendar} setShow={setShowCalendar} dateRangeEnergy={dateRangeEnergy} interval={intervalGraph} setDates={handleCalendar} setIntervalGraph={setIntervalGraph} language={props.language} />
                      </div>
                    </div>
                  </div>
                  <div id="export_data_picker">
                    <ButtonCircle
                      focus={false}
                      icon={'circle-arrow-down'}
                      showPulse={graphExportData && props.showPulseAnimation}
                      onClick={() => {
                        props.setShowReportModal(true)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div ref={graphContainer} className={`row-start align-items-center w-100 ${emptyData ? "" : grabbing ? "pe-grabbing" : "pe-grab"}`} style={{ overflowX: "hidden", overflowY: "hidden" }}>
                {props.prevStateBar ? <button className="position-absolute zindex-2 row-center align-items-center border-1 border-color-gray-800 bg-gray-450 rounded-30 py-2 px-3" style={{ top: 90, right: 16 }} onClick={() => handlePrevStateBar(props.prevStateBar)}>
                  <i className="fal fa-circle-chevron-left txt-8 txt-green-500 me-2"></i>
                  <p className="txt-phrase txt-white txt-regular txt-6 pe-pointer">{props.language.graphs_go_back_button}</p>
                </button> : null}
                {emptyData === false && data.length ? (
                  <div style={{ width: graphContainerWidth }} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)}>
                    <div className="row-center w-100 align-items-center position-absolute zindex-3 top-0 gradient-container-graph" style={{ marginTop: 65, height: 30 }} />
                    {intervalGraph === 3 || (intervalGraph === 2 && numOfGraphsSelected >= 3) || (intervalGraph === 1 && numOfGraphsSelected >= 8) ? null :
                      <div className="row-center align-items-center buttons-snap-graph position-absolute zindex-2 top-40 pe-pointer" style={{ height: 40, width: 40, marginLeft: 90 }}
                        onClick={() => {
                          setScrollActive(false);
                          setDomain(getNextMonth(domain?.x[1] * 1000, true, intervalGraph, props.language.language_locale));
                          track('Graphic clicked', { Graphic_Back: 'activate', Rank_ID: props.rankGraphId, Project_Type: props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })
                        }}>
                        <i className="fa-light fa-chevron-left txt-12 txt-white" />
                      </div>}
                    {intervalGraph === 3 || (intervalGraph === 2 && numOfGraphsSelected >= 3) || (intervalGraph === 1 && numOfGraphsSelected >= 8) ? null :
                      <div className="row-center align-items-center buttons-snap-graph position-absolute zindex-2 top-40 right-0 pe-pointer" style={{ height: 40, width: 40, marginRight: 90 }}
                        onClick={() => {
                          setScrollActive(false);
                          const nextValue = getNextMonth(domain?.x[1] * 1000, false, intervalGraph, props.language.language_locale);
                          if (nextValue && nextValue?.x[1] - 86400 <= entireDomain.x[1]) {
                            setDomain(nextValue);
                          }
                          track('Graphic clicked', { Graphic_Forward: 'activate', Rank_ID: props.rankGraphId, Project_Type: props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })
                        }}>
                        <i className="fa-light fa-chevron-right txt-12 txt-white" />
                      </div>}
                    {props.activeGraphs.includes('IRRADIANCE_GEN') ?
                      <Fragment>
                        <div className="row-center align-items-center position-absolute zindex-2 top-50 txt-8 txt-white opacity-80 txt-regular" style={{ marginLeft: 50, transform: 'rotate(-90deg)', transformOrigin: 'top left' }}>Generación (kWh)</div>
                        <div className="row-center align-items-center position-absolute zindex-2 top-50 right-0 txt-8 txt-white opacity-80 txt-regular" style={{ marginRight: 50, transform: 'rotate(90deg)', transformOrigin: 'top right' }}>Irradiancia (W/m2)</div>
                      </Fragment> : null
                    }
                    {intervalGraph ? (
                      <VictoryChart
                        animate={false}
                        height={500}
                        width={graphContainerWidth}
                        domain={entireDomain}
                        domainPadding={{ x: intervalGraph === 1 ? 0 : 0, y: 37 }}
                        padding={{ top: 35, bottom: 100, left: 0, right: 0 }}
                        containerComponent={
                          <VictoryZoomVoronoiContainer
                            allowZoom={false}
                            allowDrag={false}
                            allowDraw={false}
                            allowResize={false}
                            zoomDimension={"x"}
                            zoomDomain={domain}
                            activateData={false}
                            onZoomDomainChange={(domainZoom) => {
                              setScrollActive(false);
                              setDomain({ x: domainZoom.x });
                            }}
                            voronoiDimension="x"
                            labels={() => ` `}
                            labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout data={data} />} />}
                          />
                        }>
                        <VictoryGroup offset={intervalGraph === 1 && numOfGraphsSelected < 4 ? (props.activeGraphs.includes('PREDICTION_ACTIVE_ENERGY') ? 0 : 50) : intervalGraph === 1 && numOfGraphsSelected >= 5 ? 20 : intervalGraph === 1 && numOfGraphsSelected === 4 ? 30 : intervalGraph === 3 && numOfGraphsSelected <= 2 ? 45 : intervalGraph === 3 && numOfGraphsSelected > 2 ? 20 : (props.activeGraphs.includes('PREDICTION_ACTIVE_ENERGY') ? 0 : 10)}>
                          {!(props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN')) ?
                            data.map((d, i) => (
                              <VictoryBar
                                key={d.id}
                                x={"x_sec"}
                                y={(valueY) => valueY?.y}
                                data={d.data.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1])}
                                barRatio={d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? 10 : 50}
                                barWidth={d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? getBarWidth(intervalGraph, numOfGraphsSelected) : getBarWidth(intervalGraph, numOfGraphsSelected) + (intervalGraph === 1 ? 20 : 4)}
                                /* barWidth={(intervalGraph === 1 && numOfGraphsSelected === 1) ? 60 : intervalGraph === 3 || (intervalGraph === 1 && numOfGraphsSelected > 1) ? 40 : (intervalGraph === 2 && numOfGraphsSelected > 1) ? 12 : 20} */
                                cornerRadius={{ topRight: 4, topLeft: 4 }}
                                style={{ data: { 
                                            fill: ({ datum }) => (data.length === 1 && datum.y >= getMax(data) ? barStyles[2].data.fill : d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? barStyles[i - Math.floor(i / barStyles.length) * barStyles.length].data.fill : '#F5FED6'), 
                                            fillOpacity: d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? 1 : 0.2,
                                            stroke: '#cefc34',
                                            strokeWidth: d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? 0 : 1,
                                            strokeDasharray: 4
                                          } }}
                                events={[
                                  {
                                    target: "data",
                                    eventHandlers: {
                                      onMouseOver: () => {
                                        track('Graphicbar hover', { Rank_ID: props.rankGraphId, Project_Type: props.activeServicesEnergy.map(function (service) { return service?.type?.name }) })
                                        return [
                                          {
                                            target: "data",
                                            mutation: () => ({ style: barStyles[barStyles.length - 1].data })
                                          }
                                        ]
                                      },
                                      onMouseOut: () => {
                                        return [
                                          {
                                            target: "data",
                                            mutation: () => { },
                                          }
                                        ]
                                      },
                                      onClick: handleBarClick
                                    },
                                  },
                                ]}
                              />
                            )) :
                            data?.filter(item => item?.value !== 'IRRADIANCE').map((d, i) => (
                              <VictoryBar
                                key={d.id}
                                x={"x_sec"}
                                y={(valueY) => valueY?.y / maxNormalize[d?.value]}
                                data={d.data.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1])}
                                barRatio={10}
                                barWidth={getBarWidth(intervalGraph, numOfGraphsSelected)}
                                cornerRadius={{ topRight: 4, topLeft: 4 }}
                                style={{ data: { fill: ({ datum }) => (data.length === 1 && datum.y >= getMax(data) ? barStyles[2].data.fill : barStyles[i - Math.floor(i / barStyles.length) * barStyles.length].data.fill) } }}
                                events={[
                                  {
                                    target: "data",
                                    eventHandlers: {
                                      onMouseOver: () => {
                                        return [
                                          {
                                            target: "data",
                                            mutation: () => ({ style: barStyles[barStyles.length - 1].data }),
                                          }
                                        ]
                                      },
                                      onMouseOut: () => {
                                        return [
                                          {
                                            target: "data",
                                            mutation: () => { },
                                          }
                                        ]
                                      },
                                      onClick: handleBarClick
                                    },
                                  },
                                ]}
                              />))
                          }
                        </VictoryGroup>
                        {props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN') ?
                          <VictoryArea
                            x={"x_sec"}
                            y={(d, i) => {
                              if (maxNormalize?.IRRADIANCE !== 0) {
                                return d?.y / maxNormalize?.IRRADIANCE;
                              }
                              else {
                                return 0;
                              }
                            }}
                            key={data?.filter(item => item?.value === 'IRRADIANCE')[0]?.id}
                            interpolation={"monotoneX"}
                            data={data?.filter(item => item?.value === 'IRRADIANCE')[0]?.data?.filter((item) => item?.x_sec >= domain.x[0] - 86400 && item?.x_sec <= domain.x[1] + 86400)}
                            style={linearStyles[2]}
                            dependentAxis
                          />
                          : null}
                        {props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN') ?
                          <VictoryAxis
                            dependentAxis
                            label={"Irradiancia (W/m2)"}
                            tickFormat={(x) => Math.round(x * 100 * maxNormalize?.IRRADIANCE / 100)}
                            tickLabelComponent={<VictoryLabel dx={graphContainerWidth - 20} dy={-10} textAnchor={"middle"} verticalAnchor={"middle"} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>}
                            style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY, axisLabel: { fontSize: 12, dx: graphContainerWidth - 40 } }}
                          />
                          : null}
                        <VictoryAxis
                          dependentAxis
                          tickFormat={(x) => {
                            if (props.activeGraphs?.includes('IRRADIANCE_GEN')) {
                              return Math.round(x * 100 * maxNormalize?.GENERATION / 100)
                            } else {
                              return Math.round(x * 100) / 100;
                            }
                          }}
                          tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={"middle"} verticalAnchor={"middle"} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>}
                          style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY, axisLabel: { fill: "red" } }}
                        />
                        <VictoryAxis tickValues={data[0].data.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1]).map((item) => item.x_sec)} tickFormat={(x) => `${handlerFormatAxis(x, intervalGraph, props.language.language_locale)}`} style={{ tickLabels: tickLabelsX }} />
                        {domain && (intervalGraph === 1 || intervalGraph === 2) ? <DotSliderLine /> : null}
                        {data.length === 1 && data[0]?.data.length && props.enableAverageLine ? <AverageLine /> : null}
                      </VictoryChart>
                    ) : (
                      <VictoryChart
                        height={500}
                        width={graphContainerWidth}
                        domainPadding={{ x: 0, y: [12, 60] }}
                        domain={entireDomain}
                        padding={{ top: 35, bottom: 100, left: 0, right: 0 }}
                        singleQuadrantDomainPadding={{ y: false }}
                        containerComponent={
                          <VictoryZoomVoronoiContainer
                            allowZoom={false}
                            allowDrag={false}
                            allowDraw={false}
                            allowResize={false}
                            zoomDimension={"x"}
                            zoomDomain={domain}
                            activateData={false}
                            onZoomDomainChange={(domainZoom) => {
                              setScrollActive(false)
                              setDomain({ x: domainZoom.x })
                            }}
                            voronoiDimension="x"
                            labels={() => ` `}
                            labelComponent={<VictoryTooltip flyoutComponent={<CustomFlyout data={data} />} />}
                          />
                        }>
                        <VictoryGroup>
                          {!(props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN')) ?
                            data.map((d, i) => (
                              <VictoryArea
                                x={"x_sec"}
                                key={d.id}
                                interpolation={"monotoneX"}
                                data={d.data.filter((item) => (item?.x_sec >= domain.x[0] - 7200 && item?.x_sec <= domain.x[1] + 7200) && !(item?.y === 0 && item?.x_sec >= (moment().startOf('day').valueOf() / 1000)))}
                                style={d?.value !== 'PREDICTION_ACTIVE_ENERGY' ? linearStyles[i - Math.floor(i / linearStyles.length) * linearStyles.length] : 
                                {data: {stroke: '#f9fafb', strokeWidth: 1, fill: `url(#linearNeutralTransparent25)`, fillOpacity: 0.2, strokeDasharray: 3}}}
                              />
                            )) :
                            data?.filter(item => item?.value !== 'IRRADIANCE').map((d, i) => (
                              <VictoryBar
                                key={d.id}
                                x={"x_sec"}
                                y={(valueY) => valueY?.y / maxNormalize?.GENERATION}
                                data={d.data.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1])}
                                barRatio={10}
                                barWidth={getBarWidth(intervalGraph, numOfGraphsSelected)}
                                cornerRadius={{ topRight: 4, topLeft: 4 }}
                                style={{ data: { fill: ({ datum }) => (data.length === 1 && datum.y >= getMax(data) ? barStyles[2].data.fill : barStyles[i - Math.floor(i / barStyles.length) * barStyles.length].data.fill) } }}
                              />))
                          }
                        </VictoryGroup>
                        {props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN') ?
                          <VictoryArea
                            x={"x_sec"}
                            y={(d, i) => {
                              if (maxNormalize?.IRRADIANCE !== 0) {
                                return d?.y / maxNormalize?.IRRADIANCE;
                              }
                              else {
                                return 0;
                              }
                            }}
                            key={data?.filter(item => item?.value === 'IRRADIANCE')[0]?.id}
                            interpolation={"monotoneX"}
                            data={data?.filter(item => item?.value === 'IRRADIANCE')[0]?.data?.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1])}
                            style={linearStyles[1]}
                            dependentAxis
                          />
                          : null}
                        {props.activeGraphs.length && props.activeGraphs.includes('IRRADIANCE_GEN') ?
                          <VictoryAxis
                            dependentAxis
                            label={"Irradiancia (W/m2)"}
                            tickFormat={(x) => Math.round(x * 100 * maxNormalize?.IRRADIANCE / 100)}
                            tickLabelComponent={<VictoryLabel dx={graphContainerWidth - 20} dy={-10} textAnchor={"middle"} verticalAnchor={"middle"} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>}
                            style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY, axisLabel: { fontSize: 12, dx: graphContainerWidth - 40 } }}
                          />
                          : null}
                        <VictoryAxis
                          dependentAxis
                          tickFormat={(x) => {
                            if (props.activeGraphs?.includes('IRRADIANCE_GEN')) {
                              return Math.round(x * 100 * maxNormalize?.GENERATION / 100)
                            } else if (props.activeGraphs?.includes('OPERATION_STATE')) {
                              return formatOperationStateValue(x)
                            } else {
                              return Math.round(x * 100) / 100;
                            }
                          }}
                          tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={"middle"} verticalAnchor={"middle"} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>}
                          style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }}
                        />
                        <VictoryAxis tickValues={data[0].data.filter((item) => item?.x_sec >= domain.x[0] && item?.x_sec <= domain.x[1]).map((item) => item.x_sec)} tickFormat={(x) => `${handlerFormatAxis(x, intervalGraph, props.language.language_locale)}:00`} style={{ tickLabels: tickLabelsX }} />
                        {data.length === 1 && data[0]?.data.length && props.enableAverageLine ? <AverageLine /> : null}
                        {!props.activeGraphs.includes('IRRADIANCE_GEN') && data.length && domain && data.map((d, i) => (
                          <FinalDot
                            key={i}
                            data={d.data.filter((item) => !(item?.y === 0 && item?.x_sec >= (moment().startOf('day').valueOf() / 1000)))}
                            style={linearStyles[i - Math.floor(i / linearStyles.length) * linearStyles.length]}
                            valueData={d?.value}
                          />
                        ))}
                      </VictoryChart>
                    )}
                  </div>
                ) : (
                  <RenderEmptyData />
                )}
              </div>
              <GraphIndicators data={data} domain={domain} intervalGraph={intervalGraph} />
            </div>
          </div>
          <ExportGraph />
        </Fragment>
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  intervalGraph: state.energy.intervalGraph,
  graphDateFrom: state.energy.graphDateFrom,
  graphDateTo: state.energy.graphDateTo,
  graphData: state.energy.graphData,
  showCalendar: state.shared.showCalendar,
  language: state.shared.language,
  enableScrollData: state.energy.enableScrollData,
  activeGraphs: state.energy.activeGraphs,
  scrollActive: state.energy.scrollActive,
  tariff: state.energy.tariff,
  showReportModal: state.energy.showReportModal,
  graphTypePicker: state.shared.startOnboarding.graph_type_picker,
  graphPeriodPicker: state.shared.startOnboarding.graph_period_picker,
  graphCalendarPicker: state.shared.startOnboarding.graph_calendar_picker,
  graphExportData: state.shared.startOnboarding.graph_export_data,
  controlledTypePicker: state.shared.controlledOnboarding.graph_type_picker,
  controlledPeriodPicker: state.shared.controlledOnboarding.graph_period_picker,
  controlledCalendarPicker: state.shared.controlledOnboarding.graph_calendar_picker,
  controlledExportPicker: state.shared.controlledOnboarding.graph_export_data,
  prevStateBar: state.energy.prevStateBar,
  enableAverageLine: state.energy.enableAverageLine,
  rankGraphId: state.energy.rankGraphId,
  showPulseAnimation: state.shared.showPulseAnimation,
  graphLoading: state.energy.graphLoading,
  activeServicesEnergy: state.services.activeServicesEnergy,
  activeServicesPlus: state.services.activeServicesPlus
})

const mapDispatchToProps = (dispatch) => ({
  loadGraphAsync: (payload, type) => dispatch(energyActions.loadGraphAsync(payload, type)),
  setGraphDates: (payload) => dispatch(energyActions.setGraphDates(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setShowCalendar: (payload) => dispatch(sharedActions.setShowCalendar(payload)),
  setShowReportModal: (payload) => dispatch(energyActions.setShowReportModal(payload)),
  setGraphActive: (payload) => dispatch(energyActions.setGraphActive(payload)),
  setScrollActive: (payload) => dispatch(energyActions.setScrollActive(payload)),
  setIntervalGraph: (payload) => dispatch(energyActions.setIntervalGraph(payload)),
  setControlledOnboarding: (payload) => dispatch(sharedActions.setControlledOnboarding(payload)),
  setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
  setDetailDataGraph: (payload) => dispatch(energyActions.setDetailDataGraph(payload)),
  setPrevStateBar: (payload) => dispatch(energyActions.setPrevStateBar(payload)),
  setShowPulseAnimation: (payload) => dispatch(sharedActions.setShowPulseAnimation(payload)),
  loadDetailService: (payload) => dispatch(energyActions.loadDetailService(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Graph)
