import React from 'react'

const InputRounded = ({ placeholder, icon, value, type, required, disabled = false, onChange, onPressEnter, focus = false, error }) => {
    return (
        <div className='column-start w-100'>
            <div className='row-start align-items-center gap-2 rounded-pill bg-gray-500 p-3 w-100'>
                {icon ? <i className={`fa-light fa-${icon} txt-gray-400 txt-12`}></i> : null}
                <input disabled={disabled} type={type} autoFocus={focus} className={`w-100 border-0 bg-transparent txt-white txt-phrase txt-8`} id="floatingInput" placeholder={placeholder} required={required} value={value} onChange={onChange} onKeyDown={({ keyCode }) => keyCode === 13 && onPressEnter && onPressEnter()} />
            </div>
            {error ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap'>{error}</p> : null}
        </div>
    );
}

export default InputRounded