import React from 'react';
import { connect } from 'react-redux';

import CurrencyFormat from 'react-currency-format';

const WalletCard = ({size = 'medium', ...props}) => {

    return (
        <div className="col-12 border-1 border-color-gray-700 rounded-20">
            <div className={`row-start px-3 py-${size === 'medium' ? 3 : 1} bg-gray-800 rounded-top-20`}>
                <h4 className={`txt-white txt-phrase txt-bold txt-${size === 'medium' ? 10 : 8}`}>Tu balance</h4>
            </div>
            <div className={`wallet-card-container px-3 py-${size === 'medium' ? 3 : 0} rounded-bottom-20 shadow`} style={{height: size === 'small' && 80}}>
                <div className="row-start align-items-center h-100">
                    <div className="position-relative row-center align-items-center p-3 border-4 border-color-white rounded-30">
                        <i className={`position-absolute fa-solid fa-bolt txt-green-500 txt-${size === 'medium' ? 12 : 10}`}></i>
                    </div>
                    <div className="column-start align-items-end ms-4">
                        <h4 className={`txt-white txt-phrase txt-bold txt-${size === 'medium' ? 12 : 10}`}>
                            <CurrencyFormat value={props.neugets} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </h4>
                        <h4 className={`txt-white txt-phrase txt-bold txt-${size === 'medium' ? 10 : 8}`}>NEUgets</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    neugets: state.accounts.neugets
});

export default connect(mapStateToProps)(WalletCard);