import React, { useState } from "react";
import { connect } from 'react-redux';

import * as sharedActions from '../../../../store/actions/Shared';

import { Popover } from '@idui/react-popover';

import { track } from "@amplitude/analytics-browser";

const GraphPicker = ({ active, setSelectedOption, setShowBlackScreen, language, phases }) => {

    const data = phases === 3 ?
        [
            {
                id: 1,
                property: "active_power",
                title: language.plus_graph_picker_option_active_power,
                unit: "W"
            },
            {
                id: 2,
                property: "reactive_power",
                title: language.plus_graph_picker_option_reactive_power,
                unit: "VAr"
            },
            {
                id: 3,
                property: "rms_voltage_1",
                title: language.plus_graph_picker_option_rms_voltage_1,
                unit: "V"
            },
            {
                id: 4,
                property: "rms_voltage_2",
                title: language.plus_graph_picker_option_rms_voltage_2,
                unit: "V"
            },
            {
                id: 7,
                property: "rms_voltage_3",
                title: language.plus_graph_picker_option_rms_voltage_3,
                unit: "V"
            },
            {
                id: 5,
                property: "rms_current_1",
                title: language.plus_graph_picker_option_rms_current_1,
                unit: "A"
            },
            {
                id: 6,
                property: "rms_current_2",
                title: language.plus_graph_picker_option_rms_current_2,
                unit: "A"
            },
            {
                id: 8,
                property: "rms_current_3",
                title: language.plus_graph_picker_option_rms_current_3,
                unit: "A"
            },
        ] :
        [
            {
                id: 1,
                property: "active_power",
                title: language.plus_graph_picker_option_active_power,
                unit: "W"
            },
            {
                id: 2,
                property: "reactive_power",
                title: language.plus_graph_picker_option_reactive_power,
                unit: "VAr"
            },
            {
                id: 3,
                property: "rms_voltage_1",
                title: language.plus_graph_picker_option_rms_voltage_1,
                unit: "V"
            },
            {
                id: 4,
                property: "rms_voltage_2",
                title: language.plus_graph_picker_option_rms_voltage_2,
                unit: "V"
            },
            {
                id: 5,
                property: "rms_current_1",
                title: language.plus_graph_picker_option_rms_current_1,
                unit: "A"
            },
            {
                id: 6,
                property: "rms_current_2",
                title: language.plus_graph_picker_option_rms_current_2,
                unit: "A"
            },
        ];

    const [focus, setFocus] = useState(false);

    return (
        <Popover
            lazy
            closeOnEnter
            fitMaxHeightToBounds
            fitMaxWidthToBounds
            trigger={'click'}
            placement={'bottom'}
            arrowColor={'#161617'}
            className={'p-0 bg-gray-500'}
            arrowSize={20}
            offset={[0, 0]}
            onChangeOpen={(state) => {
                setShowBlackScreen(state);
                setFocus(state);                              
            }}
            animation={{
                animate: { opacity: 1, scale: 1 },
                exit: { opacity: 0, scale: 0.9, transition: { duration: 0.1 } },
                initial: { opacity: 0, scale: 0.9 }
            }}
            content={
                <div className="column-start align-items-center px-3 py-2">
                    {data.map(option => (
                        <div
                            key={option.id}
                            className={`row-between align-items-center my-2 pe-pointer p-2 rounded-8 ${option.id === active.id && "bg-gray-800"}`}
                            onClick={() => {
                                setSelectedOption(option);
                                track('Plus variable selected', {Plus_Variable_ID: option?.property})
                            }}
                        >
                            <div className="me-3 txt-phrase txt-6 txt-white pe-pointer">{option.title}</div>
                            {option.id === active.id && <i className="fa-solid fa-check-circle txt-green-500 txt-8"></i>}
                        </div>
                    ))}
                </div>
            }>
            <div className="row-center align-items-center">
                <button className={`row-center align-items-center bg-gray-450 border-1 border-color-gray-800 rounded-30 px-3 ${focus && "zindex-5"}`} style={{ height: 32 }}>
                    <i className="fa-solid fa-chart-simple txt-10 txt-white"></i>
                </button>
            </div>
        </Popover>);
}

const mapStateToProps = (state) => ({
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphPicker);