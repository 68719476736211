import React, { useEffect } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../../../store/actions/Services';
import * as plusActions from '../../../../store/actions/Plus';

import Header from "../../../shared/Header";
import Graph from "./graph/Graph";
import Cards from "./Cards";
import ServicesPlus from "../shared/ServicesContainer";

const AlwaysOn = (props) => {

    let account = props.account;
    const loadServicesAsync = props.loadServicesAsync;
    let intervalGraphAlwaysOn = props.intervalGraphAlwaysOn;
    let activeServicesPlus = props.activeServicesPlus;
    const loadGraphAlwaysOnAsync = props.loadGraphAlwaysOnAsync;

    useEffect(() => {
        if (!props.activeServicesPlus?.length) {
            account && loadServicesAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        if (activeServicesPlus.length) {
            loadGraphAlwaysOnAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeServicesPlus, intervalGraphAlwaysOn]);

    return (
        <section className="column-start align-items-center vh-100 w-100">
            <Header moduleName={props.language.plus_always_on_title} backToButton={true} />
            <div className="column-center align-items-center w-100">
                <ServicesPlus module={'alwaysOn'} />
                <Cards />
                <Graph />
            </div>
        </section>
    )
};

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    account: state.accounts.account,
    language: state.shared.language,
    intervalGraphAlwaysOn: state.plus.intervalGraphAlwaysOn,
    loadingGraph: state.plus.loadingAlwaysOnModule,
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
    loadGraphAlwaysOnAsync: () => dispatch(plusActions.loadGraphAlwaysOnAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlwaysOn);