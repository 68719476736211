import React from 'react'
import { connect } from 'react-redux';

import { track } from "@amplitude/analytics-browser";

import * as plusActions from '../../../../../store/actions/Plus';

const GraphPeriodPicker = (props) => {

    const changePeriod = (interval) => {
        let eventProperty = '';

        if (props.intervalGraph !== interval) {
            props.setIntervalGraph(interval);
            switch (interval) {
                case 1:
                    eventProperty = 'Rank_Weekly'
                    break
                case 2:
                    eventProperty = 'Rank_Monthly'
                    break
                case 3:
                    eventProperty = 'Rank_Yearly'
                    break
                default:
                    eventProperty = 'Rank_Weekly'
                    break
            }
            track('Standby rank selected', {Rank_ID: eventProperty})
        }
    }

    return (
        <div className="position-relative row-start align-items-center py-2 rounded-pill" style={{ width: 400, border: '4px solid #1D1D1D', backgroundColor: '#1D1D1D' }}>
            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { changePeriod(1) }}>
                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.energy_graph_period_picker_week}</div>
            </div>
            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { changePeriod(2) }}>
                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.energy_graph_period_picker_month}</div>
            </div>
            <div className="row-center align-items-center h-100 col-4 zindex-2 pe-pointer" onClick={() => { changePeriod(3) }}>
                <div className="txt-white txt-phrase txt-6 txt-regular pe-pointer">{props.language.energy_graph_period_picker_year}</div>
            </div>
            <div id="home-graph-picker" className="position-absolute h-100 top-0 col-4 bg-gray-450 border-1 border-color-gray-800 rounded-pill zindex-1" style={{ left: `${(props.intervalGraph - 1) * (100 / 3)}%` }}></div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    intervalGraph: state.plus.intervalGraphAlwaysOn,
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    setIntervalGraph: (payload) => dispatch(plusActions.setIntervalGraphAlwaysOn(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphPeriodPicker);