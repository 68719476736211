import React from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';

import { Modal, Whisper, Tooltip } from "rsuite";

import InvoiceDetails from './InvoiceDetails';

const InvoiceModal = (props) => {

    let invoiceShowIndex = props.invoiceShowIndex;
    let invoiceShow = props.invoiceShow;
    let selectedInvoices = props.selectedInvoices;
    let setInvoiceShowIndex = props.setInvoiceShowIndex;

    return (
        <Modal
            size="lg"
            show={props.showInvoice}
            backdropClassName={'bg-black opacity-80 pe-pointer'}
            //style={{ borderRadius: "16px" }}
            onHide={() => props.setShowInvoice(false)}>
            <div style={{ minHeight: "90vh" }}>
                {invoiceShow ?
                    <div className="row-between align-items-center bg-gray-550 rounded-top-24 w-100 py-2 px-3" style={{ height: '10vh' }}>
                        <button className={`row-center align-items-center button-collapsed rounded-30`} disabled={!invoiceShowIndex} style={{ height: 24, width: 27 }} onClick={() => { invoiceShowIndex && setInvoiceShowIndex(invoiceShowIndex - 1); }}>
                            <Whisper
                                delay={200}
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>{props.language.invoices_tooltip_previous_bill}</Tooltip>}>
                                <i className="fa-regular fa-chevron-left txt-6"></i>
                            </Whisper>
                        </button>
                        <div className="row-center w-100 align-items-center flex-grow-1 mx-3 px-3 py-2 bg-gray-800 rounded-pill">
                            <i className={`fas fa-city txt-8 txt-white me-2`}></i>
                            <div className={`txt-phrase txt-bold txt-6 txt-white`}>{invoiceShow.service_name}</div>
                            <i className={`fas fa-chevron-right txt-8 txt-white mx-2`}></i>
                            <div className="txt-gray-600 txt-phrase txt-6 txt-bold">{props.language.invoices_title_section_bills} {invoiceShow.reference}</div>
                        </div>
                        <div className="row-start align-items-center">
                            <button className={`row-center align-items-center button-collapsed rounded-30`} disabled={!(selectedInvoices.length > invoiceShowIndex + 1)} style={{ height: 24, width: 27 }} onClick={() => { selectedInvoices.length > invoiceShowIndex + 1 && setInvoiceShowIndex(invoiceShowIndex + 1) }}>
                                <Whisper
                                    delay={200}
                                    placement="bottom"
                                    trigger="hover"
                                    speaker={<Tooltip>{props.language.invoices_tooltip_next_bill}</Tooltip>}>
                                    <i className="fa-regular fa-chevron-right txt-6"></i>
                                </Whisper>
                            </button>
                            {!props.invoiceUrl ?
                                <button className={`row-center align-items-center button-collapsed rounded-30 ms-2`} style={{ height: 24, width: 27 }} onClick={async() => { await props.downloadInvoiceAsync(invoiceShow?.pdf_path) }}>
                                    <Whisper
                                        delay={200}
                                        placement="bottom"
                                        trigger="hover"
                                        speaker={<Tooltip>{props.language.invoices_tooltip_download_bill}</Tooltip>}>
                                        <i className="fa-regular fa-arrow-down-to-bracket txt-6"></i>
                                    </Whisper>
                                </button> : null}
                            <button className={`row-center align-items-center button-collapsed rounded-30 ms-2`} style={{ height: 24, width: 27 }} onClick={() => { props.setShowInvoice(false) }}>
                                <Whisper
                                    delay={200}
                                    placement="bottom"
                                    trigger="hover"
                                    speaker={<Tooltip>{props.language.invoices_tooltip_close_bill}</Tooltip>}>
                                    <i className="fa-regular fa-xmark txt-6"></i>
                                </Whisper>
                            </button>
                        </div>
                    </div> : null}
                {!props.invoiceUrl ? <div className='column-start gap-4 overflow-auto border-none-scroll rounded-bottom-24' style={{ maxHeight: '80vh' }}>
                    <InvoiceDetails />
                </div> :
                    <div className='column-start gap-4 overflow-hidden rounded-bottom-24' style={{ height: '80vh' }}>
                        <object data={props.invoiceUrl} type="application/pdf" width="100%" height="100%">
                            <p className="row-center align-items-center w-100 vh-100 txt-white txt-title txt-12 mt-5">{props.language.invoices_text_error_show_bills}</p>
                        </object>
                    </div>}
            </div>
        </Modal>
    )

};

const mapStateToProps = (state, ownProps) => ({
    language: state.shared.language,
    invoiceShow: state.invoices.invoiceDetails,
    invoiceShowIndex: state.invoices.invoiceDetailsIndex,
    selectedInvoices: state.invoices.selectedInvoices,
    invoiceUrl: ownProps.invoiceUrl
});

const mapDispatchToProps = dispatch => ({
    downloadInvoiceAsync: (payload) => dispatch(invoicesActions.downloadInvoiceAsync(payload)),
    setInvoiceShowIndex: (payload) => dispatch(invoicesActions.setShowInvoiceDetailsIndex(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModal);