import * as creators from '../creators/Pays';

const initialState = {
    banks: [],
    invoicesToPay: [],
    typeComponent: 'METHOD',
    infoPayment: {
        commission: 0,
        totalPayment: 0,
        neugets: 0
    },
    paymentLoading: false,
    paymentMethods: [],
    paymentProvider: null
}

export const paysReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_PAYS_BANKS:
            return { ...state, banks: payload };
        case creators.SET_PAYS_INVOICES_TO_PAY:
            return { ...state, invoicesToPay: payload };
        case creators.SET_PAYS_TYPE_COMPONENT:
            return { ...state, typeComponent: payload };
        case creators.SET_PAYS_INFO_PAYMENT:
            return { ...state, infoPayment: payload };
        case creators.SET_PAYS_PAYMENT_LOADING:
            return { ...state, paymentLoading: payload };
        case creators.SET_PAYS_PAYMENT_METHODS:
            return { ...state, paymentMethods: payload };
        case creators.SET_PAYS_PAYMENT_PROVIDER:
            return { ...state, paymentProvider: payload };
        default:
            return state;
    }
}
