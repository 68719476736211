import React from 'react';
import step1 from '../../../imgs/onboarding/step1-profile.png';
import step1En from '../../../imgs/onboarding/english/step1-profile-en.png';
import step2 from '../../../imgs/onboarding/step2-profile.png';
import step2En from '../../../imgs/onboarding/english/step2-profile-en.png';
import step3 from '../../../imgs/onboarding/step3-profile.png';
import step3En from '../../../imgs/onboarding/english/step3-profile-en.png';
import step4 from '../../../imgs/onboarding/step3-profile.png';
import step4En from '../../../imgs/onboarding/english/step3-profile-en.png';

export const stepsProfile = (language) => {
    return ([
        {
            content:
                <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 152, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step1-profile" />
                </div>,
            title: language.onboarding_profile_title_step1,
            description: language.onboarding_profile_description_step1,
            placement: "center",
            target: "#profile_menu",
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 152, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2: step2En} className="w-100 h-100" alt="step2-profile" />
                </div>,
            title: language.onboarding_profile_title_step2,
            description: language.onboarding_profile_description_step2,
            placement: "left",            
            target: "#section_alerts",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
                    <img src={language.language_locale === 'es'? step3 : step3En} className="w-100 h-100" alt="step3-profile" />
                </div>,
            title: language.onboarding_profile_title_step3,
            description: language.onboarding_profile_description_step3,
            placement: "left",            
            target: "#section_user",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
                    <img src={language.language_locale === 'es'? step4 : step4En} className="w-100 h-100" alt="step4-profile" />
                </div>,
            title: language.onboarding_profile_title_step4,
            description: language.onboarding_profile_description_step4,
            placement: "left-end",            
            target: "#section_onboarding",
            floaterProps: {
                disableAnimation: true,
            },
            disableBeacon: true,
            scrollOffset: 40,
            steps: 4
        },
    ])
};