export const SET_USER = "SET_USER";
export const SET_USER_CAPABILITIES = "SET_USER_CAPABILITIES";
export const SET_PROFILE_PROGRESS = "SET_PROFILE_PROGRESS";
export const SET_SAVING_INFO_USER = "SET_SAVING_INFO_USER";
export const SET_USER_ACCESS_EDIT = "SET_USER_ACCESS_EDIT";
export const SET_SHOW_CREATE_USER_MODAL = "SET_SHOW_CREATE_USER_MODAL";
export const SET_SHOW_USERS_LIST_MODAL = "SET_SHOW_USERS_LIST_MODAL";
export const SET_USER_JOBS = "SET_USER_JOBS";
export const SET_USERS_ACCOUNT = "SET_USERS_ACCOUNT";
export const SET_USERS_ACCOUNT_LOADING = "SET_USERS_ACCOUNT_LOADING";
export const SET_USER_CHANNELS = "SET_USER_CHANNELS";
export const SET_USER_PERSONAL_INTERESTS = "SET_USER_PERSONAL_INTERESTS";
export const SET_USER_PREFERENCE_INFO = "SET_USER_PREFERENCE_INFO";
export const SET_SHOW_MODAL_USER_PREFERENCE = "SET_SHOW_MODAL_USER_PREFERENCE";
export const SET_USER_TOKEN_API = "SET_USER_TOKEN_API";
export const SET_LOADING_NEW_USER = "SET_LOADING_NEW_USER";