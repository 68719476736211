import React, { Fragment } from 'react'

import TextTooltip from '../../genius/TextTooltip'

const ButtonCircle = ({ focus, icon, onClick, background = 'gray-450', borderColor = 'gray-800', color = 'gray-700', borderWidth = 1, size = 32, sizeIcon = 12, additionalStyle = "", showPulse = false, disabled = false, language }) => {

    return (       
        <Fragment> 
            {disabled ? <TextTooltip bgColor={"black-opacity"}
                content={
                <div className="px-3 py-2">
                    <p className="rounded-pill py-1 px-2 txt-white txt-phrase txt-6">{language.genius_tooltip_button_graphs}</p>
                </div>
                }
                text={
                    <button disabled={disabled} className={`position-relative rounded-circle ${showPulse && 'pulse'} ${focus ? "zindex-8" : ""} text-${color} bg-${background} border-${borderWidth} border-color-${borderColor} ${additionalStyle}`} style={{ height: size, width: size }} onClick={onClick}>
                        <i className={`button-circle-icon position-absolute fa-light fa-${icon} txt-${sizeIcon} txt-${color}`}></i>
                    </button>
                }
            /> :
            <button disabled={disabled} className={`position-relative rounded-circle ${showPulse && 'pulse'} ${focus ? "zindex-8" : ""} text-${color} bg-${background} border-${borderWidth} border-color-${borderColor} ${additionalStyle}`} style={{ height: size, width: size }} onClick={onClick}>
                <i className={`button-circle-icon position-absolute fa-light fa-${icon} txt-${sizeIcon} txt-${color}`}></i>
            </button>}
        </Fragment>
    )
}


export default ButtonCircle