import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import * as plus from "../../../../store/actions/Plus";
import * as energyActions from "../../../../store/actions/Energy";
import * as servicesActions from "../../../../store/actions/Services";
import * as accountsActions from "../../../../store/actions/Accounts";

import { gradients, grid, tickLabelsY, tickStyle } from "../../../shared/svgs/GraphStyles";

import { VictoryChart, VictoryArea, VictoryAxis, createContainer, VictoryLabel, Rect } from "victory";

import moment from "moment";

import 'moment/locale/es'

import _ from 'lodash';

import { Transition } from 'react-transition-group';

import { Loader, Animation } from 'rsuite';

import { Link, useHistory } from 'react-router-dom';

import GraphPicker from "./GraphPicker";
import CreateTag from "../tags/CreateTag";
import empty_data_device from "../../../../imgs/empty-data-device.svg";

import ExportGraph from "../export/ExportGraph";
import ButtonCircle from "../../../shared/utilities/ButtonCircle";

import Draggable from 'react-draggable';

moment.locale('es');

const BouncingDotsLoader = (props) => {
    return (
        <div className="bouncing-loader">
            {[0, 1, 2].map((_item, index) => (<div key={index} />))}
        </div>
    );
};


const getInitPage = (timezone) => {
    const initPageByTime = new Date().toLocaleString("en-US", { timeZone: timezone });
    return new Date(initPageByTime).getHours();
}


const graphLoadingContainer = (loading, language) => <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
    <Transition
        in={loading}
        timeout={100}>
        {state => (
            <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                <div className="column-center align-items-center w-100 h-100">
                    <Loader inverse center size="md" content={language.loader_text_content} />
                </div>
            </div>
        )}
    </Transition>
</div>;

const LoadingHistoryData = () =>
    <div className="position-absolute left-50 top-0 mt-0 zindex-1">
        <BouncingDotsLoader />
    </div>;

const VictoryZoomContainer = createContainer('zoom', 'voronoi');

const areaStyle = {
    data: {
        fill: "url(#linearGreenTransparent25)",
        stroke: "#CEFC34",
        strokeWidth: 1
    }
}


const Graph = (props) => {

    const history = useHistory();

    const formatNumber = number => {
        if (Math.abs(number) >= 500) {
            number = (number / 1000).toFixed(2);
            return (`${number} k`)
        }
        else {
            return (`${(number).toFixed(2)} `);
        }
    }

    const defaultOption = {
        id: 1,
        property: "active_power",
        title: props.language.plus_graph_picker_option_active_power,
        unit: "W"
    }

    const pointTagDragInit = { x: 0, y: 0, positionX: 0 };

    const [options, setOptions] = useState(defaultOption);

    const [day, setDay] = useState(0);

    const [data, setData] = useState([]);

    const [domain, setDomain] = useState(null);
    const [entireDomain, setEntireDomain] = useState(null);
    const [showStreaming, setShowStreaming] = useState(true);
    const [indicatorZoom, setIndicatorZoom] = useState(1);
    const [zoomMinutes, setZoomMinutes] = useState(10);
    const [showExportGraph, setShowExportGraph] = useState(false);
    const [grabbing, setGrabbing] = useState(false);
    const [grabbingTag, setGrabbingTag] = useState(false);
    const [pointTagDrag, setPointTagDrag] = useState(pointTagDragInit);
    const [enableTag, setEnableTag] = useState(false);
    const [pointOne, setPointOne] = useState(null);
    const [pointTwo, setPointTwo] = useState(null);
    const [active1, setActive1] = useState(true);
    const [active2, setActive2] = useState(false);
    const [showCreateTag, setShowCreateTag] = useState(false);
    const [pointTooltip, setPointTooltip] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [dataDomain, setDataDomain] = useState([]);
    const [enableLoaderGraph, setEnableLoaderGraph] = useState(false);


    let dataGraph = props.dataGraph;
    let paddingDomain = 1.1;
    let deviceActive = props.devices.filter(value => value?.id_service === props.activeServicesPlus[0]?.idService)[0];

    useEffect(() => {
        if (!showCreateTag) {
            setPointTagDrag(pointTagDragInit);
        }
        // eslint-disable-next-line
    }, [showCreateTag]);

    useEffect(() => {
        if (!props.devices.length) {
            props.loadDevices()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.devices.length && props.activeServicesPlus.length && !props.loadingData) {
            setIndicatorZoom(1);
            setZoomMinutes(10);
            props.loadInitData(getInitPage(deviceActive?.timezone) + 1);
            setDomain(null);
            setShowStreaming(true);
            setEnableTag(false);
            setEnableLoaderGraph(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.devices, props.activeServicesPlus]);

    useEffect(() => {
        if (dataGraph?.length > 0) {
            const minValueY = _.minBy(dataGraph, d => d[options.property])[options.property];
            const maxValueY = _.maxBy(dataGraph, d => d[options.property])[options.property];
            const intervalY = [minValueY < 0 ? minValueY * paddingDomain : 0, maxValueY < 0 ? 0 : maxValueY * paddingDomain];
            const intervalX = [dataGraph[0].time, _.last(dataGraph).time];
            setEntireDomain({ x: intervalX, y: intervalY });
            if (!domain) {
                const filtered = dataGraph.slice(- zoomMinutes * 60);
                const minValueYDomain = _.minBy(filtered, d => d[options.property])[options.property];
                const maxValueYDomain = _.maxBy(filtered, d => d[options.property])[options.property];
                const intervalYDomain = [minValueYDomain < 0 ? minValueYDomain * paddingDomain : 0, maxValueYDomain < 0 ? 0 : maxValueYDomain * paddingDomain];
                setDomain({ x: [intervalX[1] - zoomMinutes * 60 * 1000 < intervalX[0] ? intervalX[0] : intervalX[1] - zoomMinutes * 60 * 1000, intervalX[1]], y: intervalYDomain });
                //setPointTagDrag({...pointTagDrag, x: intervalX[0], y: dataGraph[0][options.property]});
            }
            if (showStreaming && data.length) {
                const filtered = data.slice(- zoomMinutes * 60);
                const minValueYDomain = _.minBy(filtered, d => d[options.property])[options.property];
                const maxValueYDomain = _.maxBy(filtered, d => d[options.property])[options.property];
                const intervalYDomain = [minValueYDomain < 0 ? minValueYDomain * paddingDomain : 0, maxValueYDomain < 0 ? 0 : maxValueYDomain * paddingDomain];
                setDomain({ x: [intervalX[1] - zoomMinutes * 60 * 1000 < intervalX[0] ? intervalX[0] : intervalX[1] - (zoomMinutes) * 60 * 1000, intervalX[1]], y: intervalYDomain });
            }
        }
        else {
            setDomain(null);
            setShowStreaming(true);
            setData([]);
            setIndicatorZoom(1);
            setZoomMinutes(5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGraph, options, showStreaming]);

    useEffect(() => {
        if (data?.length > 0) {
            const minValueY = _.minBy(data, d => d[options.property])[options.property];
            const maxValueY = _.maxBy(data, d => d[options.property])[options.property];
            const intervalY = [minValueY < 0 ? minValueY * paddingDomain : 0, maxValueY < 0 ? 0 : maxValueY * paddingDomain];
            setDomain({ ...domain, y: intervalY });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        if (domain) {
            const widthDomain = props.graphContinerWidth - 108;
            const timeStampEstimated = parseInt(((pointTagDrag?.positionX * (domain?.x[1] - domain?.x[0]) / (widthDomain * 1000)) + (domain?.x[0] / 1000)).toFixed(0) * 1000);
            const itemSelected = data.filter(item => item?.time === timeStampEstimated);
            const valueY = itemSelected.length ? itemSelected[0][options.property] : pointTagDrag.y;
            setPointTagDrag({ ...pointTagDrag, x: timeStampEstimated, y: valueY });
            let filtered = dataGraph.filter(item => item.time >= domain?.x[0] - 120000 && item?.time <= domain?.x[1] + 120000);
            setDataDomain(dataGraph.filter(item => item.time >= domain?.x[0] && item?.time <= domain?.x[1]))
            if (filtered.length > 1200) {
                const k = Math.ceil(filtered.length / 1200);
                filtered = filtered.filter((_d, i) => ((i % k) === 0));
            }
            setData(filtered);
            const dataGraphNoInit = props.dataGraphPage.filter(item => !item?.init);
            const firstAvailablePage = dataGraphNoInit.length ? dataGraphNoInit[0]?.page : getInitPage(deviceActive?.timezone);
            const intervalLeft = (domain?.x[0] - entireDomain?.x[0]) / 60000;
            const verifyPageLeft = props.dataGraphPage.filter(item => item?.page === firstAvailablePage - 1);
            if (intervalLeft <= 60 && !props.enableHistory && !verifyPageLeft.length && (dataGraph.length >= 1800 || (!(dataGraph.length >= 1800) && deviceActive?.id_infrastructure !== 2))) {
                props.loadHistoryData(handleBeforeDay(firstAvailablePage - 1), false);
            }
            if (props.dataGraphPage.length >= 3) {
                const lastAvailablePage = _.last(dataGraphNoInit)?.page;
                const lastValueX = _.last(props.dataGraphPage[props.dataGraphPage.length - 2]?.data)?.time;
                const intervalRight = (lastValueX - domain?.x[1]) / 60000;
                const verifyPage = props.dataGraphPage.filter(item => item?.page === lastAvailablePage + 1);
                if (intervalRight <= 60 && !verifyPage.length && !props.enableHistory && lastAvailablePage + 1 !== _.last(props.dataGraphPage).page - 1) {
                    props.loadHistoryData(handleAfterDay(lastAvailablePage + 1), false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain, props.activeServicesPlus]);

    const handleBeforeDay = page => {
        if (page > 0) return { page: page, day: day, type: 0 }
        else {
            setDay(day + 1);
            return { page: 24, day: day + 1, type: 0 };
        }
    }

    const handleAfterDay = page => {
        if (page < 25) return { page: page, day: day, type: 1 }
        else {
            setDay(day - 1);
            return { page: 1, day: day - 1, type: 1 };
        }
    }

    const handleZoomIn = (e) => {
        e.stopPropagation();
        const minutes = 5;
        const zoom = minutes * 60 * 1000;
        if (domain?.x[0] + zoom <= domain?.x[1] - zoom) {
            setDomain({ ...domain, x: [domain?.x[0] + zoom, domain?.x[1]] });
            setZoomMinutes(zoomMinutes - minutes);
            setIndicatorZoom(0);
        }
        else {
            setIndicatorZoom(1);
        }
    }

    const handleZoomOut = (e) => {
        e.stopPropagation();
        const minutes = 5;
        const zoom = -minutes * 60 * 1000;
        const zoomOutMax = 20 * 60 * 1000;
        if (domain?.x[1] - domain?.x[0] - zoom <= zoomOutMax) {
            setDomain({ ...domain, x: [domain?.x[0] + zoom, domain?.x[1]] });
            setZoomMinutes(zoomMinutes + minutes);
            setIndicatorZoom(0);
        }
        else {
            setIndicatorZoom(-1);
        }
    }


    const DotSliderLine = ({ positionX }) => {
        const positionAdjusted = ((props.graphContinerWidth - 60) / (props.graphContinerWidth - 108)) * positionX + 20
        return (
            <g>
                <line
                    x1={positionAdjusted}
                    x2={positionAdjusted}
                    y1={0}
                    y2={430}
                    stroke="#f9fafb"
                    strokeWidth="2"
                    strokeDasharray={10}
                />
            </g>
        )
    }

    const DotSliderArea = ({ x, scale, positionX }) => {
        x = x ? scale.x(pointOne) : 20;
        const positionAdjusted = ((props.graphContinerWidth - 60) / (props.graphContinerWidth - 108)) * positionX + 20;
        return (
            <g>
                <polygon points={`${x},0 ${x},430 ${positionAdjusted},430 ${positionAdjusted},0`} fill="#E6FE9A" fillOpacity={0.3} />
            </g>
        )
    }

    const Tooltip = ({ scale }) => {
        let x = pointTooltip ? scale.x(pointTooltip?.time) : 150;
        let y = pointTooltip ? scale.y(pointTooltip[options.property]) : 50;
        let y_value = pointTooltip ? pointTooltip._y : 0;
        let x_value = pointTooltip ? moment.utc(pointTooltip?.time).format('HH:mm:ss') : '00:00';

        return (
            <Fragment>
                {pointTooltip ?
                    <g>
                        <line
                            x1={x}
                            x2={x}
                            y1={0}
                            y2={430}
                            stroke="#f9fafb"
                            strokeWidth="2"
                            strokeDasharray={10}
                        />
                        <rect
                            x={x - 60}
                            y={0}
                            width={120}
                            height={45}
                            rx={8}
                            ry={8}
                            fill="#F5FED6"
                        />
                        <circle cx={x} cy={y} r="5" stroke="white" strokeWidth="2" fill="#CEFC34" />
                        <polygon points={`${x - 20},35 ${x + 20},35 ${x},55`} fill="#F5FED6" />
                        <foreignObject x={x - 40} y={15} width="30" height="30">
                            <i className="fa-solid fa-circle-location-arrow txt-12 txt-neutral-800" />
                        </foreignObject>
                        <text x={x - 15} y={20} fontSize={12} color={'#133C55'} fontFamily={'Inter'}>{x_value}</text>
                        <text x={x - 15} y={35} fontSize={12} color={'#1D2939'} fontFamily={'Inter'} fontWeight={'bold'}>{`${formatNumber(y_value)}${options.unit}`}</text>
                    </g> : null}
            </Fragment>
        );
    }

    const capitalizedText = (text) => {
        let label = "";
        var phrase = text.toLowerCase().split(' ');

        for (var i = 0; i < phrase.length; i++) {
            phrase[i] = phrase[i].charAt(0).toUpperCase() + phrase[i].substring(1);
        };

        label = phrase.join(' ');

        return label;
    };
    
    return (
        <Fragment>
            <div className="ps-3 w-100 flex-grow-1">
                {gradients}
                {props.activeServicesPlus.length ?
                    <div className="column-between align-center h-100 rounded-16 p-3 bg-gray-500 border-1 border-color-gray-800 w-100">
                        {!props.loadingData ?
                            <Fragment>
                                <div className="column-start align-items-start">
                                    {!data.length ?
                                        <div className="row-between align-items-center w-100">
                                            <p className="txt-white txt-phrase txt-12 txt-bold">{capitalizedText(moment.utc(domain?.x[1]).format('dddd, DD MMMM YYYY'))}</p>
                                            <div className="row-end align-items-center gap-2">                                                
                                                <button 
                                                    onClick={() => {
                                                        history.push('home/detail'); 
                                                        props.setAccount(props.totalAccounts.find(s => s.idAccount === parseInt(props.activeServicesPlus[0]?.idAccount))); 
                                                        props.setIntervalGraph(0); 
                                                        props.setActiveServicesEnergy(props.activeServicesPlus);
                                                    }} 
                                                    className={`row-center align-items-center border-1 border-color-gray-800 bg-gray-450 gap-2 rounded-pill py-2 ps-3 pe-2 me-3`}>
                                                    <p className="txt-phrase txt-white txt-6 text-nowrap">Ir a consumo hora a hora</p>
                                                    <i className={`fa-light fa-circle-chevron-right txt-10 txt-gray-700`}></i>
                                                </button>
                                                <ButtonCircle
                                                    icon='magnifying-glass-plus'
                                                    sizeIcon={8}
                                                    additionalStyle='opacity-30 pe-none'
                                                    onClick={handleZoomIn}
                                                />
                                                <ButtonCircle
                                                    icon='magnifying-glass-minus'
                                                    sizeIcon={8}
                                                    additionalStyle='mx-3 opacity-30 pe-none'
                                                    onClick={handleZoomOut}
                                                />
                                                <ButtonCircle
                                                    icon='arrow-down-to-bracket'
                                                    sizeIcon={8}
                                                    additionalStyle='opacity-30 pe-none'
                                                    onClick={() => setShowExportGraph(true)}
                                                />
                                            </div>
                                        </div> :
                                        <div className="row-between align-items-center w-100">
                                            <p className="txt-white txt-phrase txt-12 txt-bold">{capitalizedText(moment.utc(domain?.x[1]).format('dddd, DD MMMM YYYY'))}</p>
                                            <div className="row-end align-items-center gap-2">
                                                <button 
                                                    onClick={() => {
                                                        history.push('home/detail'); 
                                                        props.setAccount(props.totalAccounts.find(s => s.idAccount === parseInt(props.activeServicesPlus[0]?.idAccount))); 
                                                        props.setIntervalGraph(0); 
                                                        props.setActiveServicesEnergy(props.activeServicesPlus);
                                                    }} 
                                                    className={`row-center align-items-center border-1 border-color-gray-800 bg-gray-450 gap-2 rounded-pill py-2 ps-3 pe-2 me-3`}>
                                                    <p className="txt-phrase txt-white txt-6 text-nowrap">Ir a consumo hora a hora</p>
                                                    <i className={`fa-light fa-circle-chevron-right txt-10 txt-gray-700`}></i>
                                                </button>
                                                <ButtonCircle
                                                    icon='magnifying-glass-plus'
                                                    sizeIcon={8}
                                                    additionalStyle={`${indicatorZoom === 1 ? "opacity-30 pe-none" : "pe-pointer"}`}
                                                    onClick={handleZoomIn}
                                                />
                                                <ButtonCircle
                                                    icon='magnifying-glass-minus'
                                                    sizeIcon={8}
                                                    additionalStyle={`mx-3 ${indicatorZoom === -1 ? "opacity-30 pe-none" : "pe-pointer"}`}
                                                    onClick={handleZoomOut}
                                                />
                                                <ButtonCircle
                                                    icon='arrow-down-to-bracket'
                                                    sizeIcon={8}
                                                    onClick={() => setShowExportGraph(true)}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="row-between align-items-center w-100 my-3">
                                        <div className="row-start align-items-center">
                                            <GraphPicker setSelectedOption={setOptions} active={options} phases={deviceActive?.phases} />
                                            <div className="row-start align-items-center bg-gray-450 border-1 border-color-gray-800 rounded-30 p-2 mx-2" style={{ height: 32 }} onClick={(e) => e.stopPropagation()}>
                                                <i className="fa-solid fa-circle txt-6 txt-green-500"></i>
                                                <div className="txt-phrase txt-6 txt-white ms-2">{options.title}</div>
                                            </div>
                                        </div>
                                        <div className="row-end align-items-center">
                                            <i className="fa-solid fa-plug-circle-boempty_data_devicelt txt-12 txt-white me-2"></i>
                                            <div className="txt-white txt-phrase txt-12 txt-bold"><span className="txt-green-500 me-2">{`${dataDomain.length ? formatNumber(_.last(dataDomain)[options.property]) : '---'}${options.unit}`}</span></div>
                                        </div>
                                    </div>
                                    {enableTag && !active2 && <Animation.Slide in={enableTag} placement={'top'}>
                                        <div className="bg-green-200 txt-gray-500 txt-phrase txt-8 txt-bold p-2 rounded-8 w-100 text-center text-wrap">{props.language.plus_graph_text_help_tag_step_1}</div>
                                    </Animation.Slide>}
                                    {active2 && enableTag && <Animation.Slide in={active2} placement={'top'}>
                                        <div className="bg-green-200 txt-gray-500 txt-phrase txt-8 txt-bold p-2 rounded-8 w-100 text-center text-wrap">{props.language.plus_graph_text_help_tag_step_2}</div>
                                    </Animation.Slide>}
                                    {!data.length ?
                                        <div className="column-center align-items-center w-100 mt-2" style={{ height: 430 }}>
                                            <img src={empty_data_device} alt="empty_data_device" style={{ width: 270, height: 190 }} />
                                            <p className="txt-phrase txt-semibold txt-8 txt-white mt-4">{props.language.plus_graph_text_empty_data_device}</p>
                                            <p className="txt-phrase txt-regular txt-8 txt-gray-100">{props.language.plus_graph_text_last_connection}{props.lastConection}</p>
                                            <button
                                                className="row-center align-items-center txt-phrase txt-bold txt-8 bg-gray-500 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 mt-4"
                                                onClick={async () => {
                                                    setDay(props.lastDay);
                                                    //setPage(props.lastPage);
                                                    await props.loadHistoryData({ page: props.lastPage, day: props.lastDay, type: 0 }, true);
                                                }}
                                            >
                                                {props.language.plus_graph_button_show_last_records_device}
                                            </button>
                                        </div> :
                                        <Fragment>
                                            <div className={`position-relative row-center align-items-center w-100 ${grabbing ? "pe-grabbing" : "pe-grab"}`} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                                <div style={{ width: props.graphContinerWidth }}>
                                                    <VictoryChart
                                                        animate={showStreaming && props.enableHistory && deviceActive?.id_infrastructure !== 2}
                                                        height={435}
                                                        width={props.graphContinerWidth}
                                                        domain={entireDomain}
                                                        padding={{ left: 20, right: 40, bottom: 0, top: 30 }}
                                                        containerComponent={
                                                            <VictoryZoomContainer
                                                                allowDrag={false}
                                                                allowDraw={false}
                                                                allowResize={false}
                                                                allowZoom={false}
                                                                zoomDimension={"x"}
                                                                zoomDomain={domain}
                                                                onZoomDomainChange={(domainZoom) => {
                                                                    const minValueY = _.minBy(data, d => d[options.property])[options.property];
                                                                    const maxValueY = _.maxBy(data, d => d[options.property])[options.property];
                                                                    const intervalY = [minValueY < 0 ? minValueY * paddingDomain : 0, maxValueY < 0 ? 0 : maxValueY * paddingDomain];
                                                                    setDomain({ x: domainZoom?.x, y: intervalY });
                                                                    if (domain?.x[1] === entireDomain?.x[1]) setShowStreaming(true);
                                                                    else setShowStreaming(false);
                                                                    setEnableLoaderGraph(true);
                                                                }}
                                                                activateData={false}
                                                                onActivated={enableTag ? null : (point => setPointTooltip(point[0]))}
                                                            />}>
                                                        <VictoryArea
                                                            x={"time"}
                                                            y={[options.property]}
                                                            data={data}
                                                            style={areaStyle}
                                                        />
                                                        <VictoryAxis dependentAxis tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={'middle'} verticalAnchor={'middle'} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>} style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }} />
                                                        {enableTag ? <DotSliderLine x={pointTagDrag?.x} positionX={pointTagDrag?.positionX} /> : showTooltip ? <Tooltip /> : null}
                                                        {enableTag && active2 ? <DotSliderArea x={pointTagDrag?.x} positionX={pointTagDrag?.positionX} /> : null}
                                                    </VictoryChart>
                                                </div>
                                                {(props.enableHistory && domain?.x[0] === entireDomain?.x[0] && enableLoaderGraph) ? <LoadingHistoryData /> : null}
                                                <div className="position-absolute bg-green-500" style={{ height: enableTag ? 460 : 430, top: 0, right: 36, width: 2 }}></div>
                                            </div>
                                            {enableTag ?
                                                <div className="w-100" style={{ height: 40, paddingLeft: 20, paddingRight: 40 }}>
                                                    <Draggable
                                                        axis="x"
                                                        onStart={() => setGrabbingTag(true)}
                                                        onStop={() => setGrabbingTag(false)}
                                                        onDrag={(e, point) => {
                                                            const widthDomain = props.graphContinerWidth - 108;
                                                            const timeStampEstimated = parseInt(((point?.x * (domain?.x[1] - domain?.x[0]) / (widthDomain * 1000)) + (domain?.x[0] / 1000)).toFixed(0) * 1000);
                                                            const itemSelected = data.filter(item => item?.time === timeStampEstimated);
                                                            const valueY = itemSelected.length ? itemSelected[0][options.property] : pointTagDrag.y;
                                                            setPointTagDrag({ x: timeStampEstimated, y: valueY, positionX: point?.x });
                                                        }}
                                                        bounds={{ left: 0, right: props.graphContinerWidth - 108 }}>
                                                        <div className={`row-between px-2 align-items-center txt-6 rounded-8 bg-neutral-50 txt-neutral-800 h-100 ${grabbingTag ? "pe-grabbing" : "pe-grab"}`} style={{ width: 75 }}>
                                                            <i className="fa-solid fa-grip-dots-vertical txt-12 txt-neutral-800" />
                                                            <div className="column-center">
                                                                <div className="row-start">
                                                                    {moment.utc(pointTagDrag?.x).format('HH:mm:ss')}
                                                                </div>
                                                                <div className="row-start">
                                                                    {formatNumber(pointTagDrag?.y)}{options.unit}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Draggable>
                                                </div> : null}
                                        </Fragment>
                                    }
                                    <div className={`row-between w-100 align-items-center mt-3 ${showStreaming ? "mb-3" : "mb-1"}`}>
                                        {domain?.x && <p className="txt-white txt-phrase txt-6">{moment.utc(domain?.x[0]).format('HH:mm:ss')}</p>}
                                        {domain?.x && <p className="txt-white txt-phrase txt-6">{moment.utc(domain?.x[1] - ((domain?.x[1] - domain?.x[0]) / 2)).format('HH:mm:ss')}</p>}
                                        {domain?.x && <p className="txt-neutral-800 zindex-2 txt-phrase txt-bold bg-green-500 rounded-30 py-1 px-3 txt-6">{moment.utc(domain?.x[1]).format('HH:mm:ss')}</p>}
                                    </div>
                                    {!showStreaming ?
                                        <div className="row-end w-100 align-items-center">
                                            <div className="row-between align-items-center txt-white txt-phrase txt-medium p-3 txt-6 pe-pointer" onClick={() => setShowStreaming(true)}>
                                                <div className="me-1">{props.language.graphs_go_back_button}</div>
                                                <i className="fa-solid fa-arrow-right-to-line txt-2" />
                                            </div>
                                        </div> : null}
                                    {!enableTag ?
                                        <div className="row-center align-items-center w-100 mb-3">
                                            {!data.length ? null :
                                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-500 txt-neutral-800 py-2 rounded-8 px-4" onClick={() => { setEnableTag(true); setShowStreaming(false); }}>
                                                    {props.language.plus_graph_button_tag}<i className="fa-regular fa-tag txt-10 ms-3" />
                                                </button>
                                            }
                                            <Link to="/plus/tag">
                                                <button className={`row-center align-items-center txt-phrase txt-bold txt-8 bg-gray-500 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 ${!data.length ? "ms-0" : "ms-4"}`} >
                                                    {props.language.plus_graph_button_tag_manager}<i className="fa-regular fa-tags txt-10 ms-3" />
                                                </button>
                                            </Link>
                                        </div> :
                                        active1 ?
                                            <div className="row-center align-items-center w-100 my-3">
                                                <button className="row-center align-items-center txt-phrase bg-gray-500 txt-bold txt-8 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 me-3" onClick={() => { setEnableTag(false); setPointTagDrag(pointTagDragInit); setPointOne(null); setPointTwo(null) }}>
                                                    <i className="fa-regular fa-xmark txt-10" />
                                                </button>
                                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-500 txt-neutral-800 py-2 rounded-8 px-4 me-3" onClick={() => { setEnableTag(false); setShowCreateTag(true); setPointOne(pointTagDrag?.x); }}>
                                                    <i className="fa-regular fa-circle-dot txt-10 me-3" />{props.language.plus_graph_button_select_point}
                                                </button>
                                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-500 txt-neutral-800 py-2 rounded-8 px-4" onClick={() => { setActive1(false); setActive2(true); setPointOne(pointTagDrag?.x); }}>
                                                    <i className="fa-regular fa-square-dashed txt-10 me-3" />{props.language.plus_graph_button_select_range}
                                                </button>
                                            </div> :
                                            <div className="row-center align-items-center w-100 my-3">
                                                <button className="row-center align-items-center txt-phrase bg-gray-500 txt-bold txt-8 border-2 border-color-green-500 txt-green-500 py-2 rounded-8 px-4 me-3" onClick={() => { setEnableTag(false); setPointTagDrag(pointTagDragInit); setPointOne(null); setPointTwo(null) }}>
                                                    <i className="fa-regular fa-xmark txt-10" />
                                                </button>
                                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-500 txt-neutral-800 py-2 rounded-8 px-4 me-3" onClick={() => { setPointTwo(pointTagDrag?.x); setActive1(true); setActive2(false); setEnableTag(false); setShowCreateTag(true); }}>
                                                    <i className="fa-regular fa-circle-dot txt-10 me-3" />{props.language.plus_graph_button_select_points}
                                                </button>
                                                <button className="row-center align-items-center txt-phrase txt-bold txt-8 bg-green-200 txt-neutral-800 py-2 rounded-8 px-4" onClick={() => { setActive1(true); setActive2(false); }}>
                                                    {props.language.plus_graph_button_go_back}
                                                </button>
                                            </div>
                                    }
                                </div>
                                <CreateTag show={showCreateTag} setShow={setShowCreateTag} pointOne={pointOne} pointTwo={pointTwo} />
                            </Fragment> :
                            <div className="position-relative w-100" style={{ minHeight: 620 }}>{graphLoadingContainer(props.loadingData, props.language)}</div>
                        }
                    </div> :
                    <div className="column-between align-center h-100 rounded-16 p-3 bg-gray-500 w-100">
                        <div className="column-center align-items-center w-100 mt-2" style={{ height: 400 }}>
                            <img src={empty_data_device} alt="empty_data_device" style={{ width: 270, height: 190 }} />
                            <p className="txt-phrase txt-semibold txt-8 txt-white mt-4">{props.language.plus_graph_text_empty_data_device}</p>
                        </div>
                    </div>
                }
            </div>
            <ExportGraph show={showExportGraph} setShow={setShowExportGraph} setPointTagDrag={setPointTagDrag} pointTagDragInit={pointTagDragInit} />
        </Fragment>
    );
}
const mapStateToProps = (state, ownProps) => ({
    dataGraph: state.plus.dataGraph,
    loadingData: state.plus.loadingInitData,
    enableHistory: state.plus.enableHistory,
    dataGraphPage: state.plus.dataGraphPage,
    graphContinerWidth: ownProps.graphContinerWidth,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
    language: state.shared.language,
    lastConection: state.plus.lastConection,
    lastDay: state.plus.lastDay,
    lastPage: state.plus.lastPage,
    totalAccounts: state.accounts.totalAccounts
})

const mapDispatchToProps = dispatch => ({
    loadInitData: page => dispatch(plus.loadInitData(page)),
    loadHistoryData: (page, loading) => dispatch(plus.loadHistoryData(page, loading)),
    setEnableHistory: payload => dispatch(plus.setEnableHistory(payload)),
    loadDevices: () => dispatch(plus.loadDevices()),
    resetData: () => dispatch(plus.resetData()),
    setIntervalGraph: (payload) => dispatch(energyActions.setIntervalGraph(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
    connectToIoTCore: () => dispatch(plus.connectToIoTCore()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Graph)
