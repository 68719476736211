import { createStore, combineReducers, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import * as sharedCreators from './creators/Shared';

import { loginReducer } from './reducers/Login';
import { usersReducer } from './reducers/Users';
import { energyReducer } from './reducers/Energy';
import { invoicesReducer } from './reducers/Invoices';
import { paysReducer } from './reducers/Pays';
import { servicesReducer } from './reducers/Services';
import { accountsReducer } from './reducers/Accounts';
import { sharedReducer } from './reducers/Shared';
import { plusReducer } from './reducers/Plus';
import { geniusReducer } from './reducers/Genius';
import { notificationsReducer } from './reducers/Notifications';
import { alertsReducer } from './reducers/Alerts';
import { chatbotReducer } from './reducers/Chatbot';

const allReducers = combineReducers({
    login: loginReducer,
    users: usersReducer,
    energy: energyReducer,
    services: servicesReducer,
    invoices: invoicesReducer,
    pays: paysReducer,
    accounts: accountsReducer,
    shared: sharedReducer,
    plus: plusReducer,
    genius: geniusReducer,
    notifications: notificationsReducer,
    alerts: alertsReducer,
    chatbot: chatbotReducer
});

const root = (state, action) => {
    if (action.type === sharedCreators.LOGOUT) {
        const { shared } = state;
        state = { shared };
    }

    return allReducers(state, action);
}

const Store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));

export default Store;
