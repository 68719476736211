export const SET_DATA_GRAPH = 'SET_DATA_GRAPH';
export const SET_LOADING_INIT_DATA = 'SET_LOADING_INIT_DATA';
export const SET_ENABLE_HISTORY = 'SET_ENABLE_HISTORY';
export const SET_DATA_GRAPH_STREAMING = 'SET_DATA_GRAPH_STREAMING';
export const SET_DEVICES = 'SET_DEVICES';
export const RESET_DATA = 'RESET_DATA';
export const SET_ENERGY_QUALITY = 'SET_ENERGY_QUALITY';
export const SET_LOADING_ENERGY_QUALITY = 'SET_LOADING_ENERGY_QUALITY';
export const SET_ALWAYS_ON = 'SET_ALWAYS_ON';
export const SET_LOADING_ALWAYS_ON = 'SET_LOADING_ALWAYS_ON';
export const SET_PLUS_INDICATORS = 'SET_PLUS_INDICATORS';
export const SET_PLUS_LOADING_INDICATORS = 'SET_PLUS_LOADING_INDICATORS';
export const SET_COMPARATIVES = 'SET_COMPARATIVES';
export const SET_LOADING_COMPARATIVES = 'SET_LOADING_COMPARATIVES';
export const SET_APPLIANCES = 'SET_APPLIANCES';
export const SET_TAG_RECORDS = 'SET_TAG_RECORDS';
export const SET_LAST_CONECTION = 'SET_LAST_CONECTION';
export const SET_LAST_DAY = 'SET_LAST_DAY';
export const SET_LAST_PAGE = 'SET_LAST_PAGE';
export const SET_DATA_GRAPH_HISTORY_INIT = 'SET_DATA_GRAPH_HISTORY_INIT';
export const SET_OPEN_QUALITY_ENERGY = 'SET_OPEN_QUALITY_ENERGY';
export const SET_DATA_GRAPH_ALWAYS_ON = 'SET_DATA_GRAPH_ALWAYS_ON';
export const SET_INTERVAL_GRAPH_ALWAYS_ON = 'SET_INTERVAL_GRAPH_ALWAYS_ON';
export const SET_GRAPH_DATES_ALWAYS_ON = 'SET_GRAPH_DATES_ALWAYS_ON';
export const SET_GRAPH_DATES_SCROLL_ALWAYS_ON = 'SET_GRAPH_DATES_SCROLL_ALWAYS_ON';
export const SET_ENABLE_SCROLL_DATA_ALWAYS_ON = 'SET_ENABLE_SCROLL_DATA_ALWAYS_ON';
export const SET_LOADING_ALWAYS_ON_MODULE = 'SET_LOADING_ALWAYS_ON_MODULE';
export const SET_QUALITY_INDICATORS = 'SET_QUALITY_INDICATORS';
export const SET_LOADING_QUALITY_INDICATORS = 'SET_LOADING_QUALITY_INDICATORS';
export const SET_ALWAYS_ON_INDICATORS = 'SET_ALWAYS_ON_INDICATORS';
export const SET_LOADING_ALWAYS_ON_INDICATORS = 'SET_LOADING_ALWAYS_ON_INDICATORS';

