import React from 'react';

const CheckPicker = ({options, labelName, selectedData, keyName, onChange}) => {
    return <div className='column-start rounded-8 py-2 px-3 bg-gray-800'>
        {options?.length && options.map((item, index) => {            
            let isSelected = selectedData.includes(item[keyName]);           
            return <div key={index} className={`row-between align-items-center pe-pointer py-3 ps-2 ${index > 0 && 'border-top-1 border-color-gray-600'}`}
                onClick={() => { 
                    if(isSelected){
                        onChange(selectedData.filter(value => value !== item[keyName]));                                
                    }else{                          
                        onChange([...selectedData, item[keyName]]);                                
                    }
                }}
            >
                <div className="row-between align-items-center w-100">
                    {'plus' in item || 'id_device' in item ? 
                    <div className={`row-between align-items-center w-100 me-2 txt-phrase txt-6 txt-white pe-pointer`}>
                        {item[labelName]}
                        <div className='ms-5 px-2 h-50 rounded-16 border-1 border-color-green-500' style={{backgroundColor: 'rgba(158,204,20,0.3)'}}>
                            <p className='txt-green-500'>Plus</p>
                        </div>
                    </div> :
                    <div className={`txt-phrase txt-6 txt-white pe-pointer`}>{item[labelName]}</div>}   
                    {isSelected && <i className="fa-solid fa-check-circle txt-green-500 txt-10"></i>}
                </div>
            </div>
        })}            
    </div>
    
};

export default CheckPicker;