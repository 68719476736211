import React from "react"
import step1 from "../../../imgs/onboarding/step1-payment.png"
import step1En from "../../../imgs/onboarding/english/step1-payment-en.png"
import step2 from "../../../imgs/onboarding/step2-payment.png"
import step2En from "../../../imgs/onboarding/english/step2-payment-en.png"
import step3 from "../../../imgs/onboarding/step3-payment.png"
import step3En from "../../../imgs/onboarding/english/step3-payment-en.png"
import step4 from "../../../imgs/onboarding/step4-payment.png"
import step4En from "../../../imgs/onboarding/english/step4-payment-en.png"

export const stepsPayment = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step 1 service picker" />
        </div>
      ),
      title: language.onboarding_payment_title_step1,
      description: language.onboarding_payment_description_step1,
      placement: "center",      
      target: "#payment",
      disableBeacon: true,
      steps: 4,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="step 2 service picker" />
        </div>
      ),
      title: language.onboarding_payment_title_step2,
      description: language.onboarding_payment_description_step2,
      placement: "left",
      target: "#payment",
      disableBeacon: true,
      steps: 4,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step3 : step3En} className="w-100 h-100" alt="step 3 service picker" />
        </div>
      ),
      title: language.onboarding_payment_title_step3,
      description: language.onboarding_payment_description_step3,
      placement: "left",
      target: "#payment",
      disableBeacon: true,
      steps: 4,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step4 : step4En} className="w-100 h-100" alt="step 4 service picker" />
        </div>
      ),
      title: language.onboarding_payment_title_step4,
      description: language.onboarding_payment_description_step4,
      placement: "left",
      target: "#payment",
      disableBeacon: true,
      steps: 4,
    },
  ])
}
