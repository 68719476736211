import React, { Fragment, useEffect } from 'react';

import { connect } from 'react-redux';

import * as plusActions from "../../../../store/actions/Plus";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fal } from '@fortawesome/pro-light-svg-icons'

import ContentLoader from "react-content-loader";

import Tooltip from '../../../shared/utilities/Tooltip';

const loadingContainer = <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#161617"
        backgroundColor="#323234"
        style={{ width: "100%", height: "100%" }}>
        <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
    </ContentLoader>
</div>;

const Location = ({ name, consumption, image, state, style }) => {

    // const iconState = () => {
    //     if (state === 0) return <i className="fa-regular fa-chevrons-down txt-10 txt-blue-500" />
    //     if (state === 1) return <i className="fa-regular fa-equals txt-10 txt-orange-500" />
    //     return <i className="fa-regular fa-chevrons-up txt-10 txt-green-500" />
    // }

    return (
        <div className="row-between align-items-center w-100 my-3">
            <div className="column-start align-items-start">
                <div className="row-start align-items-center">
                    {image && <img src={image} alt='project' />}
                    <FontAwesomeIcon icon={fal[style ? style.icon : "faBuilding"]} className="txt-10 txt-white" />
                    <div className="txt-phrase txt-6 txt-white px-2">{name}</div>
                </div>
                <div className={`txt-phrase txt-8 txt-bold pt-1 ${state === 0 ? 'txt-blue-500' : state === 1 ? 'txt-orange-500' : 'txt-green-500'}`}>{consumption}</div>
            </div>
        </div>
    )
};

const RenderCardEmpty = ({ language }) => {
    return (
        <div className="row-between align-items-center w-100 my-3 bg-gray-800 rounded-8 p-2">
            <div className="column-start align-items-start">
                <div className="row-start align-items-center">
                    <i className="fa-solid fa-city txt-8 txt-white"></i>
                    <div className="txt-phrase txt-6 txt-white px-2">---</div>
                </div>
                <div className='txt-phrase txt-8 txt-bold pt-1 txt-gray-600'>--- KWh</div>
            </div>
            <div className="row-center align-items-center bg-gray-600 rounded-8 p-2" style={{ height: 40, width: 40 }}>
                <i className="fa-solid fa-angles-down txt-12 txt-gray-800"></i>
            </div>
        </div>
    )
};

const Rank = (props) => {

    useEffect(() => {
        if (props.activeServicesPlus.length) props.loadComparatives()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeServicesPlus]);

    return (
        <Fragment>
            {!props.loadingComparatives ? !props.comparatives.length ?
                <div className="column-start align-items-center flex-grow-1 bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100 pe-pointer">
                    <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.plus_rank_title}</p>
                    <RenderCardEmpty language={props.language} />
                </div> :
                <div className="column-start align-items-center flex-grow-1 bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100 pe-pointer">
                    <div className="row-start align-items-center w-100">
                        <Tooltip text={props.language.plus_rank_tooltip_info} />
                        <p className="txt-phrase txt-8 txt-white txt-regular ms-3">{props.language.plus_rank_title}</p>
                    </div>
                    <div className="column-between w-100">
                        {props.comparatives.slice(0, 3).map((value, i) => (
                            <Location key={i} consumption={value?.value} name={value?.name} state={value?.state} style={value?.style} />
                        ))}
                    </div>
                </div> :
                <div className="position-relative w-100" style={{ height: 236 }}>{loadingContainer}</div>}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    comparatives: state.plus.comparatives,
    loadingComparatives: state.plus.loadingComparatives,
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    loadComparatives: () => dispatch(plusActions.loadComparatives()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rank);
