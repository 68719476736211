import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';

import * as chatbotActions from "../../store/actions/Chatbot";
import * as sharedActions from "../../store/actions/Shared";

import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";

const LumiBanner = (props) => {

    const dragItem = useRef();
    const dragged = useRef(false);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const elementWidth = 100;
    const elementHeight = 100;

    const lumiStyleActive = { 
        transform: 'scale(1)'
    };

    const lumiStyleInactive = {
        transform: 'scale(0)'
    };

    let showChatBot = props.showChatBot;
    let showBannerChatbot = props.showBannerChatbot;

    const setShowChatBot = props.setShowChatBot;
    const setShowBannerChatbot = props.setShowBannerChatbot;
    //const setScreenPaddingBottom = props.setScreenPaddingBottom;

    const initialLeftPosition =  windowWidth - elementWidth - 12;
    const initialTopPosition = windowHeight - elementHeight - 12;

    const [style, setStyle] = useState(lumiStyleActive);
    //const [close, setClose] = useState(false);
    const [topPosition, setTopPosition] = useState(initialTopPosition);
    const [leftPosition, setLeftPosition] = useState(initialLeftPosition);
    const [isReturning, setIsReturning] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const { RiveComponent } = useRive({
        src: "lumiAssets/lumi.riv",
        stateMachines: "State Machine 1",
        layout: new Layout({
            fit: Fit.FitWidth,
            alignment: Alignment.Center,
        }),
        autoplay: true
    });

    const handleAnimation = () => {
        setShowChatBot(true);
        setShowBannerChatbot(true);
    };

    //const handleClose = (state) => setClose(state)

    useEffect(() => {

        if (showChatBot)
            setShowBannerChatbot(true);
        
        const stateStyle = showBannerChatbot
          ? lumiStyleActive
          : lumiStyleInactive;

        //setScreenPaddingBottom(showBannerChatbot ? 100 : 0);
      
        setStyle(stateStyle);

        // eslint-disable-next-line
      }, [showChatBot, showBannerChatbot]);

    const handleMouseDown = (e) => {
        dragItem.current = {
            startX: e.clientX - leftPosition,
            startY: e.clientY - topPosition,
        };
        dragged.current = false;
        setIsReturning(false);
        setIsDragging(true);
        window.addEventListener('pointermove', handleMouseMove);
        window.addEventListener('pointerup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const newLeft = Math.max(115, Math.min(e.clientX - elementWidth/2, initialLeftPosition));
        const newTop = e.clientY - elementHeight/2;
        if (Math.abs(newLeft - leftPosition) > 5 || 
            Math.abs(newTop - topPosition) > 5) {
            dragged.current = true;
        }
        setLeftPosition(newLeft);
        setTopPosition(newTop);
    };

    const handleMouseUp = () => {
        setIsReturning(true);
        setIsDragging(false);
        window.removeEventListener('pointermove', handleMouseMove);
        window.removeEventListener('pointerup', handleMouseUp);

        setTopPosition(initialTopPosition);

        if (!dragged.current) {
            handleAnimation()
        }
    };

    return (
        <Fragment>
            <div
                ref={dragItem}
                //onMouseEnter={() => handleClose(true)}
                //onMouseLeave={() => handleClose(false)}
                onPointerDown={handleMouseDown}
                onPointerUp={handleMouseUp}
                className={`position-fixed zindex-4 ${isDragging ? "pe-grabbing" : "pe-grab"}`}
                style={{
                    ...style,
                    top: topPosition,
                    left: leftPosition,
                    width: elementWidth, 
                    height: elementHeight,
                    transition: isReturning ? 'all 0.5s ease-in-out' : 'none'
                }}>
                <RiveComponent />
            </div>
            {/* <div className='position-fixed bottom-0 right-0 zindex-4' onMouseEnter={() => handleClose(true)} onMouseLeave={() => handleClose(false)}>
                <div className='row-end align-items-center' style={{ height: showBannerChatbot ? 78 : 0, transition: ' height 0.3s ease-in-out', }}/>
                <div className={`${(!showBannerChatbot || !close) && 'd-none'} position-absolute top-0 right-0 row-center align-items-center rounded-pill pe-pointer`} onClick={() => { setShowBannerChatbot(false); track("Ai Lumi closed"); }} style={{ transform: `translate(-30px, -24px)`, width: 24, height: 24 }}>
                    <i className='fal fa-circle-xmark txt-10 txt-red-500 txt-regular txt-8 pe-pointer' />
                </div>
            </div> */}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showChatBot: state.chatbot.showChatBot,
    showBannerChatbot: state.chatbot.showBannerChatbot
});

const mapDispatchToProps = (dispatch) => ({
    setShowChatBot: (payload) => dispatch(chatbotActions.setShowChatBot(payload)),
    setShowBannerChatbot: (payload) => dispatch(chatbotActions.setShowBannerChatbot(payload)),
    setScreenPaddingBottom: (payload) => dispatch(sharedActions.setScreenPaddingBottom(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(LumiBanner);