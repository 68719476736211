import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as plusActions from "../../../../store/actions/Plus";

import { Modal, Animation, Alert } from 'rsuite';

import CreateDevice from './CreateDevice';

import empty_modules_plus from "../../../../imgs/box.svg";

import { track } from "@amplitude/analytics-browser";

const DataAppliances = ({value, property, unit}) => {
    return(
            <div className="row-between w-100">
                <div className="txt-phrase txt-regular txt-gray-400 txt-6">{property}</div>
                <div className="row-end align-items-center">
                    <div className="txt-phrase txt-semibold txt-green-50 txt-6">{value}</div>
                    <div className={`txt-phrase txt-semibold txt-green-50 txt-6 ${ unit ? 'ms-2' : 'ms-0'}`}>{unit}</div>
                </div>
            </div> 
    )
}

const Devices = (props) => {
    const [showInfoDevice, setShowInfoDevice] = useState(false); 
    const [appliancesSelected, setAppliancesSelected] = useState(props.appliancesSelected);   

    useEffect(() => {
        props.onChange(appliancesSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliancesSelected]);    

    return(
        <Fragment>
            <div className={`row-between align-items-center w-100 pe-pointer mt-3 p-2 rounded-${showInfoDevice ? 'top-8' : '8'} ${showInfoDevice || props.appliancesSelected.includes(props.item.id_appliance) ? 'bg-gray-800' : 'bg-gray-500'}`} onClick={() => setShowInfoDevice(!showInfoDevice)}>
                <div className="row-start align-items-center">
                    <div className="column-center rounded-8 bg-gray-500 p-2" style={{ width: 40, height: 40 }}>
                        <i className="fa-regular fa-plug-circle-bolt txt-green-50 txt-14"></i>
                    </div>
                    <div className="col-8 text-wrap txt-phrase txt-semibold txt-white txt-8 ms-3 pe-pointer">{props.item.custom_name}</div>
                </div>
                <i className={`fa-regular fa-chevron-down txt-white txt-10 pe-pointer ${!showInfoDevice ? 'device-detail-open' : 'device-detail-close'}`}></i>
            </div>
            <Animation.Collapse in={showInfoDevice} dimension={'height'}>
                <div className="column-start bg-gray-800 w-100 rounded-bottom-8 p-2">
                    {props.item.id_tag ? 
                        <Fragment>
                            <DataAppliances value={props.item.active_cons.toFixed(4)} property={props.language.tag_new_tag_text_average_comsuption} unit={"Wh"}/> 
                            <DataAppliances value={props.item.duration} property={props.language.tag_new_tag_text_duration} unit={"s"}/> 
                        </Fragment>
                        : 
                        <div className="row-between w-100">
                            <div className="txt-phrase txt-regular txt-gray-400 txt-6">{props.language.tag_new_tag_text_no_tag}</div>
                        </div>
                    }
                    <div className='row-center'>
                        <button 
                            onClick={() => { 
                                if (!props.appliancesSelected.includes(props.item.id_appliance)) { 
                                    setAppliancesSelected([...props.appliancesSelected, props.item.id_appliance]);
                                    Alert.success(props.language.alert_create_new_tag_associated_tags)
                                    setShowInfoDevice(false);
                                }
                            }} 
                            className='bg-green-500 mt-3 rounded-8 py-2 px-5 txt-neutral-800 txt-phrase txt-8 txt-bold'>{props.language.tag_new_tag_button_link_tag}</button>
                    </div> 
                </div>
            </Animation.Collapse>
        </Fragment>
    );
}


const RenderCardEmpty = ({language}) => {
    return(
        <div className={`column-between w-100 align-items-center bg-gray-500 rounded-16 p-3`} style={{ height: 220 }}>
            <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 144, height: 144 }} />
            <div className={`row-center mx-5 mt-2 align-items-center txt-phrase txt-bold txt-8`}>
              {language.tag_new_tag_text_no_devices}          
            </div>
            <div className={`row-center text-center mt-1 text-wrap txt-phrase txt-regular txt-6`}>
              {language.tag_new_tag_text_no_devices_description}          
            </div>
        </div>
    )
  };

const CreateTag = (props) => {

    const [showCreateDevice, setShowCreateDevice] = useState(false);
    const [devices, setDevices] = useState([]);
    const [search, setSearch] = useState('');
    const [appliancesSelected, setAppliancesSelected] = useState([]);

    useEffect(() => {
        setDevices([...props.appliances.filter(d => d?.custom_name?.toLowerCase().includes(search?.toLowerCase()))]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => { 
        if (props.activeServicesPlus.length) props.loadAppliances()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activeServicesPlus]);

    useEffect(() => { 
        setDevices(props.appliances) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appliances]);

    return(
        <Fragment>
            <Modal
                size="xs" 
                overflow={false}
                backdropClassName={'bg-black opacity-80 pe-pointer'}    
                dialogClassName="rounded"                  
                show={props.show}                                                   
                onHide={() => { setAppliancesSelected([]); props.setShow(false);}}
            >
                    <div className='column-start w-100 h-100'>
                        <div className='row-between align-items-center w-100 mt-3 px-3'>
                            <div className='txt-green-500 txt-phrase txt-8 txt-medium pe-pointer' onClick={() => { setAppliancesSelected([]); props.setShow(false);}}>{props.language.tag_new_tag_header_label_cancel}</div>
                            <div className='txt-white txt-phrase txt-8 txt-semibold'>{props.language.tag_new_tag_header_label_tag}</div>
                            <div className='txt-green-500 txt-phrase txt-8 txt-medium pe-pointer'
                                onClick={() => {
                                    props.addTags({id_appliance : appliancesSelected, initial_timestamp : props.pointOne / 1000, final_timestamp : props.pointTwo ? props.pointTwo / 1000 : null});
                                    track('Plus graphic tag created')
                                    setAppliancesSelected([]);
                                    props.setShow(false);
                                    }}>
                                {props.language.tag_new_tag_header_label_save}
                            </div>
                        </div> 
                        <div className='column-start p-3 mt-3'>
                            <div className="row-start align-items-center border-1 border-color-white p-2 rounded-8 bg-black">
                                <input type="text" className="txt-phrase txt-8 txt-white bg-black border-0 w-100" placeholder={props.language.tag_new_tag_input_label_search} value={search} onChange={({ target }) => setSearch(target.value)} />
                                <i className="fa-light fa-search txt-8 txt-white" />
                            </div> 
                            <div className='mt-3 txt-white txt-phrase txt-8 txt-medium'>{props.language.tag_new_tag_title_devices_list}</div>
                            {devices.length ? devices.map((item, index) => 
                                <Devices key={index} item={item} language={props.language} onChange={value => setAppliancesSelected(value)} appliancesSelected={appliancesSelected}/>
                            ) : <RenderCardEmpty language={props.language} />} 
                            <div className='row-end'>
                                <button className='bg-green-500 mt-3 rounded-8 py-2 px-5 txt-neutral-800 txt-phrase txt-8 txt-bold' onClick={() => setShowCreateDevice(true)}>{props.language.tag_new_tag_button_new_device}</button>
                            </div>                    
                        </div>                  
                    </div>
                        
            </Modal>
            <CreateDevice show={showCreateDevice} setShow={setShowCreateDevice} /> 
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    appliances: state.plus.appliances,
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    loadAppliances: () => dispatch(plusActions.loadAppliances()),
    addTags: (payload) => dispatch(plusActions.addTags(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTag);
