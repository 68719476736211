import * as creators from '../creators/Chatbot';

const initialState = {
    activeChat: false,
    loadingChat: false,
    currentConversationId: null,
    messages: [],
    showChatBot: false,
    activeChatMessages: true,
    conversations: [],
    showBannerChatbot: true
}

export const chatbotReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_ACTIVE_CHAT:
            return { ...state, activeChat: payload };
        case creators.SET_LOADING_CHAT:
            return { ...state, loadingChat: payload };
        case creators.SET_CURRENT_CONVERSATION:
            return { ...state, currentConversationId: payload };
        case creators.SET_MESSAGES:
            return { ...state, messages: payload };
        case creators.SET_SHOW_CHATBOT:
            return { ...state, showChatBot: payload };
        case creators.SET_ACTIVATE_CHAT_MESSAGES:
            return { ...state, activeChatMessages: payload };
        case creators.SET_CONVERSATIONS:
            return { ...state, conversations: payload };
        case creators.SET_SHOW_BANNER_CHATBOT:
            return { ...state, showBannerChatbot: payload };
        default:
            return state;
    }
}