import React from "react"

import step1 from "../../../imgs/onboarding/step1-new-user.png"
import step1En from "../../../imgs/onboarding/english/step1-new-user-en.png"
import step2 from "../../../imgs/onboarding/step2-new-user.png"
import step2En from "../../../imgs/onboarding/english/step2-new-user-en.png"
import step3 from "../../../imgs/onboarding/step3-new-user.png"
import step3En from "../../../imgs/onboarding/english/step3-new-user-en.png"

export const stepsUser = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step 1 new user" />
        </div>
      ),
      title: language.onboarding_user_title_step1,
      description: language.onboarding_user_description_step1,
      placement: "right",
      width: 340,
      height: 350,
      target: "#user_modal",
      disableBeacon: true,
      steps: 3
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="step 2 new user" />
        </div>
      ),
      title: language.onboarding_user_title_step2,
      description: language.onboarding_user_description_step2,
      placement: "right",
      width: 340,
      height: 350,
      target: "#user_form",
      disableBeacon: true,
      steps: 3,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          <img src={language.language_locale === 'es'? step3 : step3En} className="w-100 h-100" alt="step 3 new user" />
        </div>
      ),
      title: language.onboarding_user_title_step3,
      description: language.onboarding_user_description_step3,
      placement: "right-end",
      width: 340,
      target: "#user_edit",
      disableBeacon: true,
      steps: 3,
    },
  ])
}
