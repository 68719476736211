import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";

import * as servicesActions from "../../store/actions/Services";
import * as chatbotActions from "../../store/actions/Chatbot";
import * as accountsActions from "../../store/actions/Accounts";

import { Drawer, Loader } from "rsuite";

import ServicesPopover from "../energy/services/ServicesPopover";

import _ from "lodash";

import { useHistory, useLocation } from "react-router-dom";

import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";

// import { tutorialListInfoGlobal } from '../onboarding/OnboardingSections';

import BackButton from "./utilities/BackButton";

import lumi from "../../imgs/LUMI.png";

import { track } from "@amplitude/analytics-browser";

import ChatPopover from "./ChatPopover";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/pro-light-svg-icons";
import { faThumbsUp as faThumbsUpSolid, faThumbsDown as faThumbsDownSolid } from "@fortawesome/pro-solid-svg-icons";

const ChatBot = (props) => {
  const { RiveComponent } = useRive({
    src: "lumiAssets/lumi.riv",
    stateMachines: "State Machine 1",
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  const { RiveComponent: RiveComponentBeta } = useRive({
    src: "lumiAssets/lumi.riv",
    stateMachines: "State Machine 1",
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center,
    }),
    autoplay: true,
  });

  let user = props.user;
  let activeChat = props.activeChat;
  let loadingChat = props.loadingChat;
  let messages = props.messages;
  let totalServices = props.totalServices;
  let activeServicesEnergy = props.activeServicesEnergy;
  let showBlackScreenDrawer = props.showBlackScreenDrawer;
  let currentConversationId = props.currentConversationId;
  let totalAccounts = props.totalAccounts;
  let currentAccount = props.account;

  const history = useHistory();
  const location = useLocation();

  const language = props.language;
  const enableChatBot = props.enableChatBot;
  const setActiveChatAsync = props.setActiveChatAsync;
  const setConversation = props.setConversation;
  const setActiveServicesEnergy = props.setActiveServicesEnergy;
  const setActiveServicesPlus = props.setActiveServicesPlus;
  const setAccount = props.setAccount;
  const setScore = props.setScore;
  const loadServicesAsync = props.loadServicesAsync;

  let showChatBot = props.showChatBot;
  let setShowChatBot = props.setShowChatBot;
  let activeChatMessages = props.activeChatMessages;
  let setActiveChatMessages = props.setActiveChatMessages;
  let loadConversations = props.loadConversations;
  let showBannerChatbot = props.showBannerChatbot;

  // const history = useHistory();

  const aids = [language.chatbot_aids1, language.chatbot_aids2, language.chatbot_aids3];

  const messagesContainerRef = useRef(null);

  const [request, setRequest] = useState(null);
  const [mount, setMount] = useState(false);

  const replaceMessageLink = (message) => {

    const url = "https://api.whatsapp.com/send?phone=5716659652";

    const linkText = "¡Contactar ahora!";

    if (message.includes(url)) {

      const parts = message.split(url);

      return (
        <Fragment>
          {parts[0]}
          <a href={url} target="_blank" rel="noopener noreferrer" className="txt-blue-200">
            {linkText}
          </a>
          {parts[1]}
        </Fragment>
      );
    }

    return message;
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    setRequest(null);

    setActiveChatMessages(true);

    setConversation(request);
  };

  const handleActiveMessage = async () => {
    let conversationSavedInLocalStorage = localStorage.getItem("chatbot_conversation");

    conversationSavedInLocalStorage = JSON.parse(conversationSavedInLocalStorage);

    let services = conversationSavedInLocalStorage.services;

    const account = totalAccounts.filter((account) => account?.services?.map((x) => x?.idService).includes(services[0]));

    setActiveChatMessages(true);

    if (services.length && account.length && account[0]?.idAccount !== currentAccount?.idAccount) {
      setAccount(account[0]);
      await loadServicesAsync(services);
      const filteredServices = totalServices.filter((service) => services.includes(service.idService));

      setActiveServicesEnergy(filteredServices);

      setActiveServicesPlus([_.last(filteredServices)]);
    } else {
      const filteredServices = totalServices.filter((service) => services.includes(service.idService));

      setActiveServicesEnergy(filteredServices);

      setActiveServicesPlus([_.last(filteredServices)]);
    }

    if (location.pathname === "/home") history.push("home/detail");
  };

  const groupMessageDate = (messages) =>
    messages.reduce((group, message) => {
      const { full_date } = message;

      let dateGroup = group.find((item) => item.full_date === full_date);

      if (!dateGroup) {
        dateGroup = { full_date, messages: [] };
        group.push(dateGroup);
      }

      dateGroup.messages.push(message);

      return group;
    }, []);

  const handleOnBoarding = (id) => {
    // const tutorialListInfo = tutorialListInfoGlobal(props, history, true);
    // const functionOnBoarding = tutorialListInfo?.some(x => x?.id === id) ? tutorialListInfo?.find(x => x?.id === id)?.tutorialFunction : null;
    // if (functionOnBoarding) functionOnBoarding();
  };

  // useEffect(() => {

  //     scrollToBottom();

  //     if (messages.length && currentResponse) {

  //         const newMessages = messages
  //             .filter(item => item?.message !== 'pending');

  //         setMessages([...newMessages, currentResponse]);

  //         setCurrentResponse(null);

  //     }

  // }, [currentResponse, messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    loadConversations();
  }, [loadConversations]);

  useEffect(() => {
    const indexSelected = aids.indexOf(request);

    if (indexSelected !== -1) {
      switch (indexSelected) {
        case 0:
          track("Ai consumption default chat selected");
          break;
        case 1:
          track("Ai reactive default chat selected");
          break;
        case 2:
          track("Ai maximum default chat selected");
          break;
        default:
          break;
      }
    }
  }, [request, aids]);

  useEffect(() => {
    if (mount) {
      if (showChatBot)
        track("Ai Chat opened");
      else
        track("Ai Chat ended");
    }
    else
      setMount(true)
    // eslint-disable-next-line
  }, [showChatBot]);

  return (
    <Fragment>
      <div
        onClick={() => !showBannerChatbot && setShowChatBot(true)}
        className={`zindex-3 pe-pointer`}
        style={{ width: 72, height: 72, transform: showBannerChatbot ? 'scale(0)' : 'scale(1)', transition: 'transform 0.3s ease-in-out' }}>
        <RiveComponent />
      </div>
      <Drawer
        show={showChatBot}
        size={"xs"}
        placement={"right"}
        backdropClassName={"bg-black opacity-80 pe-pointer"}
        onHide={() => setShowChatBot(false)}>
        <div className="position-relative column-between align-items-center vh-100 w-100 bg-gray-500">
          {showBlackScreenDrawer && (
            <div
              className="position-absolute left-0 top-0 h-100 w-100"
              style={{ zIndex: 1030 }}>
              <div className="bg-black opacity-80 w-100 h-100" />
            </div>
          )}
          {!enableChatBot ? (
            <div className="w-100 column-center align-items-center h-100 my-5 px-4">
              <div className="text-wrap text-center txt-title txt-16 txt-bold txt-white">{language.lumi_title}</div>
              <div className="text-wrap text-center txt-phrase txt-8 txt-white py-2">{language.lumi_first_text}</div>
              <div
                className="row-center zindex-3 pe-pointer"
                style={{ width: 180, height: 180 }}>
                <RiveComponentBeta />
              </div>
              <div className="text-wrap text-center txt-phrase txt-8 txt-white">{language.lumi_second_text}</div>
              {!activeChat ? (
                <div
                  onClick={() => setActiveChatAsync()}
                  className="bg-green-500 rounded-8 m-3 py-2 pe-pointer text-wrap text-center txt-phrase txt-semibold txt-green-800 txt-8">
                  {language.lumi_do_click_here}
                </div>
              ) : (
                <div className="transition-transform bg-gray-800 border-1 border-color-gray-600 rounded-16 my-4 py-3 px-3">
                  <div className="text-wrap text-center txt-title txt-bold txt-10 txt-green-500">{language.lumi_title_card}</div>
                  <div className="text-wrap text-center txt-phrase txt-8 txt-white my-2">{language.lumi_first_text_card}</div>
                  <div className="text-wrap text-center txt-phrase txt-8 txt-white mt-2">{language.lumi_second_text_title}</div>
                </div>
              )}
            </div>
          ) : loadingChat ? (
            <div className="column-center align-items-center w-100 h-100">
              <Loader
                inverse
                center
                size="md"
                content={props.language.loader_text_content}
              />
            </div>
          ) : (
            <Fragment>
              <div className={`row-between w-100 align-items-center p-3 ${enableChatBot && "border-bottom border-color-gray-800"}`}>
                <BackButton onClick={() => setShowChatBot(false)} />
                <div style={{ width: "85%" }}>
                  <ServicesPopover
                    activeServices={activeServicesEnergy}
                    selectFunction={(value) => {
                      setActiveServicesEnergy(value);
                      setActiveServicesPlus([_.last(value)]);
                    }}
                    availableTypeServices={["energy", "plus", "generation"]}
                    limitSelectServices={3}
                    typeComponent={"chatbot"}
                  />
                </div>
              </div>
              <div
                ref={messagesContainerRef}
                className="flex-grow-1 w-100 overflow-auto"
                onLoad={() => scrollToBottom()}>
                <div className="column-end gap-3 w-100 py-3">
                  {!messages.length && (
                    <div className="text-wrap text-left px-3 txt-title txt-10 txt-title txt-bold txt-white">
                      ¡{language.lumi_hey}, {user.name}!<br />
                      <p className="txt-phrase txt-regular txt-white txt-8 text-wrap">{language.lumi_question}</p>
                    </div>
                  )}
                  {messages.length &&
                    groupMessageDate(messages).map((group, index) => {
                      return (<Fragment key={index}>
                        {group.full_date !== null && group.full_date !== undefined && (
                          <div className="row-center align-items-center">
                            <div className="txt-white bg-gray-800 px-4 py-2 rounded-20 txt-8">{group.full_date}</div>
                          </div>
                        )}
                        {group.messages.map((item, index) => (
                          <div
                            key={index}
                            className={`${item?.sender === "user" ? "row-end" : "row-start"}`}>
                            <div className="column-start px-3">
                              <div className={`txt-6 txt-gray-400 mb-1 ${item?.sender === "user" ? "text-end me-3" : "text-start ms-5"}`}>{item?.sender === "user" ? language.lumi_you_chat : "Lumi"}</div>
                              <div className="row-end w-100">
                                {item?.sender !== "user" && (
                                  <div className="column-end txt-white me-0">
                                    <img
                                      width={42}
                                      src={lumi}
                                      alt="logo-lumi"
                                    />
                                  </div>
                                )}
                                <div className={`row-center rounded-top-14 text-8 text-white pt-3 px-3 pb-2 ${item?.sender === "user" ? "rounded-left-14 bg-blue-500" : "rounded-right-14 bg-gray-300"}`}>
                                  {item?.message !== "pending" ? (
                                    <div className="column-between">
                                      <div className="row-start">
                                        {item?.onBoarding !== null ? (
                                          <div>
                                            {language.lumi_explanation}
                                            <u
                                              onClick={() => handleOnBoarding(item?.onBoarding)}
                                              className="pe-pointer">
                                              {language.lumi_go_tutorial}
                                            </u>
                                          </div>
                                        ) : (
                                          <div style={{ whiteSpace: "pre-line" }}>{replaceMessageLink(item?.message)}</div>
                                        )}
                                      </div>
                                      <div className={`row-${item?.sender === "user" ? "end" : "start"} txt-6 txt-white opacity-70 mt-1`}>{item?.date}</div>
                                    </div>
                                  ) : (
                                    <div className="loadingChatbot mb-1">. . .</div>
                                  )}
                                </div>
                              </div>
                              {item?.sender !== "user" && item?.message !== "pending" && item?.id_content !== 0 && (
                                <div className="row-start align-items-center w-100 mt-2 gap-3">
                                  <div className="txt-8 txt-gray-400 ms-5">Califica esta respuesta</div>
                                  <FontAwesomeIcon
                                    icon={item?.score === 1 ? faThumbsUpSolid : faThumbsUp}
                                    className="txt-green-500-hover pe-pointer txt-gray-400"
                                    style={{ width: 14, height: 14 }}
                                    onClick={() => {
                                      if (item?.score !== 1) setScore({ id_content: item?.id_content, score: 1 });
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={item?.score === 2 ? faThumbsDownSolid : faThumbsDown}
                                    className="txt-red-500-hover pe-pointer txt-gray-400"
                                    style={{ width: 14, height: 14 }}
                                    onClick={() => {
                                      if (item?.score !== 2) setScore({ id_content: item?.id_content, score: 2 });
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </Fragment>)
                    })}
                </div>
              </div>
              <div className="w-100 sticky-bottom row-between bottom-0 p-3 w-100 border-top border-color-gray-800 gap-3">
                <ChatPopover />
                {activeChatMessages || currentConversationId === null ? (
                  <div className="row-between align-items-center bg-gray-900 border-color-gray-900 border-1 px-2 overflow-hidden rounded-pill pe-pointer flex-grow-1 gap-2">
                    <datalist
                      id="sugerenciasPreguntas"
                      className="bg-black">
                      {aids.map((value) => (
                        <option
                          key={value}
                          value={value}
                        />
                      ))}
                    </datalist>
                    <div className="row-center align-items-center align-self-stretch border-1 border-color-green-500 bg-green-500-40 rounded-pill txt-green-500 txt-6 my-2 px-2 py-1">BETA</div>
                    <input
                      list="sugerenciasPreguntas"
                      type="text"
                      className="w-100 border-0 bg-gray-900 txt-white txt-phrase txt-regular txt-8 pe-pointer"
                      value={request ?? ""}
                      placeholder={language.lumi_ask_me}
                      onChange={({ target }) => setRequest(target?.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && request) {
                          handleSendMessage();
                        }
                      }}
                    />
                    <div
                      className="row-center align-items-center rounded-30 p-2 ms-3"
                      onClick={() => {
                        if (request) {
                          handleSendMessage();
                          track("Ai message sent");
                        }
                      }}>
                      <i className={`fas fa-paper-plane-top txt-10 txt-white pe-pointer opacity-${request ? "100" : "30"}`} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="row-between align-items-center bg-gray-450 border-color-gray-800 border-1 py-2 ps-3 pe-2 rounded-pill flex-grow-1 pe-pointer"
                    onClick={() => handleActiveMessage()}>
                    <p className="text-phrase txt-gray-700 txt-10">{language.lumi_continue_conversation}</p>
                    <div className="row-center align-items-center rounded-30 p-2 ms-3">
                      <i className="far fa-arrow-right txt-10 txt-gray-700 txt-regular txt-8 pe-pointer" />
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  language: state.shared.language,
  user: state.users.user,
  messages: state.chatbot.messages,
  activeChat: state.chatbot.activeChat,
  loadingChat: state.chatbot.loadingChat,
  totalServices: state.services.totalServices,
  activeServicesEnergy: state.services.activeServicesEnergy,
  showBlackScreenDrawer: state.shared.showBlackScreenDrawer,
  currentConversationId: state.chatbot.currentConversationId,
  totalAccounts: state.accounts.totalAccounts,
  account: state.accounts.account,
  showChatBot: state.chatbot.showChatBot,
  activeChatMessages: state.chatbot.activeChatMessages,
  showBannerChatbot: state.chatbot.showBannerChatbot
});

const mapDispatchToProps = (dispatch) => ({
  setActiveChatAsync: () => dispatch(chatbotActions.setActiveChatAsync()),
  setConversation: (payload) => dispatch(chatbotActions.setConversation(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
  setShowChatBot: (payload) => dispatch(chatbotActions.setShowChatBot(payload)),
  setActiveChatMessages: (payload) => dispatch(chatbotActions.setActiveChatMessages(payload)),
  setScore: (payload) => dispatch(chatbotActions.setScore(payload)),
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
  loadConversations: () => dispatch(chatbotActions.loadConversations())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBot);
