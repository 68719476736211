import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';

import { capitalizeFirstLetter } from "../../services/StringsHandler";

import empty_card_bill from "../../imgs/empty-card-bill.svg";

import ContentLoader from "react-content-loader";

import { Animation } from 'rsuite';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';

import InvoiceBubble from "./InvoiceBubble";

const FilterInvoices = (props) => {

    let invoicesPeriodsFiltered = props.invoicesPeriodsFiltered;
    let language = props.language;
    let filterStatus = props.filterStatus;
    let selectedInvoices = props.selectedInvoices;
    let invoicesDate = props.invoicesDate;

    const loadInvoicesAsync = props.loadInvoicesAsync;
    const setInvoiceShow = props.setInvoiceShow;
    const setSelectedInvoices = props.setSelectedInvoices;

    const locale = language.language_locale === 'es' ? esLocale : enLocale;

    const [openPeriods, setOpenPeriods] = useState([]);

    const handleOpenPeriod = (period, open) => {

        let periods = openPeriods;

        const periodId = period.id_period;
        const date = new Date(period.billing_date);

        if (periods.includes(periodId)) {
            periods = periods.filter(p => p !== periodId);
        } else {
            periods = [...periods, periodId]
        }

        setOpenPeriods(periods);

        !open && loadInvoicesAsync({ month: date.getMonth() + 1, year: date.getFullYear() })

    };

    const handleSelectAll = (ids, allSelected, open, period) => {

        if (allSelected) {
            setSelectedInvoices([...selectedInvoices.filter(e => !ids.includes(e))]);
        } else {
            !open ? handleOpenPeriod(period, open) : setSelectedInvoices([...selectedInvoices, ...ids]);
        }

    };

    useEffect(() => {

        if (filterStatus && invoicesPeriodsFiltered) {

            const index = Math.max(invoicesPeriodsFiltered.findIndex(i => {

                const date = new Date(i.billing_date);

                return invoicesDate.getMonth() === date.getMonth() && invoicesDate.getFullYear() === date.getFullYear();

            }), 0);

            if (invoicesPeriodsFiltered[index]) {

                setOpenPeriods(prev => [...prev, invoicesPeriodsFiltered[index].id_period]);

                if (!invoicesPeriodsFiltered[index].invoices) {

                    const date = new Date(invoicesPeriodsFiltered[index].billing_date);

                    loadInvoicesAsync({ month: date.getMonth() + 1, year: date.getFullYear() });

                }
            }

        }
        // eslint-disable-next-line
    }, [filterStatus, setOpenPeriods]);

    return (
        <div className="column-start align-items-center h-100 gap-5 ps-3 pe-2 py-4 w-100 overflow-auto">
            {
                invoicesPeriodsFiltered?.length ?
                    invoicesPeriodsFiltered.map(period => {

                        let open = openPeriods.includes(period.id_period);

                        const billsIds = period.invoices?.map(i => i.id_bill);

                        const allSelected = billsIds && billsIds
                            .every(element => selectedInvoices.includes(element));

                        const date = new Date(period.billing_date);

                        return (
                            <div key={period.id_period} className="w-100">
                                <div className="row-between align-items-center gap-3 w-100 pe-pointer" onClick={() => {
                                    handleOpenPeriod(period, open);
                                }}>
                                    <div className="row-start align-items-center gap-3 pe-pointer">
                                        <i className={`fal txt-10 ${allSelected ? 'fa-circle-check txt-green-500' : 'fa-circle txt-gray-200'}`} onClick={(e) => {
                                            e.stopPropagation();
                                            handleSelectAll(billsIds, allSelected, open, period)
                                        }}></i>
                                        <p className="txt-phrase txt-semibold txt-8 txt-white pe-pointer">{capitalizeFirstLetter(format(date, "MMMM yyyy", { locale: locale }))}</p>
                                    </div>
                                    <i className={`far txt-8 txt-gray-200 fa-chevron-${open ? 'up' : 'down'}`}></i>
                                </div>
                                <Animation.Collapse in={open} dimension={'height'} timeout={100}>
                                    <div className="column-start align-items-start gap-2 px-2 w-100">
                                        {
                                            period?.invoices ?
                                                <Fragment>
                                                    {
                                                        period?.invoices.length ?
                                                            period?.invoices.map(i => {

                                                                let returnInvoice = false;

                                                                switch (filterStatus) {
                                                                    case 'paid':
                                                                        returnInvoice = i.state === 1;
                                                                        break;
                                                                    case 'overdue':
                                                                        returnInvoice = i.state === 0 && new Date(i.date_expiration) < new Date()
                                                                        break;
                                                                    case 'pending':
                                                                        returnInvoice = i.state === 0;
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }

                                                                return (
                                                                    returnInvoice ? <InvoiceBubble key={i.id_bill} invoice={i} handlerClick={() => setInvoiceShow(i)} /> : null
                                                                )
                                                            }) :
                                                            <div className="w-100 h-100 px-3 py-4 column-center align-items-center">
                                                                <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap text-center">{props.language.invoices_text_empty_bills_period}</div>
                                                            </div>
                                                    }
                                                </Fragment> :
                                                <div className="position-relative w-100 mt-3" style={{ height: 100 }}>
                                                    <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
                                                        <ContentLoader seed={2} backgroundColor="#161617" foregroundColor="#323234" style={{ width: "100%", height: "100%" }}>
                                                            <rect x="12" y="0" width="calc(100% - 12px)" height="100" rx="16" ry="16"></rect>
                                                        </ContentLoader>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </Animation.Collapse>
                            </div>
                        )
                    }) :
                    <div className="w-100 h-100 px-3 column-center align-items-center">
                        <img src={empty_card_bill} alt="empty_card_bill" style={{ width: 172, height: 104 }} />
                        <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap mt-4 text-center">{props.language[`invoices_text_empty_bills_${filterStatus}`]}</div>
                    </div>
            }
        </div>

    );
};

const mapStateToProps = (state) => ({
    invoicesPeriodsFiltered: state.invoices.invoicesPeriodsFiltered,
    totalInvoicesByPeriod: state.invoices.totalInvoicesByPeriod,
    language: state.shared.language,
    filterStatus: state.invoices.filterStatus,
    selectedInvoices: state.invoices.selectedInvoices,
    invoicesDate: state.invoices.invoicesDate
});

const mapDispatchToProps = dispatch => ({
    loadInvoicesAsync: (payload) => dispatch(invoicesActions.loadInvoicesAsync(payload)),
    setInvoiceShow: (payload) => dispatch(invoicesActions.setShowInvoiceDetails(payload)),
    setSelectedInvoices: (payload) => dispatch(invoicesActions.setSelectedInvoices(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterInvoices);