import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../../../store/actions/Services';
import * as plusActions from '../../../../store/actions/Plus';

import Header from "../../../shared/Header";
import GraphPower from "./GraphPower";
import ServicesPlus from "../shared/ServicesContainer";
import CreateDevice from "./CreateDevice"

import { Animation, Table } from 'rsuite';

import moment from "moment";

import { useWindowSize } from '../../../shared/utilities/useWindowSize';

import { Link } from 'react-router-dom';

import empty_modules_plus from "../../../../imgs/box.svg";

const DataAppliances = ({ value, property, unit }) => {
    return (
        <div className="row-between w-100">
            <div className="txt-phrase txt-regular txt-gray-400 txt-6">{property}</div>
            <div className="row-end align-items-center">
                <div className="txt-phrase txt-semibold txt-green-50 txt-6">{value}</div>
                <div className={`txt-phrase txt-semibold txt-green-50 txt-6 ${unit ? 'ms-2' : 'ms-0'}`}>{unit}</div>
            </div>
        </div>
    )
}


const RenderCardEmpty = ({ language }) => {
    return (
        <div className={`column-between w-100 align-items-center bg-gray-500 rounded-16 p-3`} style={{ height: 220 }}>
            <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 144, height: 144 }} />
            <div className={`row-center mx-5 mt-2 align-items-center txt-phrase txt-bold txt-8`}>
                {language.tag_new_tag_text_no_devices}
            </div>
            <div className={`row-center text-center mt-1 text-wrap txt-phrase txt-regular txt-6`}>
                {language.tag_new_tag_text_no_devices_description}
            </div>
        </div>
    )
};


const Devices = (props) => {

    const [showInfoDevice, setShowInfoDevice] = useState(false);

    const [applianceSelected, setApplianceSelected] = useState(false);

    const isSelected = props.deviceSelected === props.item?.id_appliance;

    useEffect(() => {
        if (applianceSelected) {
            props.onChange(props.item?.id_appliance);
            setApplianceSelected(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applianceSelected]);

    return (
        <Fragment>
            <div className={`row-between align-items-center w-100 pe-pointer mt-3 p-2 ${showInfoDevice ? 'rounded-top-8' : 'rounded-8'} ${showInfoDevice || isSelected ? 'bg-gray-800' : 'bg-gray-500'}`}
                onClick={() => setApplianceSelected(true)}>
                <div className="row-start align-items-center">
                    <div className="column-center rounded-8 bg-gray-500 p-2" style={{ width: 40, height: 40 }}>
                        <i className="fa-regular fa-plug-circle-bolt txt-green-50 txt-12"></i>
                    </div>
                    <div className="col-8 text-wrap txt-phrase txt-semibold txt-white txt-8 ms-3 pe-pointer">{props.item.custom_name}</div>
                </div>
                <i className={`fa-regular fa-chevron-down txt-white txt-10 pe-pointer ${!showInfoDevice ? 'device-detail-open' : 'device-detail-close'}`} onClick={() => setShowInfoDevice(!showInfoDevice)}></i>
            </div>
            <Animation.Collapse in={showInfoDevice} dimension={'height'}>
                <div className="column-start bg-gray-800 w-100 rounded-bottom-8 p-2">
                    {props.item.id_tag ?
                        <Fragment>
                            <DataAppliances value={props.item.active_cons.toFixed(4)} property={props.language.tag_new_tag_text_average_comsuption} unit={"Wh"} />
                            <DataAppliances value={props.item.duration} property={props.language.tag_new_tag_text_duration} unit={"s"} />
                        </Fragment>
                        :
                        <div className="row-between w-100">
                            <div className="txt-phrase txt-regular txt-gray-400 txt-6">{props.language.tag_new_tag_text_no_tag}</div>
                        </div>
                    }
                </div>
            </Animation.Collapse>
        </Fragment>
    );
}

const TagManager = (props) => {

    const size = useWindowSize();

    const graphContiner = useRef(null);

    const [graphContinerWidth, setGraphContinerWidth] = useState(0);
    const [showCreateDevice, setShowCreateDevice] = useState(false);
    const [deviceSelected, setDeviceSelected] = useState(null);
    const [search, setSearch] = useState('');
    let account = props.account;

    useEffect(() => setGraphContinerWidth(graphContiner.current.getBoundingClientRect().width), [size]);

    useEffect(() => {
        if (!props.activeServicesPlus?.filter(item => item?.idAccount === props.account?.idAccount).length) {
            account && props.loadServicesAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        if (props.activeServicesPlus.length) props.loadAppliances();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [props.activeServicesPlus]);

    useEffect(() => {
        if (props.appliances.length) {
            if (deviceSelected) props.loadTagRecords(deviceSelected);
        }
        else {
            props.setTagRecords([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appliances, deviceSelected, props.activeServicesPlus]);

    useEffect(() => {
        if (props.appliances.length && !deviceSelected) setDeviceSelected(props.appliances[0]?.id_appliance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appliances])

    const { Column, HeaderCell, Cell } = Table;

    return (
        <section className="column-start align-items-center min-vh-100 w-100">
            <Header moduleName={props.language.tag_manager_title_main_dashboard} backToButton={true} />
            <div className="column-center align-items-center flex-grow-1 w-100">
                <ServicesPlus />
                <div className="row-between align-items-start w-100 p-3">
                    <div className="column-start align-items-start bg-gray-500 border-1 border-color-gray-800 rounded-16 px-3 py-4" style={{ width: 300, minWidth: 300, maxHeight: 516, height: 'fit-content' }}>
                        <div className="txt-phrase txt-regular txt-gray-50 txt-10 row-center w-100 mb-3">{props.language.tag_manager_title_devices}</div>
                        <div className="row-start w-100 align-items-center border-1 border-color-white p-2 rounded-8 mb-3">
                            <input type="text" className="txt-phrase txt-8 txt-white bg-gray-500 border-0 w-100" placeholder={props.language.services_input_label_search} value={search} onChange={({ target }) => setSearch(target.value)} />
                            <i className="fa-regular fa-search txt-8 txt-white" />
                        </div>
                        <div className="w-100" style={{ overflowX: "hidden", overflowY: "auto", maxHeight: 330 }}>
                            {props.appliances.filter(item => item?.custom_name?.toLowerCase()?.includes(search.toLowerCase()) || search === '').length ? props.appliances.filter(item => item?.custom_name?.toLowerCase()?.includes(search.toLowerCase()) || search === '').map((item, index) =>
                                <Devices key={index} item={item} deviceSelected={deviceSelected} onChange={value => setDeviceSelected(value)} language={props.language} />
                            ) : <RenderCardEmpty language={props.language} />}
                        </div>
                        <button className="row-center bg-gray-800 rounded-8 w-100 align-items-center mt-3" style={{ height: 40 }} onClick={() => { setShowCreateDevice(true); }}>
                            <i className="fa-solid fa-add txt-10 txt-neutral-200" />
                        </button>
                    </div>
                    <div ref={graphContiner} className="column-start align-items-start" style={{ width: "calc(100% - 300px)" }}>
                        <div className="column-start ps-3 align-items-center w-100">
                            <div className="column-start bg-gray-500 border-1 border-color-gray-800 rounded-16 w-100">
                                <GraphPower graphContinerWidth={graphContinerWidth} data={props.tagRecords} />
                            </div>
                        </div>
                        <div className="column-start ps-3 align-items-center w-100 mt-3">
                            <div className="column-start bg-gray-500 border-1 border-color-gray-800 rounded-16 w-100 p-3">
                                {props.tagRecords.length ?
                                    <Fragment>
                                        <Table
                                            autoHeight
                                            data={props.tagRecords}
                                            bordered
                                        >
                                            <Column width={25} verticalAlign="middle">
                                                <HeaderCell></HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div className='fas fa-trash txt-8 txt-neutral-50 pe-pointer text-center'
                                                            onClick={() => {

                                                            }}
                                                        />
                                                    )}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle">
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_name}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {`Tag ${rowData.id_tag}`}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle">
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_create_at}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {moment(rowData.create_timestamp).format('DD/MM/YYYY HH:mm:ss')}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle" flexGrow={1}>
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_active_energy}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {rowData.active_cons ? rowData.active_cons.toFixed(4) + " Wh" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle" flexGrow={1}>
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_active_power}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {rowData.active_power ? rowData.active_power.toFixed(2) + " W" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle" flexGrow={1}>
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_reactive_energy}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {rowData.reactive_cons ? rowData.reactive_cons.toFixed(4) + " VArh" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                            <Column verticalAlign="middle" flexGrow={1}>
                                                <HeaderCell className="txt-neutral-50 txt-phrase txt-semibold txt-8 text-center">{props.language.tag_manager_table_header_reactive_power}</HeaderCell>
                                                <Cell>
                                                    {rowData => (
                                                        <div>
                                                            <div className="txt-neutral-50 txt-phrase txt-regular txt-6 text-wrap text-center">
                                                                {rowData.reactive_power ? rowData.reactive_power.toFixed(2) + " VAr" : rowData.processed ? props.language.tag_table_tag_label_processed : props.language.tag_table_tag_label_procesing}
                                                            </div>
                                                        </div>)}
                                                </Cell>
                                            </Column>
                                        </Table>
                                    </Fragment> :
                                    <Fragment>
                                        <div className="column-start bg-gray-800 rounded-8 w-100 p-2" style={{ height: 130 }}>
                                            <div className="column-center align-items-center w-100 h-100">
                                                <div className="txt-gray-600 txt-phrase txt-14 txt-bold text-center mt-3">{props.language.tag_manager_table_title_empty_data}</div>
                                                <div className="txt-gray-600 txt-phrase txt-12 txt-regular text-wrap text-center">{props.language.tag_manager_table_phrase_empty_data}</div>
                                            </div>
                                        </div>
                                        <div className="row-center w-100">
                                            <Link to="/plus">
                                                <button className="mt-3 border-2 border-color-green-500 txt-phrase txt-medium txt-10 bg-gray-500 txt-green-500 py-2 rounded-8 px-4">
                                                    <i className="fa-light fa-tag txt-8 txt-green-500 me-2" />
                                                    {props.language.tag_manager_button_tag}
                                                </button>
                                            </Link>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateDevice show={showCreateDevice} setShow={setShowCreateDevice} />
        </section>
    );
}

const mapStateToProps = (state) => ({
    activeServicesPlus: state.services.activeServicesPlus,
    account: state.accounts.account,
    dataGraph: state.plus.dataGraph,
    appliances: state.plus.appliances,
    tagRecords: state.plus.tagRecords,
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
    setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
    resetData: () => dispatch(plusActions.resetData()),
    loadServicesAsync: () => dispatch(servicesActions.loadServicesAsync()),
    loadAppliances: () => dispatch(plusActions.loadAppliances()),
    loadTagRecords: (payload) => dispatch(plusActions.loadTagRecords(payload)),
    setTagRecords: (payload) => dispatch(plusActions.setTagRecords(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);