import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as paysActions from '../../../store/actions/Pays';

import { Loader, Animation, Toggle } from 'rsuite';

import CurrencyFormat from 'react-currency-format';

import neugetsLogo from '../../../imgs/neugets-logo.svg';

import { track } from "@amplitude/analytics-browser";

const TotalPayment = (props) => {

    let neugets = props.neugets;
    let isEnable = props.isEnable;
    let infoPayment = props.infoPayment;
    let isLoading = props.paymentLoading;

    const setInfoPayment = props.setInfoPayment;
    const payAction = props.payAction;

    const neugetsToCOP = 1000;

    const [maxNeugets, setMaxNeugets] = useState();

    const [neugetsData, setShowNeugetsData] = useState(false);    

    useEffect(() => {
        setMaxNeugets(Math.min(neugets, Math.floor(infoPayment.total_payment / neugetsToCOP)))
    }, [neugets, infoPayment, setMaxNeugets]);

    const changeNeugets = (value) => {
        value >= 0 && value <= maxNeugets && setInfoPayment({ ...infoPayment, neugets: value});
    }

    return (
        <div className='w-100 py-3'>
            <div className='w-100 rounded-16 bg-gray-800 p-3'>
                <div className='row-between align-items-start w-100 mb-3'>
                    <div className={`col-6 column-center align-items-start ${!neugetsData && "bg-gray-600"} rounded-10 p-2`}>
                        <CurrencyFormat
                            className="txt-bold txt-10 txt-green-500 txt-title"
                            value={neugetsData ? infoPayment.total_payment : infoPayment.total_payment / (1 - infoPayment.commission)}
                            prefix="$"
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={1}
                            fixedDecimalScale={true}
                        />
                        <div className='txt-phrase txt-semibold txt-6 txt-white mt-1'>{neugetsData ? props.language.invoices_pay_title_subtotal_payment : props.language.invoices_pay_title_total_payment}</div>
                    </div>
                    {
                        false && 
                            <Fragment>
                                <div className='col-6 column-center align-items-end'>
                                    <div className='row-center align-items-center'>
                                        {maxNeugets !== 0 &&
                                            <Toggle
                                                checked={neugetsData}
                                                disabled={maxNeugets === 0}
                                                onChange={(value) => {
                                                    setInfoPayment({ ...infoPayment, neugets: value ? maxNeugets : 0 });
                                                    setShowNeugetsData(value);
                                                }} />}
                                        <div className="row-start align-items-center bg-gray-500 p-1 ms-2 rounded-pill" style={{ width: 70 }}>
                                            <div id="neugets-logo-small" className="row-center align-items-center rounded-circle p-1">
                                                <img src={neugetsLogo} alt="neugets-logo" className="m-1 w-100 h-100"></img>
                                            </div>
                                            <div className="col align-items-center txt-6 txt-white txt-phrase txt-semibold text-center">{props.neugets ? props.neugets : 0}</div>
                                        </div>
                                    </div>
                                    <div className='txt-phrase txt-6 txt-white mt-1 text-wrap text-center'>{maxNeugets !== 0 ? props.language.invoices_pay_title_use_neugets : props.language.invoices_pay_title_get_neugets}</div>
                                </div>
                                <Animation.Collapse in={neugetsData} dimension={'height'}>
                                    <div className='column-center align-items-center w-100'>
                                        <div className='txt-phrase txt-8 txt-white text-center'>{props.language.invoices_pay_title_quantity_neugets}</div>
                                        <div className='row-between align-items-center w-100 my-3'>
                                            <div className='col column-center align-items-center'>
                                                <div className="row-between align-items-center w-100 px-3">
                                                    <div className='row-center align-items-center rounded-pill border-1 border-color-white' style={{ height: 18, width: 18 }} onClick={() => changeNeugets(infoPayment.neugets - 1)}>
                                                        <div className="txt-phrase txt-white txt-8 pe-pointer user-select-none">-</div>
                                                    </div>
                                                    <input
                                                        type={'number'}
                                                        className="border-0 bg-transparent txt-white text-center txt-title txt-10"
                                                        style={{ width: 60 }}
                                                        value={infoPayment.neugets || 0}
                                                        onChange={({ target }) => changeNeugets(Math.floor(target.value))} />
                                                    <div className='row-center align-items-center rounded-pill border-1 border-color-white' style={{ height: 18, width: 18 }} onClick={() => changeNeugets(infoPayment.neugets + 1)}>
                                                        <div className="txt-phrase txt-white txt-8 pe-pointer user-select-none">+</div>
                                                    </div>
                                                </div>
                                                {
                                                    infoPayment.neugets &&
                                                    <CurrencyFormat
                                                        className="txt-phrase txt-6 txt-green-500"
                                                        value={infoPayment.neugets * neugetsToCOP}
                                                        prefix="- $"
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={1}
                                                        fixedDecimalScale={true} />}
                                            </div>
                                            <div className='col column-center align-items-center bg-gray-600 rounded-10 p-2'>
                                                <CurrencyFormat
                                                    className="txt-bold txt-10 txt-green-500 txt-title"
                                                    value={(infoPayment.total_payment - infoPayment.neugets * neugetsToCOP) / (1 - infoPayment.commission)}
                                                    prefix="$"
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={1}
                                                    fixedDecimalScale={true}
                                                />
                                                <div className='txt-phrase txt-semibold txt-6 txt-white mt-1'>{props.language.invoices_pay_title_total}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Animation.Collapse>
                            </Fragment>
                    }
                </div>
                <div className={`row-center align-items-center ${isEnable ? "bg-green-500 pe-pointer" : "bg-gray-600"} rounded-8 py-2 px-3`}
                    onClick={() => {                        
                        if (!isLoading) {
                            payAction();                            
                            isEnable && track('Bill payed', {Payment_Method: props.typeComponent, Bill_ID: props.activeServicesInvoices.map(function (service) { return service?.name })})
                        }
                    }}>
                    {
                        isLoading ?
                            <Loader /> :
                            <Fragment>
                                <i className={`fa-regular fa-wallet txt-12 ${isEnable ? "txt-neutral-800" : "txt-gray-200"}`}></i>
                                <p className={`txt-phrase txt-bold txt-8 ${isEnable ? "txt-neutral-800 pe-pointer" : "txt-gray-200"} ps-3`}>{props.language.invoices_pay_button_pay}</p>
                            </Fragment>
                    }
                </div>
            </div> 
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    infoPayment: state.pays.infoPayment,
    paymentLoading: state.pays.paymentLoading,
    neugets: state.accounts.neugets,
    invoicesToPay: state.invoices.invoicesToPay,
    language: state.shared.language,
    isEnable: ownProps.isEnable,
    payAction: ownProps.payAction,
    typeComponent: state.pays.typeComponent,
    activeServicesInvoices: state.services.activeServicesInvoices
})

const mapDispatchToProps = dispatch => ({
    setInfoPayment: (payload) => dispatch(paysActions.setInfoPayment(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TotalPayment)