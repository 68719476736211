import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import * as usersActions from "../../../store/actions/Users";

import { useTable, useSortBy, usePagination } from "react-table";

import amplitude from "amplitude-js";
import empty_users from "../../../imgs/empty-users.svg";

const Table = ({ columns, data, actions, page, deleteAction, ...props }) => {

    const {
        getTableBodyProps,
        headerGroups,
        prepareRow
    } = useTable({ columns, data }, useSortBy, usePagination);

    const [userSelected, setUserSelected] = useState(null);

    useEffect(() => {
        if (userSelected) { actions(userSelected); }
    }, [actions, userSelected]);

    return (
        <Fragment>
            {
                data.length ?
                    <div className="table-overflow">
                        <table className="table">
                            <thead className="bg-gray-500 position-sticky zindex-2 top-0">
                                {headerGroups
                                    .map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers
                                                .map((column) => (
                                                    column.id !== "id" &&
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="txt-white txt-phrase txt-8 txt-medium py-3">
                                                        <div className="row-start align-items-center">
                                                            <div>{column.render("Header")}</div>
                                                            <span className="ms-2">
                                                                {column.isSorted ?
                                                                    column.isSortedDesc
                                                                        ? <i className="fa-solid fa-sort-down txt-10 txt-white pe-pointer" />
                                                                        : <i className="fa-solid fa-sort-up txt-10 txt-white pe-pointer" /> :
                                                                    ""}
                                                            </span>
                                                        </div>
                                                    </th>
                                                ))}
                                        </tr>
                                    ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page
                                    .map((row) => {                                        
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells
                                                    .map((cell) => {
                                                        return (
                                                            cell.column.id !== "id" &&
                                                            <td className="txt-white txt-phrase txt-8 txt-regular py-3" style={{ verticalAlign: "middle" }} {...cell.getCellProps()}>
                                                                {cell.column.id === "services" ?
                                                                    <div className="row-center align-items-center p-2 bg-gray-800 rounded-pill" style={{ width: 70 }}>
                                                                        <i className="fa-regular fa-building txt-10 txt-white me-2"></i>{cell.render("Cell")}
                                                                    </div> :
                                                                    cell.column.id === "department" && cell.value ?
                                                                        <div className="row-start align-items-center">
                                                                            {cell.value?.length > 1 ?
                                                                                <div className="row-start align-items-center">
                                                                                    <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                                        {props.language[cell?.value[0]?.valueText]}
                                                                                    </div>
                                                                                    <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill ms-1">{`+${cell.value.length - 1}`}</div>
                                                                                </div> :
                                                                                <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                                    {props.language[cell?.value[0]?.valueText] ?? 'N/A'}
                                                                                </div>}
                                                                            {/* <div className="row-center align-items-center py-2 px-3 bg-green-900 border-1 border-color-green-500 txt-green-500 txt-phrase txt-semibold txt-6 rounded-pill">
                                                                                {cell.value.length > 1 ? `${cell.value[0]?.name} ...+${cell.value.length - 1}` : cell.value[0]?.name ?? 'N/A'}
                                                                            </div> */}
                                                                        </div> :
                                                                        cell.column.id === "edit" ?
                                                                            <div className="row-center align-items-center">
                                                                                <div className="row-center align-items-center py-2 px-3 bg-blue-500-40 border-1 border-color-blue-500 rounded-pill me-1 pe-pointer" onClick={() => { setUserSelected(cell.row.values); props.setShowCreateUserModal(2); amplitude.getInstance().logEvent("Edit User"); }}>
                                                                                    <i className="fa-regular fa-edit txt-8 txt-blue-200 pe-pointer"></i>
                                                                                </div>
                                                                                <div className="row-center align-items-center py-2 px-3 bg-red-500-40 border-1 border-color-red-500 rounded-pill ms-1 pe-pointer" onClick={() => { deleteAction(cell.row.values); amplitude.getInstance().logEvent("Delete User"); }}>
                                                                                    <i className="fa-regular fa-trash txt-8 txt-red-200 pe-pointer"></i>
                                                                                </div>
                                                                            </div> :
                                                                            cell.render("Cell")}
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div> :
                    <div className="column-center align-items-center w-100 min-height-300">
                        <img src={empty_users} alt="empty_users" style={{ width: 204, height: 100 }} />
                        <div className="txt-phrase txt-gray-400 txt-10 txt-semibold text-center mt-3">{props.language.user_table_title_empty_state}</div>
                        <div className="txt-phrase txt-gray-400 txt-8 txt-regular text-center w-50 text-wrap mt-3">{props.language.user_table_description_empty_state}</div>
                        <button className="row-center align-items-center bg-gray-800 py-2 px-4 mt-4 rounded-8 pe-pointer" onClick={() => { props.setShowCreateUserModal(1); props.setUserAccessEdit(null); amplitude.getInstance().logEvent("Add New User"); }}>
                            <i className="fa-solid fa-plus txt-8 txt-white me-2"></i>
                            <p className="txt-phrase txt-bold txt-white txt-8 pe-pointer">{props.language.user_title_create_user}</p>
                        </button>
                    </div>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setUserAccessEdit: (payload) => dispatch(usersActions.setUserAccessEdit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Table);