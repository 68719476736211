import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import * as plusActions from '../../../../store/actions/Plus';

import _ from 'lodash';

import moment from "moment";

import ContentLoader from "react-content-loader";

const indicatorsLoading = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        backgroundColor="#161617"
        foregroundColor="#323234"
        style={{ width: "100%", height: "100%" }}>
        <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
    </ContentLoader>
</div>;

const RenderWidgets = ({ item }) => {
    return (
        <div className="column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100" style={{ height: 120 }}>
            <p className="txt-phrase txt-6 txt-white">{item.tile}</p>
            <p className={`txt-bold txt-phrase txt-12 txt-${item.color}-gradient`}>{item.value}</p>
            <p className="txt-regular txt-6 txt-phrase txt-white">{item.text}</p>
        </div>);
};

const Cards = (props) => {

    const [data, setData] = useState([]);
    let qualityIndicators = props.qualityIndicators
    let devices = props.devices;
    let activeServicesPlus = props.activeServicesPlus;
    let loadQualityIndicators = props.loadQualityIndicators;

    useEffect(() => {
        const request = async () => await loadQualityIndicators();
        if (devices.length && activeServicesPlus.length) request()
    }, [devices, activeServicesPlus, loadQualityIndicators]);

    useEffect(() => {
        if (qualityIndicators) {
            const itemMax = _.maxBy(qualityIndicators?.max, d => d?.value)
            const itemMin = _.minBy(qualityIndicators?.min, d => d?.value)
            const metaData = [
                {
                    id: 1,
                    tile: 'Máximo Voltaje Hoy',
                    value: `${itemMax?.value?.toFixed(1)} V`,
                    text: `Registrado a las ${moment.utc(itemMax?.timestamp).format('HH:mm:ss')} en la Línea ${itemMax?.line}`,
                    color: 'blue-green'
                },
                // {
                //     id: 2,
                //     tile: 'Voltaje nominal (Regulación)',
                //     value: '110 V',
                //     text: 'Límite máx: 121V | mín: 99V',
                //     color: 'red-orange'
                // },
                {
                    id: 3,
                    tile: 'Mínimo Voltaje Hoy',
                    value: `${itemMin?.value?.toFixed(1)} V`,
                    text: `Registrado a las ${moment.utc(itemMin?.timestamp).format('HH:mm:ss')} en la Línea ${itemMin?.line}`,
                    color: 'blue-green'
                }
            ];
            setData(metaData);
        }

    }, [qualityIndicators])

    return (
        <div className="row-between align-items-center w-100 gap-3">
            {
                props.loadingQualityIndicators ?
                    <div className="position-relative w-100" style={{ height: 120 }}>
                        {indicatorsLoading}
                    </div> :
                    data.length ?
                        data.map((d, index) => (
                            <div key={index} className="col">
                                <RenderWidgets key={index} item={d} />
                            </div>
                        )) : null
            }
        </div>
    )
};


const mapStateToProps = (state) => ({
    qualityIndicators: state.plus.qualityIndicators,
    loadingQualityIndicators: state.plus.loadingQualityIndicators,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
})

const mapDispatchToProps = dispatch => ({
    loadQualityIndicators: () => dispatch(plusActions.loadQualityIndicators()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cards)
