import * as creators from '../creators/Plus';

import { SETData, GETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

import { getUnixTime } from 'date-fns';

import { Amplify, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import moment from 'moment';
import _ from 'lodash';

const urlData = 'https://u897vnfmtl.execute-api.us-east-2.amazonaws.com/PDN';
let subscription;

export const setDataGraph = payload => {
    return {
        type: creators.SET_DATA_GRAPH,
        payload
    }
}

export const setLoadingInitData = payload => {
    return {
        type: creators.SET_LOADING_INIT_DATA,
        payload
    }
}

export const setEnableHistory = payload => {
    return {
        type: creators.SET_ENABLE_HISTORY,
        payload
    }
}

export const setDataGraphStreaming = payload => {
    return {
        type: creators.SET_DATA_GRAPH_STREAMING,
        payload
    }
}

export const setDevices = payload => {
    return {
        type: creators.SET_DEVICES,
        payload
    }
}

export const resetData = () => {
    return {
        type: creators.RESET_DATA
    }
}

export const setEnergyQuality = payload => {
    return {
        type: creators.SET_ENERGY_QUALITY,
        payload
    }
}

export const setLoadingEnergyQuality = payload => {
    return {
        type: creators.SET_LOADING_ENERGY_QUALITY,
        payload
    }
}

export const setAlwaysOn = payload => {
    return {
        type: creators.SET_ALWAYS_ON,
        payload
    }
}

export const setLoadingAlwaysOn = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON,
        payload
    }
}

export const setIndicators = payload => {
    return {
        type: creators.SET_PLUS_INDICATORS,
        payload
    }
}

export const setLoadingIndicators = payload => {
    return {
        type: creators.SET_PLUS_LOADING_INDICATORS,
        payload
    }
}

export const setComparatives = payload => {
    return {
        type: creators.SET_COMPARATIVES,
        payload
    }
}

export const setLoadingComparatives = payload => {
    return {
        type: creators.SET_LOADING_COMPARATIVES,
        payload
    }
}

export const setAppliances = payload => {
    return {
        type: creators.SET_APPLIANCES,
        payload
    }
}

export const setTagRecords = payload => {
    return {
        type: creators.SET_TAG_RECORDS,
        payload
    }
}

export const setLastConection = payload => {
    return {
        type: creators.SET_LAST_CONECTION,
        payload
    }
}

export const setLastDay = payload => {
    return {
        type: creators.SET_LAST_DAY,
        payload
    }
}

export const setLastPage = payload => {
    return {
        type: creators.SET_LAST_PAGE,
        payload
    }
}

export const setOpenQualityEnergy = payload => {
    return {
        type: creators.SET_OPEN_QUALITY_ENERGY,
        payload
    }
}


export const setDataGraphAlwaysOn = payload => {
    return {
        type: creators.SET_DATA_GRAPH_ALWAYS_ON,
        payload
    }
}

export const setDataGraphHistoryInit = payload => {
    return {
        type: creators.SET_DATA_GRAPH_HISTORY_INIT,
        payload
    }
}

export const setIntervalGraphAlwaysOn = payload => {
    return {
      type: creators.SET_INTERVAL_GRAPH_ALWAYS_ON,
      payload,
    };
  };
  
  export const setGraphDatesAlwaysOn = payload => {
    return {
        type: creators.SET_GRAPH_DATES_ALWAYS_ON,
        payload
    }
  }
  
  export const setGraphDatesScrollAlwaysOn = payload => {
    return {
        type: creators.SET_GRAPH_DATES_SCROLL_ALWAYS_ON,
        payload
    }
  }

export const setEnableScrollDataAlwaysOn = payload => {
    return {
        type: creators.SET_ENABLE_SCROLL_DATA_ALWAYS_ON,
        payload
    }
}

export const setLoadingAlwaysOnModule = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON_MODULE,
        payload
    }
}

export const setQualityIndicators = payload => {
    return {
        type: creators.SET_QUALITY_INDICATORS,
        payload
    }
}

export const setLoadingQualityIndicators = payload => {
    return {
        type: creators.SET_LOADING_QUALITY_INDICATORS,
        payload
    }
}

export const setAlwaysOnIndicators = payload => {
    return {
        type: creators.SET_ALWAYS_ON_INDICATORS,
        payload
    }
}

export const setLoadingAlwaysOnIndicators = payload => {
    return {
        type: creators.SET_LOADING_ALWAYS_ON_INDICATORS,
        payload
    }
}

export const getDataRedis = ({page, day, type}) => {
    return async (dispatch, getState) => {
        const idService = getState().services.activeServicesPlus[0]?.idService;
        const devices = getState().plus.devices;
        let deviceActive = devices.filter(value => value.id_service === idService);
        if (deviceActive.length) {
            await GETData(`?id=${deviceActive[0]?.id_device}`, "GET", "https://wzryjrhkrcrivqalqyiieyvkui0fiuhk.lambda-url.us-east-1.on.aws", true)
            .then((result) => {
                if (result.length){
                    const payload = {
                        page: page,
                        data: result,
                        init: true,
                        day: day,
                        type: type
                    }
                    dispatch(setDataGraph(payload));
                    dispatch(setLoadingInitData(false));
                }
            })
            .catch(err => console.error(err));
        }
    }
}

export const getDataAthenaRedis = ({page, day, type}) => {
    return async (dispatch, getState) => {
        const dataGraph = getState().plus.dataGraph;
        if (dataGraph.length) {
            const timestamp = moment.utc(dataGraph[0]?.time).format('YYYY-MM-DD HH:mm:ss')
            const isLessHour = _.last(dataGraph).time - dataGraph[0]?.time < 3600000;
            const idService = getState().services.activeServicesPlus[0]?.idService;
            const devices = getState().plus.devices;
            let deviceActive = devices.filter(value => value.id_service === idService);
            if (deviceActive.length) {
                await SETData('', 'POST', { id_device: deviceActive[0]?.id_device, request: "getDataAthenaRedis", timestamp: timestamp, isLessHour: isLessHour }, urlData, true)
                .then((response) => {
                    if (response?.body?.length){
                        const payload = {
                            page: page,
                            data: response?.body,
                            init: false,
                            day: day,
                            type: type
                        }
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            dispatch(setDataGraphHistoryInit(payload));
                        }
                    }
                    setTimeout(() => dispatch(setEnableHistory(false)), 5000);
                    dispatch(setLoadingInitData(false));
                })
                .catch(err => console.error(err));
            }
        }
    }
}

export const convertDataGraph = (data, page) => {
    return async (dispatch, getState) => {
        const factor = 3600000;
        const dataConverted = data.map(data => {
            const newData = {
                device_id: data?.device_id,
                time: new Date(data?.timestamp + " GMT")?.getTime(),
                active_power: data?.active_energy * factor,
                reactive_power: data?.reactive_energy * factor,
                active_power_1: data?.rms_voltage_1 * data?.rms_current_1 * data?.power_factor_1,
                active_power_2: data?.rms_voltage_2 * data?.rms_current_2 * data?.power_factor_2,
                active_power_3: data?.rms_voltage_3 * data?.rms_current_3 * data?.power_factor_3,
                rms_voltage_1: data?.rms_voltage_1,
                rms_voltage_2: data?.rms_voltage_2,
                rms_voltage_3: data?.rms_voltage_3,
                rms_current_1: data?.rms_current_1,
                rms_current_2: data?.rms_current_2,
                rms_current_3: data?.rms_current_3,
                date: data?.timestamp?.split(' ')[0],
                power_factor_1: data?.power_factor_1,
                power_factor_2: data?.power_factor_2,
                power_factor_3: data?.power_factor_3,
            };
            return newData;
        })
        const dataGraph = getState().plus.dataGraph;
        const payload = {
            page: page,
            data: dataConverted,
            init: true,
            day: 0
        }
        if (dataGraph.length){
            dispatch(setDataGraphStreaming(dataConverted));
        }
        else{
            const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
            if(String(currentDevice[0]?.id_device) === data[0]?.device_id){
                dispatch(setDataGraph(payload));
                dispatch(setLoadingInitData(false));
            }
        }
    }
}

export const connectToIoTCore = (page, id_device) => {
  return async (dispatch) => {
    const awsmobile = {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:164ea9a0-074a-432f-9688-8c684d218f54",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_DEwtuTV5n",
        "aws_user_pools_web_client_id": "4vneg13c6bmvksbj3k7aj2kch",
        "oauth": {},
        "aws_cognito_username_attributes": [],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    };
    Amplify.configure(awsmobile);
    Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: process.env.REACT_APP_REGION,
        aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
    }));
    subscription = PubSub.subscribe(`plus/data/device_${id_device}`).subscribe({
        next: data => dispatch(convertDataGraph(data?.value, page)),
        error: error => console.error(error),
        close: () => {},
    });
  }
}

export const disconnectFromIoTCore = () => {
    return async (_dispatch, _getState) => {
        if (subscription) {
        subscription.unsubscribe(); 
        }
    }
  };

export const loadInitData = (page) => {
    return async (dispatch, getState) => {

        dispatch(setLoadingInitData(true));

        dispatch(disconnectFromIoTCore());

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value.id_service === idService);
    
        if (deviceActive.length){
            deviceActive = deviceActive[0];
            if (deviceActive?.id_infrastructure !== 2) {
                await SETData('', 'POST', { page, id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: "getInitDataV2" }, urlData, true)
                    .then(response => {
                        if (response?.body?.length){
                            const payload = {
                                page: page,
                                data: response?.body,
                                init: true,
                                day: 0
                            }
                            const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                            if(currentDevice[0]?.id_device === response?.idDevice){
                                dispatch(setDataGraph(payload));
                            }
                            if(response?.nextShardId !== ''){
                                dispatch(loadStreamingData(response?.nextShardId));
                            }
                        }
                        else{
                            dispatch(setLastConection(response?.lastConection));
                            dispatch(setLastDay(response?.lastDay));
                            dispatch(setLastPage(response?.lastPage));
                        }
                        dispatch(setLoadingInitData(false));
                    })
                    .catch(err => console.error(err))
                    .finally(() => dispatch(loadHistoryData({page: page, day: 0, type: 0}, false, "getHistoryDataInit")));
            }
            else {
                await dispatch(getDataRedis({page: page, day: 0, type: 0}));
                await dispatch(connectToIoTCore(page, deviceActive?.id_device));
                await dispatch(getDataAthenaRedis({page: page, day: 0, type: 0}));
                //dispatch(loadHistoryData({page: page, day: 0, type: 0}, false, "getHistoryDataInit"));
            }
        }
        else{
            dispatch(setLoadingInitData(false));
            dispatch(setLastConection(null));
            dispatch(setLastDay(0));
            dispatch(setLastPage(1));
        }

    }
}


export const loadHistoryData = ({ page, day, type }, loading = false, request = "getHistoryData") => {
    return async (dispatch, getState) => {

        if (loading) dispatch(setLoadingInitData(true));

        dispatch(setEnableHistory(true));

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value?.id_service === idService);

        if (deviceActive.length){
            deviceActive = deviceActive[0];
            await SETData('', 'POST', { page, id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: request, day: day }, urlData, true)
                .then(response => {
                    if (response?.body?.length){
                        const payload = {
                            page: page,
                            data: response?.body,
                            init: false,
                            day: day,
                            type: type
                        }
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            if (request === "getHistoryDataInit"){
                                dispatch(setDataGraphHistoryInit(payload));
                            }
                            else{
                                dispatch(setDataGraph(payload));
                            }
                        }
                    }
                    setTimeout(() => dispatch(setEnableHistory(false)), 5000);
                    dispatch(setLoadingInitData(false));
                })
                .catch(err => console.error(err))
        }
        else{
            dispatch(setEnableHistory(false));
            dispatch(setLoadingInitData(false));
        }

    }
}


export const loadStreamingData = (shardId) => {
    return async (dispatch, getState) => {

        let nextShardId = shardId

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value?.id_service === idService);

        if (shardId?.length > 0 && deviceActive?.length) {
            deviceActive = deviceActive[0];
            await SETData('', 'POST', { id_device: deviceActive?.id_device, request: "getStreamingData", shardId: shardId }, urlData, true)
                .then(response => {
                    if (response?.body?.length) {
                        const currentDevice = getState().plus.devices?.filter(value => value?.id_service === (getState().services.activeServicesPlus[0]?.idService));
                        if(currentDevice[0]?.id_device === response?.idDevice){
                            dispatch(setDataGraphStreaming(response?.body));
                        }
                    }
                    nextShardId = response?.nextShardId?.length ? response?.nextShardId : nextShardId;
                })
                .catch(err => console.error(err));
        }
        setTimeout(() => dispatch(loadStreamingData(nextShardId)), 1500);
    }
}

export const loadDevices = () => {
    
    const user = JSON.parse(localStorage.getItem('user'));

    return async (dispatch) => {
        await GETData(`plus/devices/${user.idUser}`, "GET")
        .then((result) => {
            if(result?.body?.length) {
                dispatch(setDevices(result.body));
            }
        })
        .catch(err => console.error(err));
    }
}

export const loadEnergyQuality = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingEnergyQuality(true));

        await GETData(`plus/quality/${idService}`, "GET")
        .then((result) => {
            if(result?.body.length){
                dispatch(setEnergyQuality(result?.body[0]));
            }
            else{
                dispatch(setEnergyQuality(null));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingEnergyQuality(false)));
    }
}


export const loadAlwaysOn = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingAlwaysOn(true));

        await GETData(`plus/alwaysOn/${idService}`, "GET")
        .then((result) => {
            if(result?.body?.length) {
                dispatch(setAlwaysOn(result?.body));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingAlwaysOn(false)));
    }
}


export const loadIndicators = () => {
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;
        
        dispatch(setLoadingIndicators(true));

        await GETData(`plus/indicators/${idService}`, "GET")
        .then((result) => {
            if(result?.body) {
                dispatch(setIndicators(result?.body));
            }
            else { 
                dispatch(setIndicators([]));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingIndicators(false)));
    }
}


export const loadComparatives = () => {
    return async (dispatch, getState) => {

        const user = JSON.parse(localStorage.getItem('user'));

        const services = getState().services.totalServices.filter(value => value?.type?.typeService === 2);
        
        dispatch(setLoadingComparatives(true));

        await GETData(`plus/comparatives/${user.idUser}`, "GET")
        .then((result) => {
            const allValues = result?.body;
            let comparatives = []
            if(result?.body.length){
                allValues.forEach(item => {
                    const service = services.filter(value => value.idService === item.id_service);
                    if (service.length) comparatives.push({...service[0], value: `${item.value} kWh`, state: 2});
                })
                dispatch(setComparatives(comparatives));
            }
            else{
                dispatch(setComparatives([]));
            }
        })
        .catch(err => console.error(err))
        .finally(() => dispatch(setLoadingComparatives(false)));
    }
}


export const loadAppliances = () => {
    
    return async (dispatch, getState) => {

        const idService = getState().services.activeServicesPlus[0]?.idService;

        await GETData(`plus/tags/appliances/${idService}`, "GET")
        .then((result) => {
            dispatch(setAppliances(result.body));
        })
        .catch(err => console.error(err));
    }
}

export const loadTagRecords = (payload) => {
    
    return async (dispatch, getState) => {

        await GETData(`plus/tags/records/${payload}`, "GET")
        .then((result) => {
            if(result?.body?.length){
                const resultModified = result.body.map(item => ({...item, id_tag: parseInt(item.id_tag)}))
                dispatch(setTagRecords(resultModified));
            }
            else{
                dispatch(setTagRecords([]));
            }
        })
        .catch(err => console.error(err));
    }
}

export const addTags = (payload) => {
    
    return async (_dispatch, getState) => {

        const language = getState().shared.language;        

        await SETData(`plus/tags`, "POST", payload)
        .then((result) => {
            if(result !== null){
                Alert.success(language.alert_create_new_tag_success)
            }else {
                Alert.error(language.alert_create_new_tag_failed)
            }
        })
        .catch(err => console.error(err));
    }
}

export const addAppliance = (payload) => {
    
    return async (dispatch, getState) => {

        const language = getState().shared.language; 

        await SETData(`plus/tags/create`, "POST", payload)
        .then((result) => {
            dispatch(loadAppliances());
            if(result !== null){
                Alert.success(language.alert_create_new_device_success, 3000)
            }else {
                Alert.error(language.alert_create_new_device_failed)
            }
        })
        .catch(err => console.error(err));
    }
}

export const loadGraphAlwaysOnAsync = (loadByScroll = false, requestLeft = true) => {
    return async (dispatch, getState) => {
  
      if(!loadByScroll) dispatch(setLoadingAlwaysOnModule(true));
  
      dispatch(setEnableScrollDataAlwaysOn(false));

      let data = []
  
      let from = getUnixTime(loadByScroll ? getState().plus.graphDateFromScrollAlwaysOn : getState().plus.graphDateFromAlwaysOn);
      let to = getUnixTime(loadByScroll ? getState().plus.graphDateToScrollAlwaysOn : getState().plus.graphDateToAlwaysOn);
      const activeServicesPlus = getState().services.activeServicesPlus;
      switch (getState().plus.intervalGraphAlwaysOn) {
        case 1:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 604800 * 1;
            }
            else{
              from = to;
              to += 604800 * 1;
            }
          }
          else{
            from -= 604800 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        case 2:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 2592000 * 1;
            }
            else{
              from = to;
              to += 2592000 * 1;
            }
          }
          else{
            from -= 2592000 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        case 3:
          if(loadByScroll) {
            if (requestLeft) {
              to = from;
              from -= 31536000 * 1;
            }
            else{
              from = to;
              to += 31536000 * 1;
            }
          }
          else{
            from -= 31536000 * 1;
          }
          dispatch(setGraphDatesScrollAlwaysOn({graphDateFromAlwaysOn: new Date(from * 1000), graphDateToAlwaysOn: new Date(to * 1000)}));
          break;
        default:
          break;
      }   
        if(activeServicesPlus.length){
            await SETData(`plus/alwaysOn/history/${from}/${to}/${activeServicesPlus[0]?.idService}/${getState().plus.intervalGraphAlwaysOn}`, 'GET')
            .then(energyResponse => {
                if (energyResponse?.body?.length) {  
                    energyResponse = energyResponse?.body;
                    if (loadByScroll){
                    const currentGraphData = getState().plus.dataGraphAlwaysOn;
                    if(requestLeft){
                        data = [...energyResponse, ...currentGraphData];
                    }
                    else{
                        data = [...currentGraphData, ...energyResponse];
                    }
                    }
                    else{               
                    data = [...data, ...energyResponse]
                    }
                    dispatch(setEnableScrollDataAlwaysOn(true));
                }
            })
            .catch(response => console.error(response));
            dispatch(setDataGraphAlwaysOn(data));
            dispatch(setLoadingAlwaysOnModule(false));
        }
      }   
  };


export const loadQualityIndicators = () => {
    return async (dispatch, getState) => {

        dispatch(setLoadingQualityIndicators(true));

        const idService = getState().services.activeServicesPlus[0]?.idService;

        const devices = getState().plus.devices;

        let deviceActive = devices.filter(value => value.id_service === idService);
    
        if (deviceActive.length){
            deviceActive = deviceActive[0]
            await SETData('', 'POST', { id_device: deviceActive?.id_device, timezone: deviceActive?.timezone, request: "getQualityIndicators" }, urlData, true)
                .then(response => {
                    if (response?.body){
                        dispatch(setQualityIndicators(response?.body));
                    }
                    else{
                        dispatch(setQualityIndicators(null));
                    }
                    dispatch(setLoadingQualityIndicators(false));
                })
                .catch(err => console.error(err));
        }
        else{
            dispatch(setLoadingQualityIndicators(false));
        }

    }
}

export const loadAlwaysOnIndicators = () => {
    return async (dispatch, getState) => {

        dispatch(setLoadingAlwaysOnIndicators(true));

        const activeServicesPlus = getState().services.activeServicesPlus;
    
        if (activeServicesPlus.length){
            
            await SETData(`plus/alwaysOn/indicators/${activeServicesPlus[0]?.idService}/es`, 'GET')
                .then(response => {
                    if (response?.body){
                        dispatch(setAlwaysOnIndicators(response?.body));
                    }
                    else{
                        dispatch(setAlwaysOnIndicators([]));
                    }
                    dispatch(setLoadingAlwaysOnIndicators(false));
                })
                .catch(err => console.error(err));
        }
        else{
            dispatch(setLoadingAlwaysOnIndicators(false));
        }

    }
}
