import React, { useState, Fragment } from "react";

import { Popover } from '@idui/react-popover';

import Tooltip from "../shared/utilities/Tooltip";

import { useWindowSize } from "../shared/utilities/useWindowSize";

const SubMenu = ({ data, selectedData, newData, language, onChange, keyName, labelName }) => {

    const [show, setShow] = useState(false);    
    const [activatedAllOptions, setActivatedAllOptions] = useState(false);

    function checkMultipleExist(arr, values) {
        const multipleExist = values.every(value => {
            return arr.includes(value.id_graph);
        });

        return multipleExist;
    };

    function mergeObjectsWithoutDuplicateIds(selected, list) {
        const mergedIds = selected.concat(list.map(item => item.id_graph));
        const uniqueIds = mergedIds.filter((id, index) => mergedIds.indexOf(id) === index);
        return uniqueIds;
    };

    const screenSize = useWindowSize();

    return (
        <Popover
            lazy            
            fitMaxHeightToBounds
            fitMaxWidthToBounds            
            trigger={'click'}            
            isOpen={show}
            placement="right"                        
            guessBetterPosition                    
            className={'p-0 rounded-8 bg-gray-500 genius-picker'}
            withArrow={true}  
            arrowColor="#161617"            
            arrowSize={20}      
            width="400px"
            offset={screenSize.width < 1400 ? [0 , 10] : [0 , 0]}
            openingAnimationTranslateDistance={0}
            spaceBetweenPopoverAndTarget={30}
            onChangeOpen={(state) => {                
                setActivatedAllOptions(checkMultipleExist(selectedData, data));
                setShow(state)
            }}
            animation={{
                animate: { opacity: 1 },
                exit: { opacity: 0, transition: { duration: 0.1 } },
                initial: { opacity: 0, }
            }}
            content={
                <Fragment>
                    <div className="w-100 px-3 overflow-auto border-none-scroll max-height-230 max-height-500-2xl">
                        {data.length ? <div className="column-start graphs-picker-container mb-3">
                            <div className="row-between align-items-center w-100 mt-3 mb-2">
                                <p className="txt-phrase txt-8 txt-green-500 pe-pointer"
                                    onClick={() => {
                                        if (!activatedAllOptions || !selectedData.length) {
                                            onChange(mergeObjectsWithoutDuplicateIds(selectedData, data))
                                        } else {
                                            onChange(selectedData.filter(id => !data.some(item => item.id_graph === id)))
                                        }
                                    }}
                                >{language.invoices_text_selec_all}</p>
                            </div>
                            {data.map(item => {

                                let isSelected = selectedData.includes(item[keyName]);
                                let isNew = false;

                                if (newData.length) isNew = newData.findIndex(i => i === item[keyName]) >= 0;

                                return (
                                    <div key={item.id_report} className={`row-between align-items-center pe-pointer p-2 my-1 rounded-8 ${isSelected ? "bg-gray-550" : "bg-gray-500"}`}
                                        onClick={() => {
                                            if (isSelected) {
                                              onChange(selectedData.filter((value) => value !== item[keyName]))
                                            } else {
                                              onChange([...selectedData, item[keyName]])                                              
                                            }
                                        }}
                                    >
                                        <div className="row-start align-items-center">
                                            <div className={`txt-phrase txt-regular txt-6 txt-white pe-pointer me-2`}>{language ? language[item[labelName]] : item[labelName]}</div>
                                            {language[item.tooltip] !== "" ? <Tooltip text={`${language[`${item.tooltip}`]}`} fontIcon={8} fontSize={6} typeIcon='light' colorIcon='white' /> : null}
                                            {isNew ? <div className="ms-2 px-2 py-1 rounded-pill border-1 border-color-green-550 bg-gray-450"><p className="txt-phrase txt-regular txt-6 txt-green-550">{language.genius_new_item}</p></div> : null}
                                        </div>
                                        <i className={`fal fa-check-circle txt-${isSelected ? 'green-500' : 'gray-700'} txt-10`}></i>
                                    </div>)
                            })}
                        </div> : null}
                    </div>
                    <div className={`row-end border-top-1 border-color-gray-600 py-3 px-1 mx-3`}>
                        <button className="p-2 rounded-8 bg-green-500 txt-neutral-800 txt-phrase txt-bold txt-8"
                            onClick={() => setShow(false) }>{language.genius_done_button}</button>
                    </div>
                </Fragment>
            }>
            <i className={`fa-light  fa-circle-chevron-right txt-10 txt-${show ? 'green-500' : 'gray-700'}`}></i>
        </Popover>

    )
};

export default SubMenu;