import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";

import PopUpScore from './PopUpScore';

const NpsSurvey = (props) => {    

    let language = props.language;
    let showNpsSurvey = props.showNpsSurvey;

    const loadUserNpsAsync = props.loadUserNpsAsync;

    useEffect(() => loadUserNpsAsync(), [loadUserNpsAsync]);    

    useEffect(() => { 
        if(showNpsSurvey && language){
            toast(<PopUpScore/>, {delay: 10000}, {toastId: 'popup'});
        }
    }, [showNpsSurvey, language]);    

    return (
        <Fragment>           
            <ToastContainer 
                position='bottom-right' 
                autoClose={false} 
                style={{maxWidth: 420}} 
                pauseOnFocusLoss={false} 
                closeOnClick={false}
                theme="dark"
            />        
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showNpsSurvey: state.shared.showNpsSurvey
})

const mapDispatchToProps = dispatch => ({
    loadUserNpsAsync: () => dispatch(sharedActions.loadUserNpsAsync()),
    setNpsSurveyAsync: payload => dispatch(sharedActions.setNpsSurveyAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NpsSurvey)