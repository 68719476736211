import React from "react";

import StepProgressBar from "./StepProgressBar";

import { track } from "@amplitude/analytics-browser";
import { connect } from "react-redux";

const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    skipProps,
    language,
}) => {
    const { onClick: backClickHandler, ...otherBackProps } = backProps;
    const { onClick: nextClickHandler, ...otherNextProps } = primaryProps;

    const handleBackClick = (event) => {
        track('Onboarding back clicked');

        if (backClickHandler) {
            backClickHandler(event);
        }
    };

    const handleNextClick = (event) => {

        if (index === 0) track('Onboarding begin clicked')
        else track('Onboarding next clicked')

        if (nextClickHandler) {
            nextClickHandler(event);
        }
    };

    return (
        <div className="column-between bg-gray-550 rounded-16 p-3" style={{ width: step?.width ? step.width : 362, height: 'auto' }} {...tooltipProps}>
            <div className="column-start w-100">
                {!step.walktrough && <StepProgressBar status={index} steps={step.steps} />}
                <div className={`row-between align-items-start w-100 mt-${step.walktrough ? 0 : 3}`}>
                    <div className="column-start" style={{ width: '100%' }}>
                        <p className="txt-phrase txt-wrap-pretty txt-semibold txt-8 txt-white">{step.title}</p>
                        <p className="txt-phrase txt-regular txt-8 txt-gray-700 text-wrap mt-2">{step.description}</p>
                    </div>
                </div>
                <div className="w-100">{step.content}</div>
            </div>
            <div className={`row-${step.walktrough || isLastStep ? 'end' : 'between'} align-items-center w-100 mt-4`}>
                {!isLastStep && <div className="txt-phrase txt-semibold txt-regular txt-gray-700 txt-8 pe-pointer" style={{ textDecoration: 'underline' }} {...skipProps}>{language.tutorial_skip_button}</div>}
                {!step?.hideFooter ?
                    <div className="row-start align-items-center">
                        {index > 0 && !step?.hideBackButton && (
                            <div onClick={(event) => handleBackClick(event)} className="row-center align-items-center button-tour rounded-pill me-2" {...otherBackProps}>
                                <i className="fal fa-chevron-left txt-white txt-8" id="back" />
                            </div>
                        )}
                        {continuous && !isLastStep && (
                            <div onClick={(event) => handleNextClick(event)} className={`row-center align-items-center button-tour rounded-pill py-${index === 0 ? 3 : 2} px-${index === 0 ? 4 : 3}`} {...otherNextProps}>
                                {index === 0 && <p className="txt-phrase txt-regular txt-white txt-8 me-2 pe-pointer">{language.tutorial_start_button}</p>}
                                <i className="fal fa-chevron-right txt-white txt-8" id="next" />
                            </div>
                        )}
                        {isLastStep && (
                            <div onClick={(event) => handleNextClick(event)} className="row-center align-items-center button-tour rounded-pill py-2 px-3" {...otherNextProps}>
                                {step.walktrough ? <p className="txt-phrase txt-regular txt-white txt-8 pe-pointer">{language.tutorial_got_it}</p> : <i className="fal fa-chevron-right txt-white txt-8" id="next" />}
                            </div>
                        )}
                    </div> :
                    null}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
});

export default connect(mapStateToProps)(Tooltip);