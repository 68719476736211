import * as creators from '../creators/Notifications';

const initialState = {
    notifications: [],
    notificationsPagination: null,
    notificationsLoading: true,
    totalNotificationsInfo: null
}

export const notificationsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_NOTIFICATIONS:
            return { ...state, notifications: payload };
        case creators.SET_TOTAL_NOTIFICATIONS_INFO:
            return { ...state, totalNotificationsInfo: payload };
        case creators.SET_NOTIFICATIONS_PAGINATION:
            return { ...state, notificationsPagination: payload };
        case creators.SET_NOTIFICATIONS_LOADING:
            return { ...state, notificationsLoading: payload };
        default:
            return state;
    }

}
