import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'

import * as servicesActions from '../../../store/actions/Services'
import * as sharedActions from '../../../store/actions/Shared'

import { Popover } from "@idui/react-popover"

import ButtonCircle from "../../shared/utilities/ButtonCircle"

import { track } from "@amplitude/analytics-browser"

import { useHistory, useLocation } from "react-router-dom"

const ServicesPopover = (props) => {

  let totalServicesGrouped = props.totalServicesGrouped
  let activeServices = props.activeServices
  let servicePicker = props.servicePicker
  let selectFunction = props.selectFunction
  let popoverPosition = props.popoverPosition
  let allowAllServices = props.limitSelectServices == null;
  let limitSelectServices = props.limitSelectServices || 1000;
  let availableTypeServices = props.availableTypeServices;

  const history = useHistory()
  const location = useLocation()

  const setControlledOnboarding = props.setControlledOnboarding
  const setShowBlackScreen = props.setShowBlackScreen
  const setShowBlackScreenDrawer = props.setShowBlackScreenDrawer

  const [focus, setFocus] = useState(false)
  const [services, setServices] = useState(totalServicesGrouped)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [servicesSelected, setServicesSelected] = useState(activeServices)

  useEffect(() => {

    const filterServices = totalServicesGrouped.map(account => {
        const filteredServices = account.services.filter(service => service.name.toLowerCase().includes(search.toLowerCase()) && availableTypeServices.includes(service.type?.name));
        return { ...account, services: filteredServices };
    }).filter(account => account.services.length > 0);

    setServices([...filterServices]);

  }, [search, totalServicesGrouped, availableTypeServices])

  useEffect(() => {
    if (show && servicePicker) {
      setControlledOnboarding({ key: 'service_picker', value: true })
    } else {
      setControlledOnboarding({ key: 'service_picker', value: false })
    }
  }, [show, setControlledOnboarding, servicePicker])

  useEffect(() => {
    if (activeServices?.length > 1) {
      track('Project selected', { Project_ID: activeServices.map(function (service) { return service?.name }), Project_Quantity: activeServices?.length, Project_Type: activeServices.map(function (service) { return service?.type?.name }) })
    }
  }, [activeServices])

  return (
    <Popover
      zIndex={10000}
      lazy
      closeOnEnter
      closeOnRemoteClick={servicePicker ? false : true}
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={popoverPosition || 'bottomRight'}
      className={'p-0 bg-gray-500'}
      isOpen={show}
      withArrow={false}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      onChangeOpen={(state) => {
        setShow(state)
        props.setShowBlackScreen(state)
        props.setShowBlackScreenDrawer(state)
        setFocus(state)
        show && track('Project selected', {
          Project_ID: activeServices
            .map(function (service) { return service?.name }), Project_Quantity: activeServices?.length, Project_Type: activeServices.map(function (service) { return service?.type?.name })
        })
      }}
      content={
        <div className="w-100">
          <div className="column-center border-bottom-1 border-color-gray-600 py-3 mx-3 gap-3">
            <div className="border-1 border-color-gray-600 bg-gray-500 sticky-top py-2 px-3 rounded-8">
              <div className="row-start align-items-center">
                <i className="fal fa-search txt-10 txt-gray-650 me-2" />
                <input type="text" className="txt-phrase txt-8 txt-white bg-gray-500 border-0 w-100" placeholder={props.language.services_input_label_search} value={search} onChange={({ target }) => setSearch(target.value)} />
              </div>
            </div>
          </div>
          <div id="services_list" className="column-start services-container max-height-270 max-height-320-xl max-height-450-2xl max-width-400 ms-3 py-3" style={{ overflowY: "scroll" }}>
            {
              services.map((account) => {

                let allServicesSelected = account.services.every(item => servicesSelected.map(i => i.idService).includes(item.idService));

                return (
                  <div key={account.account.idAccount} className="column-start align-items-start gap-1">
                    <div className="w-100 row-start align-items-center gap-2">
                      <div>
                        <p className="txt-phrase txt-8 txt-gray-700">{account.account.name}</p>
                      </div>
                      <div className="row-start flex-grow-1 align-items-center">
                        <div className="flex-grow-1 border-top-1 border-color-gray-600"></div>
                      </div>
                      {
                        allowAllServices && 
                          <div className="pe-pointer" onClick={() => {
                          
                            let services = servicesSelected;
    
                            if (allServicesSelected) {
                              if (services.length > 1) {
        
                                services = services
                                  .filter(item => !account.services.map(i => i.idService).includes(item.idService))
        
                                setServicesSelected(services)
        
                              }
                            } else {
        
                              services = [...services, ...account.services]
        
                              if (services.length <= limitSelectServices) setServicesSelected(services)
                            
                            }
                          }}>
                            <p className="txt-phrase text-end txt-8 txt-green-500">{!allServicesSelected ? props.language.services_picker_select_all : props.language.services_picker_descelect_all}</p>
                          </div>
                      }
                    </div>
                    {
                      account.services.map((item) => {

                        const isSelected = servicesSelected
                        .findIndex(i => i.idService === item.idService) >= 0

                        return (
                          <div key={item.idService} className={`w-100 row-between align-items-center gap-2 pe-pointer py-2 px-3 my-2 rounded-8 ${isSelected ? "bg-gray-800" : "bg-black-dark"}`}
                            onClick={() => {
          
                              let services = servicesSelected
          
                              if (isSelected) {
                                if (services.length > 1) {
          
                                  services = services
                                    .filter(i => i.idService !== item.idService)
          
                                  setServicesSelected(services)
          
                                }
                              } else {
          
                                services = [...services, item]
          
                                if (services.length <= limitSelectServices) setServicesSelected(services)
                              }
          
                            }}>
                            <div className="col-10 column-start">
                              <div className={`txt-phrase txt-8 text-truncate w-100 pe-pointer txt-${isSelected || props.activeServices.length < 3 ? 'white' : 'gray-600'}`}>{item.name}</div>
                              <div className={`txt-phrase txt-6 text-truncate pe-pointer txt-${isSelected || props.activeServices.length < 3 ? 'gray-400' : 'gray-400'}`}>{item.address}</div>
                            </div>
                            <div className="row-end align-items-center col-2">
                              <i className={`fal fa-check-circle txt-${isSelected ? 'green-500' : 'gray-700'} txt-10`}></i>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
          <div className={`row-end border-top-1 border-color-gray-600 py-3 px-1 mx-3`}>
            <button className={`bg-green-500 txt-neutral-800 p-2 rounded-8 txt-phrase txt-bold txt-8`}
              onClick={() => {

                if (servicesSelected.length) selectFunction(servicesSelected)

                setShowBlackScreen(false)
                setShowBlackScreenDrawer(false)

                setShow(false)
                setFocus(false)

                if (props.typeComponent === 'chatbot' && location.pathname === '/home') history.push('home/detail')

              }}>{props.language.energy_done_button}</button>
          </div>
        </div>
      }>
      {props.typeComponent === 'energy' ? <ButtonCircle
        focus={focus}
        icon={focus ? 'circle-xmark' : 'circle-plus'}
        showPulse={servicePicker && props.showPulseAnimation} /> :
        <div className={`chatBot-selector-header row-between align-items-center py-2 px-3 rounded-30 border-1 border-color-gray-800 w-100`} style={{ zIndex: focus ? 1030 : 0 }}>
          <div className='column-start'>
            <p className='txt-phrase txt-regular txt-8 txt-gray-700'>{`${props.language.lumi_consulting} ${activeServices?.length} ${props.language.lumi_projects}`}</p>
            <p className='txt-phrase txt-regular txt-6 txt-gray-200'>{props.language.energy_click_to_change}</p>
          </div>
          <i className={`fal fa-${focus ? 'chevron-up' : 'chevron-down'} txt-gray-700 txt-8`}></i>
        </div>}
    </Popover>
  )
}

const mapStateToProps = (state, ownProps) => ({
  account: state.accounts.account,
  servicesTypes: state.services.servicesTypes,
  totalServices: state.services.totalServices,
  totalServicesGrouped: state.services.totalServicesGrouped,
  language: state.shared.language,
  showPulseAnimation: state.shared.showPulseAnimation,
  servicePicker: state.shared.startOnboarding.service_picker,
  selectFunction: ownProps.selectFunction,
  activeServices: ownProps.activeServices,
  limitSelectServices: ownProps.limitSelectServices,
  availableTypeServices: ownProps.availableTypeServices,
  typeComponent: ownProps.typeComponent,
  popoverPosition: ownProps.popoverPosition,
})

const mapDispatchToProps = dispatch => ({
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setControlledOnboarding: (payload) => dispatch(sharedActions.setControlledOnboarding(payload)),
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPopover)
