import React from 'react'

const Select = ({ label, value, options, background, required, onChange, keyName = 'value', labelName='label', error, language }) => {
    return (
        <div className="column-start align-items-start w-100">
            <div className="form-floating w-100">
                <select className={`form-select border-2 border-color-${error ? 'red-500' : 'white'} txt-white txt-phrase txt-8 bg-${background}`} id="floatingSelect" aria-label="Floating label select" required={required} value={value} onChange={onChange}>
                    {options.map(option => <option key={option[keyName]} value={option[keyName]}>{language ? language[option[labelName]] : option[labelName]}</option>)}
                </select>
                <label htmlFor="floatingSelect" className="txt-white txt-phrase">{label}</label>
            </div>
            {error ? <p className="txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap">{error}</p> : null}
        </div>
    );
}

export default Select