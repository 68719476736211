import * as creators from '../creators/Energy';

import { addDays, startOfWeek } from 'date-fns';
import { setCalendarDatesEnergy } from '../../services/CalendarHandler';

const initialState = {
    graphData: [],
    graphLoading: true,
    indicatorsLoading: true,
    intervalGraph: 1,
    graphs: [],
    graphsRelations: [],
    activeGraphs: [],
    indicators: [],
    invoiceInfo: null,
    graphDateFrom: addDays(startOfWeek(new Date()), 1),
    graphDateTo: addDays(startOfWeek(new Date()), 7),
    graphDateFromScroll: addDays(startOfWeek(new Date()), 1),
    graphDateToScroll: addDays(startOfWeek(new Date()), 7),
    enableScrollData: true,
    showReportModal: false,
    reportLoading: false,
    scrollActive: true,
    tariff: [],
    bannersData: [],
    showDetailService: false,
    prevStateBar: null,
    enableAverageLine: true,
    rankGraphId: 'Rank_Weekly'
}

export const energyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_GRAPH_DATA:
            return { ...state, graphData: payload };
        case creators.SET_GRAPH_LOADING:
            return { ...state, graphLoading: payload };
        case creators.SET_INDICATORS_LOADING:
            return { ...state, indicatorsLoading: payload };
        case creators.SET_INVOICE_INFO:
            return { ...state, invoiceInfo: payload };
        case creators.SET_INTERVAL_GRAPH:

            let date = { startDate: new Date(), endDate: new Date(), key: 'selection' };
            let eventProperty = '';

            if (state.graphDateTo < date)
                date = state.graphDateTo;

            const dates = setCalendarDatesEnergy(date, payload);

            switch (payload) {
                case 0:
                    eventProperty = 'Rank_Hourly'
                    break
                case 1:
                    eventProperty = 'Rank_Weekly'
                    break
                case 2:
                    eventProperty = 'Rank_Monthly'
                    break
                case 3:
                    eventProperty = 'Rank_Yearly'
                    break
                default:
                    eventProperty = 'Rank_Weekly'
                    break
            }

            return {
                ...state,
                intervalGraph: payload,
                graphDateFrom: dates.graphDateFrom,
                graphDateTo: dates.graphDateTo,
                graphDateFromScroll: dates.graphDateFrom,
                graphDateToScroll: dates.graphDateTo,
                rankGraphId: eventProperty
            };

        case creators.SET_GRAPH_ACTIVE:
            return { ...state, activeGraphs: payload };
        case creators.SET_INDICATORS:
            return { ...state, indicators: payload };
        case creators.SET_GRAPH_OPTIONS:
            return {
                ...state,
                graphs: [{ options: payload.options, graphsGroups: payload.graphsGroups }],
                graphsRelations: payload.relations
            };
        case creators.SET_GRAPH_DATES:
            return {
                ...state,
                graphDateFrom: payload.graphDateFrom,
                graphDateTo: payload.graphDateTo,
                graphDateFromScroll: payload.graphDateFrom,
                graphDateToScroll: payload.graphDateTo
            };
        case creators.SET_GRAPH_DATES_SCROLL:
            return {
                ...state,
                graphDateFromScroll: payload.graphDateFrom,
                graphDateToScroll: payload.graphDateTo
            };
        case creators.SET_ENABLE_SCROLL_DATA:
            return { ...state, enableScrollData: payload };
        case creators.SET_SHOW_REPORT_MODAL:
            return { ...state, showReportModal: payload };
        case creators.SET_REPORT_LOADING:
            return { ...state, reportLoading: payload };
        case creators.SET_SCROLL_ACTIVE:
            return { ...state, scrollActive: payload };
        case creators.SET_TARIFF:
            return { ...state, tariff: payload };
        case creators.SET_BANNERS_DATA:
            return { ...state, bannersData: payload };
        case creators.SET_SHOW_DETAIL_SERVICE:
            return { ...state, showDetailService: payload };
        case creators.SET_DETAIL_DATA_GRAPH:
            return {
                ...state,
                intervalGraph: payload.intervalGraph,
                graphDateFrom: payload.graphDateFrom,
                graphDateTo: payload.graphDateTo,
                graphDateFromScroll: payload.graphDateFrom,
                graphDateToScroll: payload.graphDateTo
            };
        case creators.SET_PREV_STATE_BAR:
            return { ...state, prevStateBar: payload };
        case creators.SET_ENABLE_AVERAGE_LINE:
            return { ...state, enableAverageLine: payload };
        default:
            return state;
    }
}
