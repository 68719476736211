import React, { useState, Fragment, useEffect} from 'react';
import { connect } from 'react-redux';

import * as energyActions from "../../../store/actions/Energy";

import moment from "moment";

import { Transition } from 'react-transition-group';

import { Loader, Modal } from "rsuite";

import { Calendar as Datepicker } from 'react-calendar';

import Input from '../../shared/utilities/Input';

import { track } from "@amplitude/analytics-browser"

const showError = (payloadExport, setFocus, setError, language) => {

    if (payloadExport.report_name === null || payloadExport.report_name === "") {        
        setFocus(1);
        setError(language.alert_filename_report_error);
        return;
    }

    return setFocus(0);
}

const validate = (payloadExport) => {

    if (payloadExport.report_name === null || payloadExport.report_name === "") {
        return false;
    }  

    return true
}

const ExportGraph = (props) => {

    const payloadToExportInit = {
        report_name: null,
        startTime: props.dateFrom,
        endTime: props.dateTo,
        "format_type": "CSV"        
    }

    let language = props.language;

    const [payloadToExport, setPayloadToExport] = useState(payloadToExportInit);    
    const [showCalendarDateFrom, setShowCalendarDateFrom] = useState(false);
    const [showCalendarDateTo, setShowCalendarDateTo] = useState(false); 
    const [focus, setFocus] = useState(0);   
    const [error, setError] = useState("");

    useEffect(() => {
        if(focus !== 0) {showError(payloadToExport, setFocus, setError, language)}                      
    }, [payloadToExport, language, focus]);
        
    return(
        <Modal            
            style={{
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block'
            }}
            show={props.showReportModal}
            overflow={false}
            backdropClassName={'bg-black opacity-80 pe-pointer'}    
            dialogClassName="rounded"                                  
            onHide={() => props.setShowReportModal(false)}>
            <Fragment>
                <div id='export_csv' className='column-start w-100 h-100'>
                    <div className='w-100 txt-white txt-phrase txt-12 txt-semibold text-center mt-3'>{props.language.energy_export_graph_title}</div> 
                    <div className='column-start p-3'>
                        <div className="row-start align-items-start flex-wrap my-2">
                            <div className="col-md-6 col-12 pe-1">
                                <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold'>{props.language.energy_export_graph_start_time}</div>
                                <div className="w-100 my-2">
                                    <div className="form-control border-2 border-color-white bg-transparent txt-white txt-phrase txt-8 py-3 pe-pointer" onClick={() => setShowCalendarDateFrom(!showCalendarDateFrom) }>
                                        <div className="row-between align-items-center">
                                            {payloadToExport.startTime ? moment(payloadToExport.startTime).format("YYYY-MM-DD") : "Selecciona la fecha"}
                                            <i className="fa-solid fa-calendar-days txt-10"></i>
                                        </div>
                                    </div>
                                    <Transition
                                        in={showCalendarDateFrom}
                                        timeout={50}>
                                        {state => (
                                            <div className={`trans-fade trans-fade-${state}`}>
                                                <Datepicker 
                                                    next2Label={null}
                                                    prev2Label={null}
                                                    maxDate={new Date()}
                                                    className={"bg-gray-500 txt-phrase txt-8 p-2 rounded-8"}
                                                    nextLabel={<i className="fa-regular fa-chevron-right txt-8 txt-white pe-pointer"></i>}
                                                    prevLabel={<i className="fa-regular fa-chevron-left txt-8 txt-white pe-pointer"></i>}
                                                    onChange={value => {                                                        
                                                        setPayloadToExport({ ...payloadToExport, startTime: value });
                                                        setShowCalendarDateFrom(false);                                                        
                                                    }}
                                                />
                                            </div>
                                        )} 
                                    </Transition>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 ps-1">
                                <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold'>{props.language.energy_export_graph_end_time}</div>
                                    <div className="w-100 my-2">
                                        <div className="form-control border-2 border-color-white bg-transparent txt-white txt-phrase txt-8 py-3 pe-pointer" onClick={() => setShowCalendarDateTo(!showCalendarDateTo) }>
                                            <div className="row-between align-items-center">
                                                {payloadToExport.endTime ? moment(payloadToExport.endTime).format("YYYY-MM-DD") : "Selecciona la fecha"}
                                                <i className="fa-solid fa-calendar-days txt-10"></i>
                                            </div>
                                        </div>
                                        <Transition
                                            in={showCalendarDateTo}
                                            timeout={50}>
                                            {state => (
                                                <div className={`trans-fade trans-fade-${state}`}>
                                                    <Datepicker 
                                                        next2Label={null}
                                                        prev2Label={null}
                                                        maxDate={new Date()}
                                                        className={"bg-gray-500 txt-phrase txt-8 p-2 rounded-8"}
                                                        nextLabel={<i className="fa-regular fa-chevron-right txt-8 txt-white pe-pointer"></i>}
                                                        prevLabel={<i className="fa-regular fa-chevron-left txt-8 txt-white pe-pointer"></i>}
                                                        onChange={value => {                                                            
                                                            setPayloadToExport({ ...payloadToExport, endTime: value });
                                                            setShowCalendarDateTo(false);                                                            
                                                        }}/>
                                                </div>
                                            )} 
                                        </Transition>
                                    </div>
                                </div>
                        </div>                        
                        <div className='txt-blue-green-gradient txt-phrase txt-8 txt-bold mt-3'>{props.language.energy_export_graph_filename}</div>
                        <div className="w-100 mt-2 mb-4">
                            <Input 
                                label={props.language.export_graph_example_filename} 
                                value={payloadToExport?.report_name}
                                type={'text'} 
                                onChange={({target}) => setPayloadToExport({ ...payloadToExport, report_name: target?.value })}
                                error={focus === 1 ? error : ""}
                            />
                        </div>                       
                        <div className='row-end my-2'>
                            <button className='me-3 bg-green-500 rounded-8 py-2 px-4 txt-blue-900 txt-phrase txt-8 txt-bold' style={{minWidth: 155}}
                                onClick={() => {
                                    if(validate(payloadToExport)){
                                        props.createReportAsync(payloadToExport);
                                        track('Graphic downloaded', {Rank_ID: props.rankGraphId, Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name })})                                                                                                                
                                    }
                                    else {
                                        showError(payloadToExport, setFocus, setError, language)
                                    }}}> 
                                {!props.reportLoading ?                               
                                    <div className="row-start align-items-center">
                                        <i className="fa-regular fa-file-arrow-down txt-10"></i>
                                        <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{props.language.energy_export_graph_button}</div>
                                    </div> :
                                    <div className='column-center align-items-center h-100'>
                                        <Loader/>
                                    </div>
                                }
                            </button>
                            <button className='bg-gray-500 rounded-8 py-2 px-4 border-2 border-color-red-500 txt-red-500 txt-phrase txt-8 txt-bold' onClick={() => {props.setShowReportModal(false)}}>{props.language.tag_new_device_button_cancel}</button>
                        </div>                       
                    </div>                  
                </div>
            </Fragment>           
        </Modal>

    );
}

const mapStateToProps = (state) => ({ 
    dateFrom: state.energy.graphDateFrom,
    dateTo: state.energy.graphDateTo,  
    showReportModal: state.energy.showReportModal,
    reportLoading: state.energy.reportLoading,
    user: state.users.user, 
    language: state.shared.language,
    rankGraphId: state.energy.rankGraphId,
    activeServicesEnergy: state.services.activeServicesEnergy
});

const mapDispatchToProps = dispatch => ({   
    createReportAsync: (payload) => dispatch(energyActions.createReportAsync(payload)),
    setShowReportModal: (payload) => dispatch(energyActions.setShowReportModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportGraph);