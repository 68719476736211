import React, { useState, useEffect } from 'react';
import { bounceInRight, bounceOutLeft, bounceIn, bounceOut } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import NotificationBox from '../../notifications/NotificationBox';

const AnimatedComponent = ({ data, type }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isEntering, setIsEntering] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsEntering(false);
            setTimeout(() => {
                setCurrentIndex((currentIndex + 1) % data.length);
                setIsEntering(true);
            }, 500);
        }, 3000);

        return () => clearInterval(interval);

    }, [currentIndex, data]);

    const animationStyle = {
        animationName: Radium.keyframes(isEntering ? type === 0 ? bounceIn : bounceInRight : type === 0 ? bounceOut : bounceOutLeft, 'bounce'),
        animationDuration: '1s',
        animationFillMode: 'both',
    };

    const elementToRender = data[currentIndex];

    return (
        <div className='w-100'>
            <StyleRoot>
                <div className="overflow-hidden w-100">
                    <div className='row-center w-100' style={animationStyle}>
                        {type === 0 ? <div className='row-start align-items-center w-100 bg-black rounded-8 px-3 py-2 mx-3'>
                            <i className={`fak fa-${elementToRender.icon}-symbol txt-green-500 txt-8 me-2`}></i>
                            <p className='txt-phrase txt-regular txt-green-500 txt-8'>{elementToRender.title}</p>
                        </div>
                            : <div className='w-100 px-3'><NotificationBox notification={elementToRender} /></div>}
                    </div>
                </div>
            </StyleRoot>
        </div>
    );
};

export default AnimatedComponent;
