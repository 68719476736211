import { addDays, addMonths, addYears, startOfWeek, startOfMonth, startOfYear, format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';

export const calendarIntervals = [
    "month", "month", "year", "decade"
];

export const setCalendarDates = (value, interval) => {

    switch (interval) {
        case 0:
            return {
                graphDateFrom: value,
                graphDateTo: value
            };
        case 1:
            return {
                graphDateFrom: addDays(startOfWeek(value, 0), 1),
                graphDateTo: addDays(startOfWeek(value, 0), 7)
            };
        case 2:
            return {
                graphDateFrom: startOfMonth(value),
                graphDateTo: addDays(addMonths(startOfMonth(value), 1), -1)
            };
        case 3:
            return {
                graphDateFrom: startOfYear(value),
                graphDateTo: addYears(startOfYear(value), 1)
            };
        default:
            return null
    }
};

export const stringFormatDate = (value, interval, language = 'es') => {

    const locale = language === 'es' ? esLocale : enLocale;

    let stringFormat;  

    switch (interval) {
        case 0:
            stringFormat = 'EEEE, dd MMMM yyyy';
            break;
        case 1:
            stringFormat = 'dd MMMM yyyy';
            break;
        case 2:
            stringFormat = 'MMMM yyyy';
            break;
        case 3:
            stringFormat = 'yyyy';
            break;
        default:
            return null
    }

    return format(value, stringFormat, { locale: locale })
};

export const setCalendarDatesEnergy = (value, interval) => {

    switch (interval) {
        case 0:
            return {
                graphDateFrom: value.startDate,
                graphDateTo: value.endDate
            };
        case 1:
            return {
                graphDateFrom: addDays(startOfWeek(value.startDate, 0), 1),
                graphDateTo: addDays(startOfWeek(value.endDate, 0), 7)
            };
        case 2:
            return {
                graphDateFrom: startOfMonth(value.startDate),
                graphDateTo: addDays(addMonths(startOfMonth(value.endDate), 1), -1)
            };
        case 3:
            return {
                graphDateFrom: startOfYear(value.startDate),
                graphDateTo: addYears(startOfYear(value.endDate), 1)
            };
        default:
            return null
    }
};

export const sortByDate = (a, b) => {    
    if (a.date < b.date)
        return - 1;    
    if (a.date > b.date)
        return 1;    
    return 0; 
};