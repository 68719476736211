import React from 'react'

const Numeric = ({ label, value, required, onChange }) => {
    return (
        <div className="column-star align-items-start">
            { label ? <label className="txt-gray-400 txt-phrase txt-6 mb-2">{label}</label> : null}
            <div className="row-start align-items-center">
                <div className="column-center align-items-center py-2 border-2 border-color-white rounded-left-10 pe-pointer" style={{ width: 60 }} onClick={() => { onChange(value + 1) }}>
                    <div className="txt-phrase txt-semibold txt-white txt-8 pe-pointer user-select-none">+</div>
                </div>
                <input type={'number'} className="py-2 border-top-2 border-bottom-2 border-left-0 border-right-0 border-color-white bg-transparent txt-white text-center txt-phrase txt-8" style={{ width: 60 }}  required={required} value={value} onChange={({target}) => onChange(target.value)}/>
                <div className="column-center align-items-center py-2 border-2 border-color-white rounded-right-10 pe-pointer" style={{ width: 60 }} onClick={() => { onChange(value - 1) }}>
                    <div className="txt-phrase txt-semibold txt-white txt-8 pe-pointer user-select-none">-</div>
                </div>
            </div>
        </div>);
}

export default Numeric