import React from 'react';
import step1 from '../../../imgs/onboarding/step1-graph-type.png';
import step1En from '../../../imgs/onboarding/english/step1-graph-type-en.png';
import step2 from '../../../imgs/onboarding/step2-graph-type.png';
import step2En from '../../../imgs/onboarding/english/step2-graph-type-en.png';
import step3 from '../../../imgs/onboarding/step3-graph-type.png';
import step3En from '../../../imgs/onboarding/english/step3-graph-type-en.png';
import step4 from '../../../imgs/onboarding/step4-graph-type.png';
import step4En from '../../../imgs/onboarding/english/step4-graph-type-en.png';

export const stepsGraphType = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 152, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En} className='w-100 h-100' alt="step1-service-picker" />
                </div>,
            title: language.onboarding_graph_type_title_step1,
            description: language.onboarding_graph_type_description_step1,
            placement: "right-start",
            target: "#graph_picker",
            disableBeacon: true,
            steps: 4,
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 152, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2 : step2En} className='w-100 h-100' alt="step2-service-picker" />
                </div>,
            title: language.onboarding_graph_type_title_step2,
            description: language.onboarding_graph_type_description_step2,
            placement: "right-start",            
            target: "#graph_picker",
            disableBeacon: true,
            steps: 4,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            hideFooterTitle: '¡Da click para descubrir cómo!',
            pulseEffect: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 142, width: 300 }}>
                    <img src={language.language_locale === 'es'? step3 : step3En} className='w-100 h-100' alt="step3-service-picker" />
                </div>,
            title: language.onboarding_graph_type_title_step3,
            description: language.onboarding_graph_type_description_step3,
            placement: "right",           
            target: "#graph_type_picker",
            disableBeacon: true,
            steps: 4,
            disableOverlayClose: true,
            hideBackButton: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <img src={language.language_locale === 'es'? step4 : step4En} className='w-100 h-100' alt="step4-service-picker" />
                </div>,
            title: language.onboarding_graph_type_title_step4,
            description: language.onboarding_graph_type_description_step4,
            placement: "right",            
            target: "#graph_type_picker",
            disableBeacon: true,
            steps: 4,
            disableOverlayClose: true,
            hideBackButton: true
        }
    ])
};