import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux';

import { useLocation, useHistory } from 'react-router-dom';

const Onboarding = (props) => {

    const location = useLocation();
    const history = useHistory();

    let user = props.user;
    let step = props.step;

    const frameOnboarding = useRef(null);

    const handleMessageFromIframe = (event) => {
        if (event.origin === process.env.REACT_APP_ONBOARDING_HOST) {

            const data = event.data;

            if (data.pathname)
                history.push(data.pathname);
            
        }
    };

    //iframe listener
    useEffect(() => {

        window.addEventListener('message', handleMessageFromIframe);

        return () => {
            window.removeEventListener('message', handleMessageFromIframe);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        const userId = user?.idUser

        if (userId !== null && userId !== undefined && step === 3) {

            frameOnboarding.current.addEventListener('load', () => {

                let window = frameOnboarding.current.contentWindow;

                let token = localStorage.getItem('token');

                setTimeout(() => {
                    window.postMessage(JSON.stringify({
                        token: token,
                        user: { idUser: userId }
                    }
                    ), process.env.REACT_APP_ONBOARDING_HOST);
                }, [100]);

            });

        } else {

            if (!location.pathname.includes('invite'))
                history.push('/home')
        }
        // eslint-disable-next-line
    }, [user?.idUser, step]);

    return (
        <div className="position-relative w-100 vh-100">
            <iframe key={user?.idUser} ref={frameOnboarding} title="onboarding-section" className="position-absolute w-100 h-100 left-0 top-0" src={`${process.env.REACT_APP_ONBOARDING_HOST}${location.pathname}`}></iframe>
        </div>);

}

const mapStateToProps = (state) => ({
    user: state.users.user,
    step: state.login.step,
    language: state.shared.language
});

export default connect(mapStateToProps)(Onboarding)
