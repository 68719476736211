import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer } from 'rsuite';

import _ from 'lodash';

import CheckPicker from '../shared/utilities/CheckPicker';
import BackButton from '../shared/utilities/BackButton';

const days = language => [
    {
        id_day: 1,
        label: language.alerts_select_days_monday
    },
    {
        id_day: 2,
        label: language.alerts_select_days_tuesday
    },
    {
        id_day: 3,
        label: language.alerts_select_days_wednesday
    },
    {
        id_day: 4,
        label: language.alerts_select_days_thursday
    },
    {
        id_day: 5,
        label: language.alerts_select_days_friday
    },
    {
        id_day: 6,
        label: language.alerts_select_days_saturday
    },
    {
        id_day: 7,
        label: language.alerts_select_days_sunday
    },
];

const checkTimePlus = language => [
    {
        minutes: 1,
        label: language.alerts_select_check_one_minute
    },
    {
        minutes: 5,
        label: language.alerts_select_check_five_minute
    },
    {
        minutes: 10,
        label: language.alerts_select_check_ten_minute
    },
    {
        minutes: 15,
        label: language.alerts_select_check_fifteen_minute
    },
];

const intervals = language => [
    {
        id_interval: 4,
        label: language.alerts_select_interval_secondBySecond,
        plus: true
    },
    {
        id_interval: 0,
        label: language.alerts_select_interval_hourly
    },
    {
        id_interval: 1,
        label: language.alerts_select_interval_daily
    },
    {
        id_interval: 2,
        label: language.alerts_select_interval_weekly
    },
    {
        id_interval: 3,
        label: language.alerts_select_interval_monthly
    }
];

const IntervalTime = (props) => {

    const initAlertTime = {
        dayIds: [],
        fromHour: "00",
        fromMinutes: "00",
        fromPartOfDay: 'am',
        toHour: "11",
        toMinutes: "59",
        toPartOfDay: 'pm',
        intervalIds: [],
        dates: [],
        timingPlus: -1,
    }

    const [alertTime, setAlertTime] = useState(initAlertTime);
    const [showTimePlus, setShowTimePlus] = useState(false);

    useEffect(() => {
        if (props.intervalTime) setAlertTime(props.intervalTime);
    }, [props.intervalTime])

    useEffect(() => {
        if (alertTime.intervalIds.length) {
            if (alertTime.intervalIds[0] === 4) {
                setShowTimePlus(true)
            }
            else {
                setShowTimePlus(false)
            };
        }
    }, [alertTime])

    const format = (value, hour) => {
        value = parseInt(value, 10);
        value = value < 0 && hour ? 0 : value > 12 && hour ? 12 : value;
        value = value < 0 && !hour ? 0 : value > 59 && !hour ? 59 : value;
        value = value < 10 ? '0' + String(value) : String(value);
        return value;
    }

    const RenderDays = ({ options, labelName, selectedData, keyName, onChange }) => {
        return (
            <div className='row-between flex-wrap w-100 align-items-center my-2'>
                {options.length && options.map((item, index) => {
                    let isSelected = selectedData.includes(item[keyName]);
                    return <div key={index} className={`row-center rounded-16 ${isSelected ? 'bg-green-500' : 'border-1 border-color-green-500'} py-1 mt-2`} style={{ width: index < 4 ? '24.2%' : '32.65%' }}
                        onClick={() => {
                            if (isSelected) {
                                onChange(selectedData.filter(value => value !== item[keyName]));
                            } else {
                                onChange([...selectedData, item[keyName]]);
                            }
                        }}
                    >
                        <p className={`txt-phrase ${isSelected ? 'txt-neutral-800 txt-semibold' : 'txt-green-500 txt-medium'} txt-8`}>{item[labelName]}</p>
                    </div>
                })}
            </div>
        )
    }

    const RenderTiming = ({ options, labelName, selectedData, keyName, onChange }) => {
        return (
            <div className='row-between flex-wrap w-100 align-items-center my-2'>
                {options.length && options.map((item, index) => {
                    let isSelected = selectedData === item[keyName];
                    return <div key={index} className={`row-center rounded-16 ${isSelected ? 'bg-green-500' : 'border-1 border-color-green-500'} py-1 mt-2`} style={{ width: '48%' }}
                        onClick={() => {
                            if (isSelected) {
                                onChange(-1);
                            } else {
                                onChange(item[keyName]);
                            }
                        }}
                    >
                        <p className={`text-wrap txt-phrase ${isSelected ? 'txt-neutral-800 txt-semibold' : 'txt-green-500 txt-medium'} txt-8`}>{item[labelName]}</p>
                    </div>
                })}
            </div>
        )
    }

    return (
        <Drawer
            show={props.showAlertPeriod}
            size={'xs'}
            placement={'right'}
            backdropClassName={'pe-pointer'}
            onHide={() => { props.setShowPeriodAlert(false) }}>
            <div className="vh-100 w-100 overflow-auto bg-gray-500">
                <div className='align-items-start py-3 px-4 h-100'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton onClick={() => props.setShowPeriodAlert(false)}></BackButton>
                        <div className='row-center align-items-center gap-2'>
                            <i className='fal fa-square-bolt txt-white txt-10'></i>
                            <p className='txt-phrase txt-white txt-bold txt-8'>{props.language.alerts_label_title_new_alert}</p>
                        </div>
                        <p className='txt-phrase txt-bold txt-green-500 txt-8 pe-pointer' onClick={() => { props.onChange(alertTime); props.setShowPeriodAlert(false) }}>Guardar</p>
                    </div>
                    <p className='txt-phrase txt-semibold txt-white txt-10'>{props.language.alerts_title_interval_time}</p>
                    <p className='txt-phrase txt-regular txt-white txt-8'>{props.language.alerts_interval_time_body_frequency}</p>
                    <div className='w-100 my-3'>
                        <CheckPicker
                            keyName='id_interval'
                            labelName='label'
                            options={intervals(props.language)}
                            selectedData={alertTime.intervalIds}
                            onChange={(value) => { setAlertTime({ ...alertTime, intervalIds: [_.last(value)] }) }}
                        />
                    </div>
                    {showTimePlus ?
                    <div className='w-100 mb-4'>
                        <p className='txt-phrase txt-semibold txt-white txt-8'>{props.language.alerts_interval_time_title_plus_check}</p>
                        <RenderTiming
                            keyName='minutes'
                            labelName='label'
                            options={checkTimePlus(props.language)}
                            selectedData={alertTime.timingPlus}
                            onChange={(value) => { setAlertTime({ ...alertTime, timingPlus: value }) }}
                        />
                    </div> : null}
                    <div className='w-100'>
                        <p className='txt-phrase txt-semibold txt-white txt-8'>{props.language.alerts_interval_time_title_days}</p>
                        <RenderDays
                            keyName='id_day'
                            labelName='label'
                            options={days(props.language)}
                            selectedData={alertTime.dayIds}
                            onChange={(value) => { setAlertTime({ ...alertTime, dayIds: value }) }}
                        />
                    </div> 
                    <p className='txt-phrase txt-semibold txt-white txt-10 mt-3'>{props.language.alerts_interval_time_title_range}</p>
                    <p className='txt-phrase txt-regular txt-white txt-8 w-100 text-wrap'>{props.language.alerts_interval_time_title_body}</p>
                    <div className='w-100 pb-5'>
                        <div className='row-between align-items-center px-3 py-2 bg-gray-800 rounded-8 w-100 mt-3'>
                            <div className='row-start align-items-center'>
                                <p className='txt-phrase txt-regular txt-white txt-8 me-5'>{props.language.alerts_title_initial_time}</p>
                                <div className='row-center bg-gray-500 rounded-8 p-2 gap-2 align-items-center'>
                                    <i className='fas fa-clock txt-10 txt-green-500'></i>
                                    <input placeholder={"00"} type="number" value={alertTime.fromHour} style={{ width: 20, border: 'none' }} className={`bg-gray-500 txt-${alertTime.fromHour ? 'white' : 'gray-600'}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, fromHour: format(target.value, true) }) }} />
                                    <p className='txt-phrase txt-regular txt-white txt-8'>:</p>
                                    <input placeholder={"00"} type="number" value={alertTime.fromMinutes} style={{ width: 20, border: 'none' }} className={`bg-gray-500 txt-${alertTime.fromMinutes ? 'white' : 'gray-600'}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, fromMinutes: format(target.value, false) }) }} />
                                    <select value={alertTime?.fromPartOfDay} onChange={item => setAlertTime({ ...alertTime, fromPartOfDay: item.target.value })} className='bg-gray-500 txt-white' style={{ outline: 'none', border: 'none' }}>
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row-between align-items-center px-3 py-2 bg-gray-800 rounded-8 w-100 mt-3'>
                            <div className='row-start align-items-center'>
                                <p className='txt-phrase txt-regular txt-white txt-8 me-5'>{props.language.alerts_title_final_time}</p>
                                <div className='row-center bg-gray-500 rounded-8 p-2 gap-2 align-items-center'>
                                    <i className='fas fa-clock txt-10 txt-green-500'></i>
                                    <input placeholder={"00"} type="number" value={alertTime.toHour} style={{ width: 20, border: 'none' }} className={`bg-gray-500 txt-${alertTime.toHour ? 'white' : 'gray-600'}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, toHour: format(target.value, true) }) }} />
                                    <p className='txt-phrase txt-regular txt-white txt-8'>:</p>
                                    <input placeholder={"00"} type="number" value={alertTime.toMinutes} style={{ width: 20, border: 'none' }} className={`bg-gray-500 txt-${alertTime.toMinutes ? 'white' : 'gray-600'}`} name="number" step="1" onChange={({ target }) => { setAlertTime({ ...alertTime, toMinutes: format(target.value, false) }) }} />
                                    <select value={alertTime?.toPartOfDay} onChange={item => setAlertTime({ ...alertTime, toPartOfDay: item.target.value })} className='bg-gray-500 txt-white' style={{ outline: 'none', border: 'none' }}>
                                        <option value="am">{props.language.alert_time_am}</option>
                                        <option value="pm">{props.language.alert_time_pm}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(IntervalTime);